import * as actions from "./actionType";
import cloneDeep from "lodash/cloneDeep";
import { get } from "lodash";

let storage;
let storageReducer;
try {
  storage = JSON.parse(get(sessionStorage, "persist:reducer"));
  storageReducer = JSON.parse(storage.reducer);
} catch {}

const initialState: any = {
  newNavbar: "",
  user: storageReducer?.user || undefined,
  travelRequestId: storageReducer?.travelRequestId || undefined,
};

export default function reducer(state: any = initialState, action: any) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case actions.NEW_NAVBAR: {
      newState.newNavbar = action.payload;
      return newState;
    }
    case actions.USER_AUTHENTICATE_SUCCESS: {
      newState.user = action.payload;
      return newState;
    }
    case actions.USER_LOGOUT: {
      newState.user = action.payload;
      return newState;
    }
    case actions.TRAVEL_REQUEST_ID: {
      newState.travelRequestId = action.payload;
      return newState;
    }
    default:
      return newState;
  }
}
