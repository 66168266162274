import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OpenPositionsBackground from "../assets/img/OpenPositionsBackground.png";
import PositionCreateModal from "../components/CreatePositionModal";

let widthWrapper: any;
let heightWrapper: any;
export function ChangeDisplay(toogle: boolean) {
  if (toogle) {
    widthWrapper("85.7vw");
    heightWrapper("100%");
  }
  else {
    widthWrapper("102.8vw");
    heightWrapper("100%");
  }
}

const classes = {
  root: {
    background: "#F5F6F8",
    backgroundImage: `url(${OpenPositionsBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    paddingTop: "100px",
    height: "100vh",
  },
  title: {
    marginTop: "63px",
  },
  descBox: {
    marginTop: "50px",
    backdropFilter: "blur",
    background:
      "linear-gradient(119.87deg, rgba(255, 255, 255, 0.8) 13.98%, rgba(237, 235, 235, 0.648) 100%)",
    boxShadow: "1px 1px 15px 0px #FFFFFF14 inset, 0px 10px 30px 0px #00000040",
  },
};

export default function RecruitmentLanding() {
  const [positions, setPositions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [allPositions, setAllPositions] = useState([]);
  const currentUser = useSelector((state: any) => state.reducer.user);
  const [showAllPosition, setShowAllPosition] = useState(false)

  const shouldSeeRecruitmentPage =
    currentUser.is_super_hr || currentUser.is_hr || currentUser.is_watcher;

  useEffect(() => {
    apiService
      .get(`${APP_URL}/job/alljobs/`)
      .then((data) => setAllPositions(data));
  }, []);

  useEffect(() => {
    apiService
      .get(`${APP_URL}/job/jobposition/`)
      .then((data) => setPositions(data));
  }, []);

  if (!shouldSeeRecruitmentPage) {
    return <Navigate to="/main/overview" />;
  }

  return (
    <Box
      sx={{
        backgroundImage: { sm: `url(${OpenPositionsBackground})` },
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "80%", md: "50%", lg: "35%" },
            margin: "auto",
            marginTop: "10%",
            px: "5%",
            maxHeight: { md: "70vh" },
            padding: "3%",
            "&::-webkit-scrollbar": {
              background: "#f1f1f1",
              width: "10px"
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey",
              borderRadius: "10px"

            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "10px"
            },


            overflowX: "auto",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              marginTop: { xs: "40px", sm: "20px" },
              fontSize: { xs: "35px", sm: "40px" },
            }}
          >
            Open positions
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              marginBottom: 5,
              fontSize: { xs: "20px", sm: "25px" },
            }}
          >
            View or create positions
          </Typography>
          {positions?.map((pos: any) => (
            <Box
              sx={{
                padding: 2,
                backgroundColor: "black",
                color: "white",
                fontWeight: "bold",
                marginBottom: 4,
                textAlign: "center",
                width: "auto",
              }}
            >
              <Link
                to={{
                  pathname: `/main/candidate-dnd/${pos.id}`,
                }}
                style={{ textDecoration: "none", color: "white" }}
              >
                <Typography
                  sx={{ fontWeight: 700, fontSize: { xs: 20, sm: 30, md: 40 } }}
                  variant="h2"
                >
                  {pos.name}
                </Typography>
              </Link>
            </Box>
          ))}
          {currentUser.is_super_hr || currentUser.is_hr ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => setShowModal(true)}
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "white",
                  background: "#2811a8",
                }}
              >
                ADD NEW POSITION
              </Button>
              <Button
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "white",
                  background: "#2811a8",
                  marginLeft: "30px",
                }}
                onClick={()=>setShowAllPosition(!showAllPosition)}
              >
                SHOW ALL POSITIONS
              </Button>
            </div>
          ) : null}

          {showAllPosition &&
            <Box sx={{marginTop: 10}} >
            {allPositions?.map((pos: any) => (
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: 4,
                  textAlign: "center",
                  width: "auto",
                }}
              >
                <Link
                  to={{
                    pathname: `/main/candidate-dnd/${pos.id}`,
                  }}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 20, sm: 30, md: 40 },
                    }}
                    variant="h2"
                  >
                    {pos.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
     }
        </Box>

        <Box
          sx={{
            width: { xs: "100%", sm: "80%", md: "50%", lg: "35%" },
            mx: "auto",
            my: "10%",
            padding: "3%",
            maxHeight: { xs: "30vh", sm: "40vh" },
          }}
        >
          <Box
            sx={{
              p: 4,
              mt: { sm: "20px" },
              background:
                "linear-gradient(119.87deg, rgba(255, 255, 255, 0.8) 13.98%, rgba(237, 235, 235, 0.648) 100%)",
              boxShadow:
                "1px 1px 15px 0px #FFFFFF14 inset, 0px 10px 30px 0px #00000040",
              backdropFilter: "Blur(120px)",
              textAlign: "left",
            }}
          >
            <Typography sx={{ paddingBottom: 10, fontSize: { xs: "40px" } }}>
              New applicants
            </Typography>
            <Button href="/main/candidates" variant="contained" color="primary">
              Candidate list
              <ArrowForwardIosIcon fontSize="small" />
            </Button>
          </Box>
          {showModal && (
            <PositionCreateModal onClose={() => setShowModal(false)} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
