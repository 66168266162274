import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, DialogTitle, DialogActions, Dialog } from "@mui/material";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import SearchBar from "material-ui-search-bar";
import moment from "moment";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Typography,
  FormControl,
  Select,
  Grid,
  Button,
} from "@mui/material";
import { TableSortLabel } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

var jwt = require("react-jwt");

const classes = {
  approved: {
    backgroundColor: "#2ED47A",
    marginLeft: "60px",
    width: "130px",
  },
  declined: {
    backgroundColor: "#F7685B",
    marginLeft: "60px",
    width: "130px",
  },
  reviewing: {
    backgroundColor: "#FFB946",
    marginLeft: "60px",
    width: "130px",
  },
  title: {
    color: "#90A0B7 !important",
    marginLeft: "60px",
    width: "130px",
  },
  inProgress: {
    backgroundColor: "#26a69a",
    borderRadius: "2rem",
    marginLeft: "60px",
    width: "130px",
  },
};

const TravelRequestList = (props: any) => {
  const statusOpstions = ["Approved", "Declined", "Reviewing"];
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [user, setUser] = useState<any>({});
  const [row, setRow] = useState([]);
  const [beDataStatus, setBeDataStatus] = useState([]);
  const [searched, setSearched] = useState<string>("");
  const [newState, setNewState] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loaderRow, setLoaderRow] = useState([]);
  const [statusstate, setStatus] = useState([]);
  const [sort_by_status, setSortByStatus] = useState<boolean>();
  const [sort_by_request_date, setSortByRequestDate] = useState<boolean>(false);
  const [sort_by_departing, setSortByDeparting] = useState<boolean>(true);
  let currentUser = useSelector((state: any) => state.reducer.user);
  const url = props.employeeId
    ? `${APP_URL}/users/travel-history/${props.employeeId}/`
    : `${APP_URL}/users/travels-by-user/`;

  useEffect(() => {
    apiService.get(url).then((resp) => {
      setData(resp.travels.reverse());
      setBeDataStatus(resp.travels);
      setOriginalData(resp.travels);
      let itemRows: any = [];
      let statusRows: any = [];
      for (let i = 0; i < resp.travels.length; i++) {
        itemRows = [...itemRows, false];
        statusRows = [...statusRows, false];
      }
      setRow(itemRows);
      setLoaderRow(statusRows);
      setLoader(false);
    });
  }, [newState]);

  useEffect(() => {
    setUser(currentUser);
  }, []);

  const handleClassName = (item: any) => {
    switch (item.approved) {
      case true:
        return classes.approved;
      case false:
        return classes.declined;
      case null && item.progress_description === null:
        return classes.reviewing;
      default:
        return classes.inProgress;
    }
  };

  const handleStatusView = (item: any) => {
    switch (item.approved) {
      case true:
        return "Approved";
      case false:
        return "Declined";
      case null && item.progress_description === null:
        return "Reviewing";
      default:
        return "In progress";
    }
  };
  const openPanel = (index: any) => {
    row[index] = !row[index];
    setRow({ ...row });
  };

  const changeStatus = (e: any, id: any, index: number) => {
    let status = e.target.value;
    let data: any;
    if (status === "Approved") {
      data = { id: id, approved: true };
    } else if (status === "Declined") {
      data = { id: id, approved: false };
    } else {
      data = { id: id, approved: null };
    }
    let temp_array = statusstate;
    temp_array.push(index);
    setStatus(temp_array);
    setBeDataStatus(data);
  };

  const deleteTravel = (item: any) => {
    let id = item.id;
    apiService.deleteItem(`${APP_URL}/users/travel/`, { id }).then(() => {
      setNewState(!newState);
    });
  };

  const submitStatusChange = (index: any) => {
    loaderRow[index] = !loaderRow[index];
    setLoaderRow({ ...loaderRow });
    apiService.patch(`${APP_URL}/users/travel/`, beDataStatus).then((resp) => {
      setNewState(!newState);
      setStatus([]);
      loaderRow[index] = !loaderRow[index];
      setLoaderRow({ ...loaderRow });
    });
  };

  const sortStatus = () => {
    if (sort_by_status === true) {
      setSortByStatus(false);
    } else if (sort_by_status === false) {
      setSortByStatus(undefined);
    } else if (sort_by_status === undefined) {
      setSortByStatus(true);
    }
    let approved: any = [];
    let declined: any = [];
    let reviewing: any = [];
    let newData: any = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].approved == true) {
        approved = [...approved, data[i]];
      } else if (data[i].approved == false) {
        declined = [...declined, data[i]];
      } else {
        reviewing = [...reviewing, data[i]];
      }
    }
    if (sort_by_status === true)
      newData = [...approved, ...reviewing, ...declined];
    else if (sort_by_status === false)
      newData = [...declined, ...reviewing, ...approved];
    else if (sort_by_status === undefined)
      newData = [...reviewing, ...approved, ...declined];
    setData(newData);
  };

  const sortDeparting = () => {
    if (sort_by_departing === true) {
      setSortByDeparting(false);
    } else if (sort_by_departing === false) {
      setSortByDeparting(true);
    }
    console.log(data);
    let newData;
    if (sort_by_departing === true)
      newData = data.sort(
        (objA, objB) =>
          new Date(objA.start_date).getTime() -
          new Date(objB.start_date).getTime()
      );
    else if (sort_by_departing === false)
      newData = data.sort(
        (objA, objB) =>
          new Date(objB.start_date).getTime() -
          new Date(objA.start_date).getTime()
      );

    setData(newData);
  };

  const sortDateOfRequest = () => {
    if (sort_by_request_date === true) {
      setSortByRequestDate(false);
    } else if (sort_by_request_date === false) {
      setSortByRequestDate(true);
    }

    let newData;
    if (sort_by_request_date === true)
      newData = data.sort(
        (objA, objB) =>
          new Date(objA.created_at).getTime() -
          new Date(objB.created_at).getTime()
      );
    else if (sort_by_request_date === false)
      newData = data.sort(
        (objA, objB) =>
          new Date(objB.created_at).getTime() -
          new Date(objA.created_at).getTime()
      );

    setData(newData);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalData.filter((dataItem) => {
      return dataItem.title.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Box mt={15}>
      <TableContainer component={Paper}>
        <Grid>
          {/* <SearchBar
            value={searched}
            onChange={(searchVal: any) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          /> */}
        </Grid>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={classes.title}></TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Requesting</TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={true}
                  onClick={sortDeparting}
                  direction={sort_by_departing ? "asc" : "desc"}
                >
                  Departing
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={true}
                  onClick={sortDateOfRequest}
                  direction={sort_by_request_date ? "asc" : "desc"}
                >
                  Date of Request
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={true}
                  onClick={sortStatus}
                  direction={sort_by_status ? "asc" : "desc"}
                  sx={{ align: "center" }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((item: any, index) => (
                <>
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" sx={{ width: "10px" }}>
                      <Button
                        onClick={() => openPanel(index)}
                        style={{ float: "left" }}
                      >
                        <KeyboardArrowDownIcon />
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        disabled={
                          currentUser.is_regular_user ||
                          currentUser.is_team_lead
                        }
                        onClick={() => props.handleTravelId(item.id)}
                        style={{
                          maxWidth: "120px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        <b>
                          {item.title.length > 20
                            ? item.title.substring(0, 20) + "..."
                            : item.title}
                        </b>
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <b>
                        {item?.requester?.first_name}{" "}
                        {item?.requester?.last_name}
                      </b>
                    </TableCell>
                    <TableCell align="center">
                      <b style={{ marginLeft: "20px", whiteSpace: "nowrap" }}>
                        {moment(item?.start_date).format("MMM Do YY")}
                      </b>
                    </TableCell>
                    <TableCell align="center">
                      <b style={{ marginLeft: "20px" }}>
                        {moment(item.travel_requests[0]?.created_at).format(
                          "MMM Do YY"
                        )}
                      </b>
                    </TableCell>
                    <TableCell align="center">
                      {user?.is_hr || user?.is_super_hr ? (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          color="primary"
                          style={{ width: "218px" }}
                        >
                          <Select
                            native
                            onChange={(e:any) => {
                              changeStatus(e, item.id, index);
                              console.log(statusstate);
                            }}
                            defaultValue={handleStatusView(item)}
                            style={handleClassName(item)}
                          >
                            {statusOpstions.map((status) => (
                              <option>{status}</option>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography style={handleClassName(item)}>
                          {handleStatusView(item)}
                        </Typography>
                      )}
                    </TableCell>
                    {(user?.is_hr || user?.is_super_hr) &&
                      (loaderRow[index] ? (
                        <CircularProgress
                          style={{
                            marginTop: "30px",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      ) : (
                        <TableCell align="center">
                          <Button
                            onClick={() => submitStatusChange(index)}
                            disabled={
                              statusstate.indexOf(index) > -1 ? false : true
                            }
                          >
                            Send
                          </Button>
                        </TableCell>
                      ))}
                    {(user?.is_hr || user?.is_super_hr) && (
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setOpenDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>

                  {row[index] && (
                    <TableRow style={{ background: "azure" }}>
                      <TableCell></TableCell>
                      <TableCell align="center" colSpan={2}>
                        <Box
                          textAlign="center"
                          maxWidth="300px"
                          style={{ wordWrap: "break-word" }}
                        >
                          Location: <b>{item?.location}</b>
                          <br />
                          Reason of journey: <b>{item?.reason}</b>
                          <br />
                          Additional info: <b>{item?.additional_info}</b>
                        </Box>
                      </TableCell>

                      <TableCell align="center" colSpan={2}>
                        <Box
                          textAlign="center"
                          maxWidth="300px"
                          style={{ wordWrap: "break-word" }}
                        >
                          Check-in:<b>{item?.start_date}</b>
                          <br />
                          Check-out:<b>{item?.end_date}</b>
                        </Box>
                      </TableCell>

                      <TableCell align="center" colSpan={1}>
                        <Typography style={{ display: "grid" }}>
                          Employees:
                          {item.travel_requests.map((item: any) => (
                            <b>
                              {item.employee.first_name}{" "}
                              {item.employee.last_name}
                            </b>
                          ))}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}

                  <Dialog
                    open={openDeleteModal}
                    onClose={() => {
                      setOpenDeleteModal(false);
                    }}
                    style={{ alignItems: "center" }}
                  >
                    <DialogTitle>
                      Travel request will be deleted, are you sure?
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        style={{ background: "#F7685B", color: "black" }}
                        onClick={() => {
                          setOpenDeleteModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ background: "#2ED47A", color: "black" }}
                        onClick={() => {
                          deleteTravel(item);
                          setOpenDeleteModal(false);
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TravelRequestList;
