import * as actions from "./actionType";

export function newNavbar(description: any) {
  return {
    type: actions.NEW_NAVBAR,
    payload: description,
  };
}

export function userAuthenticate(user: any) {
  return {
    type: actions.USER_AUTHENTICATE_SUCCESS,
    payload: user,
  };
}

export function userLogout(user: any) {
  return {
    type: actions.USER_LOGOUT,
    payload: user,
  };
}

export function travelRequestId(id: any) {
  return {
    type: actions.TRAVEL_REQUEST_ID,
    payload: id,
  };
}
