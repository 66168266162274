import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import CurrentPerformanceReview from "./CurrentPerformanceReview";
import PerformanceReviewHistory from "./PerformanceReviewHistory";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { useParams } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import { AvatarIcon } from "./Avatar";
import CircularProgress from "@mui/material/CircularProgress";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  alignItems: "center",
  justifyContent: "center",
  alignContent: "space-between",
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.8)",
  width: "110%",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#1890ff",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

interface StyledTabProps {
  label: string;
}

const classes = {
  profileBox: {
    display: "flex",
  },
  avatar: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "0.5rem",
  },
  info: {
    flex: 6,
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  nameLastName: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  description: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
};

const PerformanceReviewRequestDetail: React.FC<any> = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [newState, setNewState] = useState(false);
  const [lastReview, setLastReview] = useState<any>();
  const [value, setValue] = useState(0);
  const [empID, setEmpID] = useState<any>();
  const [loader, setLoader] = useState(true);
  const params = useParams<{ perId: string }>();

  useEffect(() => {
    const fetchData = () => {
      apiService.get(`${APP_URL}/performance-review/pr_request/${params.perId}/`).then((resp) => {
        setData(resp);
        setLoader(false);
      });
    };
    fetchData();
  }, [newState]);

  useEffect(() => {
    const fetchPreviousPRRs = async () => {
      const result = await apiService.get(`${APP_URL}/performance-review/pr_history/`).then((resp: any) => {
        setLastReview(resp.filter((prr: any) => prr.employee.id === data?.employee.id));
      });
    };
    fetchPreviousPRRs();
  }, [data?.employee.id]);

  const setPRForms = () => {
    apiService.get(`${APP_URL}/performance-review/pr_request/${params.perId}/`).then((resp) => {
      setData(resp);
      setEmpID(resp.employee.id);
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <>
      <Dashboard />
      <>
        <Container>
          <Box mt={10} mb={2} style={classes.profileBox}>
            <Box sx={classes.avatar}>
              <AvatarIcon
                src={"data:image/jpeg;base64," + data?.employee?.avatar_64}
                firstName={data?.employee?.user?.first_name}
                lastName={data?.employee?.user?.last_name}
                style={{
                  fontSize: "50px !important",
                  width: 120,
                  height: 120,
                }}
              />
            </Box>
            <Box mt={1} sx={classes.info}>
              <Box p={1} style={classes.nameLastName}>
                <Typography variant="h4">
                  {data?.employee?.user?.first_name} {data?.employee?.user?.last_name}
                </Typography>
              </Box>
              <Box p={1}>
                <Divider />
              </Box>
              <Box p={1} style={classes.description}>
                <Box sx={classes.field}>
                  <Typography variant="overline">CLIENT</Typography>
                  <Box m={1} />
                  <Typography variant="caption"> {data?.employee?.client?.name}</Typography>
                </Box>
                <Box ml={10} sx={classes.field}>
                  <Typography variant="overline">PROJECT</Typography>
                  <Box m={1} />
                  <Typography variant="caption">
                    {data?.employee?.project && data?.employee?.project[0]?.name}
                  </Typography>
                </Box>
                <Box ml={10} sx={classes.field}>
                  <Typography variant="overline">TEAM</Typography>
                  <Box m={1} />
                  <Typography variant="caption">{data?.employee?.team && data?.employee?.team[0]?.name}</Typography>
                </Box>
                <Box ml={10} sx={classes.field}>
                  <Typography variant="overline">ROLE</Typography>
                  <Box m={1} />
                  <Typography variant="caption">{data?.employee?.position?.name}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={5} sx={{ bgcolor: "#fff" }}>
            <AntTabs value={value} onChange={handleChange}>
              <AntTab label="Current performance review" />
              <AntTab label="Performance review history" />
            </AntTabs>
            <Box sx={{ p: 1 }} />
            {value === 0 && <CurrentPerformanceReview data={data} setForms={setPRForms} setNewState={setNewState} />}
            {value === 1 && <PerformanceReviewHistory lastReview={lastReview} />}
          </Box>
        </Container>
      </>
    </>
  );
};

export default PerformanceReviewRequestDetail;
