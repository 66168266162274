import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { IQuestionForm } from "../../PerformanceReviewQuestions/utils/prf.model";
import { IFunctionProps } from "../interfaces/functions.model";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import apiService from "../../../services/apiService";
import { APP_URL } from "../../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import StarsIcon from "@mui/icons-material/Stars";
import { useState } from "react";

interface IProps {
  question_form: IQuestionForm;
  submitted: boolean;
}

const QuestionForm = ({
  secIndex,
  subsecIndex,
  qfIndex,
  question_form,
  submitted,
  prf,
  setPrf,
}: IFunctionProps & IProps) => {
  const updatePrf = (res: IQuestionForm) => {
    setPrf((prevPrf) => ({
      ...prevPrf,
      sections: prevPrf.sections.map((sec, index) => {
        if (index === secIndex) {
          return {
            ...sec,
            subsections: sec.subsections.map((sub, index) => {
              if (index === subsecIndex) {
                return {
                  ...sub,
                  question_forms: sub.question_forms.map((qf, index) => {
                    if (index === qfIndex) {
                      return res;
                    }
                    return qf;
                  }),
                };
              }
              return sub;
            }),
          };
        }
        return sec;
      }),
    }));
  };

  const changeQuestionBody = async (questionId: number, newQuestion: string) => {
    await apiService
      .patch(`${APP_URL}/performance-review/question_formsv2/`, {
        id: questionId,
        question: newQuestion,
      })
      .then((res: IQuestionForm) => updatePrf(res));
  };

  const changeQuestionType = async (questionId: number, isRating: boolean) => {
    await apiService
      .patch(`${APP_URL}/performance-review/question_formsv2/`, {
        id: questionId,
        is_text: !isRating,
      })
      .then((res: IQuestionForm) => updatePrf(res));
  };

  const deleteQuestion = async () => {
    await apiService
      .deleteItem(`${APP_URL}/performance-review/question_formsv2/`, {
        id: prf.sections[secIndex].subsections[subsecIndex].question_forms[qfIndex].id,
      })
      .then(() => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: [
            ...prevPrf.sections.slice(0, secIndex),
            {
              ...prevPrf.sections[secIndex],
              subsections: [
                ...prevPrf.sections[secIndex].subsections.slice(0, subsecIndex),
                {
                  ...prevPrf.sections[secIndex].subsections[subsecIndex],
                  question_forms: [
                    ...prevPrf.sections[secIndex].subsections[subsecIndex].question_forms.slice(0, qfIndex),
                    ...prevPrf.sections[secIndex].subsections[subsecIndex].question_forms.slice(qfIndex + 1),
                  ],
                },
                ...prevPrf.sections[secIndex].subsections.slice(subsecIndex + 1),
              ],
            },
            ...prevPrf.sections.slice(secIndex + 1),
          ],
        }));
      });
  };

  return (
    <Draggable
      draggableId={`sec_${secIndex}_subsec_${subsecIndex}_draggableQf_${qfIndex}`}
      index={qfIndex}
      key={qfIndex}
    >
      {(providedQfDrag, snapshot) => (
        <Box ref={providedQfDrag.innerRef} {...providedQfDrag.draggableProps} key={question_form.id}>
          <Grid container direction="row" spacing={2} sx={{ ml: 5, marginRight: "40px" }}>
            {/* QUESTION TITLE */}
            <Grid item>
              <Typography
                sx={{
                  ml: -1,
                  mt: 6,
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                {qfIndex + 1 + ". "}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                multiline
                sx={{
                  width: "50vw",
                  mt: 5,
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
                placeholder="Question..."
                defaultValue={question_form.question}
                onBlur={(e) =>
                  changeQuestionBody(
                    prf.sections[secIndex].subsections[subsecIndex].question_forms[qfIndex].id,
                    e.target.value
                  )
                }
                error={submitted && question_form.question.trim() === ""}
                helperText={submitted && question_form.question.trim() === "" ? "This field is required" : ""}
              />
            </Grid>
            {/* QUESTION TITLE */}
            <FormControlLabel
              value="Rating"
              control={
                <Checkbox
                  checked={!question_form.is_text}
                  onChange={(e) =>
                    changeQuestionType(
                      prf.sections[secIndex].subsections[subsecIndex].question_forms[qfIndex].id,
                      e.target.checked
                    )
                  }
                />
              }
              label="Rating"
              labelPlacement="bottom"
              sx={{ marginTop: 7, marginRight: 1, width: 40, height: 40 }}
            />
            <IconButton onClick={deleteQuestion} sx={{ marginTop: 7, marginLeft: 3, width: 40, height: 40 }}>
              <DeleteIcon />
            </IconButton>
            <IconButton {...providedQfDrag.dragHandleProps} sx={{ marginTop: 7, marginLeft: 3, width: 40, height: 40 }}>
              <DragIndicatorIcon />
            </IconButton>
          </Grid>
        </Box>
      )}
    </Draggable>
  );
};

export default QuestionForm;
