import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useEffect, useState } from "react";
import { APP_URL } from "../utils/constants";
import Cookies from "js-cookie";
import apiService from "../services/apiService";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ViewerPdf from "./PDFViewer/ViewerPdf";
import UploadForm from "./UploadFunction";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { saveAs } from "file-saver";
import { Container } from "@mui/material";
import { b64toBlob } from "../services/b64Converter";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { decodeToken } from "react-jwt";

function getNameOfDocument(path: string) {
  var splitted = path.split("/");
  var return_string: string = splitted[splitted.length - 1];
  return return_string;
}

function typeChecker(path: string) {
  var splitted = path.split(".");
  return splitted[splitted.length - 1];
}

const pdf = "pdf";

export const FileListFunction = () => {
  const [files, setFiles] = useState<any>();
  const [newState, setNewState] = useState<any>(true);
  const [loaderDoc, setLoaderDoc] = useState(false);
  const [user, setUser] = useState<any>();
  const [isSuperHR, setIsSuperHR] = useState<boolean>();
  const [state, setState] = useState<any>(null);
  const [openedDoc, setOpenedDoc] = useState<any>();
  const params = useParams<{ profileId: string }>();

  let jwt = require("react-jwt");
  let cookie = Cookies.get("myCookie");
  let userId: number;
  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }

  const documentUploaded = () => {
    if (params.profileId) {
      apiService
        .get(`${APP_URL}/users/documents-per-user/${params.profileId}/`)
        .then((resp) => setFiles(resp));
    } else {
      apiService
        .get(`${APP_URL}/users/documents-per-user/${userId}/`)
        .then((resp) => setFiles(resp));
    }
  };

  useEffect(() => {
    if (params.profileId) {
      apiService
        .get(`${APP_URL}/users/documents-per-user/${params.profileId}/`)
        .then((resp) => {
          setFiles(resp);
        });
    } else {
      apiService
        .get(`${APP_URL}/users/documents-per-user/${userId}/`)
        .then((resp) => {
          setFiles(resp);
        });
    }

    apiService.get(`${APP_URL}/users/profile/${userId}/`).then((resp) => {
      setUser(resp);
      setIsSuperHR(resp.is_super_hr);
    });
  }, [newState]);

  const OpenModal = (id: string) => {
    apiService.get(`${APP_URL}/users/documents/${id}/`).then((resp) => {
      setOpenedDoc("data:image/png;base64," + resp.file);
      setState(resp.title);
    });
  };

  const CloseModal = () => {
    setState(null);
  };

  const deleteFile = (id: any) => {
    setLoaderDoc(true);
    apiService
      .deleteItem(`${APP_URL}/users/documents/`, { id })
      .then(() => (setNewState(!newState), setLoaderDoc(false)));
  };

  const Download = (props: any) => {
    const downloadFile = () => {
      apiService.get(`${APP_URL}/users/documents/${props.id}/`).then((resp) => {
        let docName = resp.title;
        let file = b64toBlob(resp.file);
        saveAs(file, docName);
      });
    };

    return (
      <Box>
        <Button
          startIcon={<FileDownloadIcon />}
          onClick={downloadFile}
        ></Button>
      </Box>
    );
  };

  return (
    <Container>
      <Box>
        {user && isSuperHR ? (
          <Box>
            <UploadForm
              onDocumentUploaded={documentUploaded}
              setLoaderDoc={setLoaderDoc}
            />
          </Box>
        ) : (
          <div></div>
        )}

        <Typography variant="h5" align="center">
          List of Documents
        </Typography>
        <Box ml={10} mr={15}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ whiteSpace: "nowrap" }}>
              <TableRow>
                <TableCell sx={{ width: "15vw" }}>FILE </TableCell>
                <TableCell sx={{ width: "5vw" }}>DOWNLOAD</TableCell>
                {isSuperHR ? (
                  <TableCell sx={{ width: "5vw" }}>DELETE</TableCell>
                ) : (
                  " "
                )}
              </TableRow>
            </TableHead>
            <TableBody style={{ whiteSpace: "nowrap" }}>
              {loaderDoc ? (
                <CircularProgress
                  style={{ marginTop: "20%", marginLeft: "60%" }}
                />
              ) : (
                files &&
                files.map((item: any) => (
                  <TableRow>
                    <TableCell sx={{ width: "15vw" }}>
                      <Button
                        onClick={() => OpenModal(`${item.id}`)}
                        sx={{
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          textAlign: "left",
                        }}
                      >
                        {/*Transforming the actual file title to doctype */}
                        <b>{item.type}</b>
                      </Button>
                      {typeChecker(`${item.title}`) === pdf ? (
                        <Modal
                          open={state === `${item.title}`}
                          onClose={CloseModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          sx={{
                            marginTop: 5,
                            height: 680,
                            width: 680,
                            left: "30%",
                          }}
                        >
                          <ViewerPdf file={`${openedDoc}`} />
                        </Modal>
                      ) : (
                        <Modal
                          open={state === `${item.title}`}
                          onClose={CloseModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          sx={{
                            height: 800,
                            width: 800,
                            top: "5%",
                            paddingBottom: "5%",
                            left: "30%",
                            overflowY: "scroll",
                          }}
                        >
                          <div>
                            <img
                              src={openedDoc}
                              loading="lazy"
                              width="100%"
                            />
                          </div>
                        </Modal>
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ ml: 2 }}>
                        <Download id={item.id} />
                      </Box>
                    </TableCell>
                    {isSuperHR ? (
                      <TableCell>
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={(e) => deleteFile(item.id)}
                          style={{ color: "#1976d2" }}
                        ></Button>
                      </TableCell>
                    ) : (
                      " "
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Container>
  );
};
