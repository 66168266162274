import React, { Fragment, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Login } from "./pages/Login";
import Dashboard from "./pages/Dashboard";
// import ModernCalendar from "./pages/Calendar";
import ActionAreaCard from "./pages/Card";
import ColorChoise from "./pages/Color-picker";
import ApplicationForm from "./pages/Applyform";
import ClientPage from "./pages/ClientPage";
import ApplicationFormPositions from "./pages/ApplyFormPosition";
import ImageUploadFunction from "./pages/ImageUpload";
//import LogoUploadFunction from './components/LogoUpload';
import CandidatesApp from "./pages/Candidates";
import GetCandidates from "./pages/CandidatesPath";
import CreatePhases from "./pages/AddPhases";
import CreatePosition from "./pages/AddPostions";
import CandidateDetails from "./pages/CandidatesDetail";
import Employee from "./pages/Employee";
import Navbar from "./components/Navbar";
// import SignUp from './pages/UpdateInfoPage';
import UpdateInfoPage from "./pages/UpdateInfoPage";
import RequestPopUp from "./pages/RequestPopUp";
import InvitationLetter from "./pages/InvitationLetter";
import SetPasswordPage from "./pages/SetPasswordPage";
import PrivateRoute from "./components/Router";
import Cookies from "js-cookie";
import { AddUser } from "./pages/AddUser";
import { Role } from "./services/roles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { FileListFunction } from "./components/UploadDocuments";
import CreateUser from "./components/HRDashboard/HRDashboard";
import AllEmployeesList from "./components/HRDashboard/ListEmployees";
import PerformanceReviewQuestion from "./pages/PerformanceReviewQuestions/PerformanceReviewQuestions";
import GeneralInfoTabs from "./components/GeneralInfoTabs";
import AllNotificationsList from "./components/NotificationList";
import PerformanceReviewRequestDetail from "./components/PerformanceReviewRequestDetail";
import PerformanceReviewEdit from "./components/PerformanceReviewForm";

import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { PersistGate } from "redux-persist/integration/react";
import mainReducer from "./store/index";
import logger from "redux-logger";
import { get } from "lodash";
import { Provider as ReduxProvider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import SideNavbar from "./components/Sidebar";
import { useSelector } from "react-redux";
import apiService from "./services/apiService";
import { APP_URL } from "./utils/constants";
import { CompanyOverview } from "./components/CompanyOverview";
import { ClientHomePage } from "./components/ClientHomePage";
import TravelRequestDetail from "./components/TravelRequestDetail";
import { Structure } from "./pages/Structure";
import { PathToSuccess } from "./pages/PathToSuccess";
import { OurTeam } from "./pages/OurTeam/OurTeam";
import PerformanceReviewTemplate from "./pages/PerformanceReviewTemplate/PerformanceReviewTemplate";
import PRFTemplate from "./pages/PRFTemplate";
import ViewDocument from "./pages/Processes/ViewDocument";
import EditDocument from "./pages/Processes/EditDocument";
import CreateDocument from "./pages/Processes/CreateDocument";
import Processes from "./pages/Processes/Processes";
import RecruitmentLanding from "./pages/RecruitmentLanding";
import ContainerDnD from "./components/DragAndDrop/ContainerDnd";
import { Authenticator } from "./components/Authenticator";
import { Cookie } from "@mui/icons-material";
import PerformanceReviewList from "./components/PerformanceReviewList";

const qcerrisTheme = createTheme({
  typography: {
    fontFamily: "'Noto Sans'",
    h1: {
      fontSize: "4.25rem",
    },
    h2: {
      fontSize: "2.625rem",
    },
    h3: {
      fontSize: "1.625rem",
    },
    h4: {
      fontSize: "1rem",
    },
  },
});

const persistConfig = {
  key: "reducer",
  storage: storageSession,
};

const composeEnhancers =
  get(window, "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__") || compose;

const persistedReducer = persistReducer(persistConfig, mainReducer);
function App() {
  const [is_valid, setValid] = useState<boolean>(false);

  let cookie = Cookies.get("myCookie");
  const persistedReducer = persistReducer(persistConfig, mainReducer);
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(logger))
  );
  const persistor = persistStore(store);

  return (
    <div className="App">
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={qcerrisTheme}>
            <CssBaseline />
            <BrowserRouter>
              <Navbar />
              <SideNavbar>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/candidates" element={<CandidatesApp />} />
                  <Route
                    path="/apply/:position"
                    element={<ApplicationForm />}
                  />
                  <Route
                    path="/apply-positions"
                    element={<ApplicationFormPositions />}
                  />
                  <Route
                    path="/set-password-page/:token"
                    element={<SetPasswordPage />}
                  />

                  {cookie ? (
                    <Route element={<Navigate to="main/overview" />} />
                  ) : (
                    <Route path="/login" element={<Login />} />
                  )}

                  <Route element={<PrivateRoute />}>
                    <Route
                      path="/main/recruitment-landing"
                      element={<RecruitmentLanding />}
                    />
                    <Route
                      path="/main/candidate-details/:id"
                      element={<CandidateDetails />}
                    />
                    <Route
                      path="/main/candidate-dnd/:id"
                      element={<ContainerDnD />}
                    />
                    <Route
                      path="/main/overview"
                      element={<CompanyOverview />}
                    />
                    <Route
                      path="/main/client/overview"
                      element={<ClientHomePage />}
                    />
                    <Route path="/main/:pageName" element={<Dashboard />} />
                    <Route
                      path="/notification-list"
                      element={<AllNotificationsList />}
                    />
                    {/* <Route path="/calendar" element={<ModernCalendar />} /> */}
                    <Route
                      path="/travel-request-detail/:idTravel"
                      element={<TravelRequestDetail />}
                    />
                    <Route path="/card" element={<ActionAreaCard />} />
                    <Route path="/logo-upload" element={<AllEmployeesList />} />
                    <Route path="/create-user" element={<CreateUser />} />
                    <Route
                      path="/prf-questions/:employeeId"
                      element={<PerformanceReviewQuestion />}
                    />
                    <Route
                      path="/main/performance-review-list"
                      element={<PerformanceReviewList />}
                    />
                    <Route
                      path="/prf-template/:prfId"
                      element={<PerformanceReviewTemplate />}
                    />
                    <Route
                      path="/performance-review-request-detail/:perId"
                      element={<PerformanceReviewRequestDetail />}
                    />
                    <Route
                      path="/performance-review-question/:employeeId/:formId/:form"
                      element={<PerformanceReviewEdit />}
                    />
                    <Route path="/clientPage" element={<ClientPage />} />
                    <Route
                      path="/processes/view-document/:documentId"
                      element={<ViewDocument />}
                    />
                    <Route
                      path="/processes/edit-document/:documentId"
                      element={<EditDocument />}
                    />
                    <Route
                      path="/processes/create-document"
                      element={<CreateDocument />}
                    />
                    <Route path="/processes" element={<Processes />} />
                    <Route path="/themepicker" element={<ColorChoise />} />
                    <Route path="/path" element={<GetCandidates />} />
                    <Route path="/phase" element={<CreatePhases />} />
                    <Route path="/position" element={<CreatePosition />} />
                    <Route path="/employee/:profileId" element={<Employee />} />
                    <Route
                      path="/update-info-page"
                      element={<UpdateInfoPage />}
                    />
                    <Route
                      path="/set-password-page"
                      element={<SetPasswordPage />}
                    />
                    <Route path="/request-pop-up" element={<RequestPopUp />} />
                    <Route
                      path="/invitation-letter"
                      element={<InvitationLetter />}
                    />
                    <Route path="/structure" element={<Structure />} />
                    <Route
                      path="/path-to-success"
                      element={<PathToSuccess />}
                    />
                    <Route path="/our-team" element={<OurTeam />} />
                    <Route path="/prf-template" element={<PRFTemplate />} />
                  </Route>

                  {cookie ? (
                    <Route element={<Navigate to="main/overview" />} />
                  ) : (
                    <Route path="/" element={<Navigate to="login" />} />
                  )}
                </Routes>
              </SideNavbar>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </div>
  );
}
export default App;
