import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  width: 450,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const AddTeam = ({
  newTeam,
  Client,
  patchClient,
  setTeamUser
}: {
  newTeam: any;
  Client: any;
  patchClient: any;
  setTeamUser:any
}) => {
  const [open, setOpen] = useState<any>(false);
  const [team, setTeam] = useState<string>("");

  let data = {
    name: team,
  };

  const handleClick = () => {
    apiService
      .post(`${APP_URL}/users/teams/`, data)
      .then(async () => {
        const result = await apiService.get(`${APP_URL}/users/teams/`);
        var listIDs: string[] = result.map((t: any) => t.id);
        var helper = result.filter(
          (t: any) => t.name === team && t.id === listIDs[listIDs.length - 1]
        );
        setTeamUser(helper)
        let patchData = {
          id: Client.id,
          teams: [helper[0].id],
        };

        apiService.patch(`${APP_URL}/users/client/`, patchData);
        // patchClient();
        newTeam();
        Toast.fire({
          icon: "success",
          title: "Team successfully added!",
          width: 480,
          background: "#a5dc86",
        });
        setOpen(false);
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: "Error Adding Team!!",
          background: "#f27474",
        });
      });
  };

  let size: number = 0;
  if (Client && Object.keys(Client).length > 0) {
    size = 1;
  }

  var boolVar: boolean = size === 1;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        Add New Team To Selected Client
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {boolVar ? (
          <Box sx={style}>
            <div>
              <Box sx={{ marginTop: "15px" }}>
                <div>
                  <Typography variant="subtitle1">Add New Team:</Typography>
                </div>
                <div>
                  <TextField
                    size="small"
                    id="demo-helper-text-aligned"
                    name="client"
                    placeholder="Team name"
                    onChange={(e) => setTeam(e.target.value)}
                  />
                </div>
                <div>
                  <Button
                    sx={{ marginTop: "1.5rem" }}
                    variant="contained"
                    startIcon={<AddCircleOutline />}
                    onClick={handleClick}
                  >
                    ADD
                  </Button>
                </div>
              </Box>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please Select Client
            </Typography>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default AddTeam;
