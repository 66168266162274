import axios from "axios";
import Cookies from "js-cookie";

const apiService = {
  get,
  getFile,
  post,
  patch,
  deleteItem,
};

function checkJWT(Authorization: any) {
  try {
    if (!Authorization)
      logout();
    else {
      const decodedJwt = JSON.parse(atob(Authorization.split(".")[1]));
      if (decodedJwt.exp * 1000 < Date.now()) {
        logout();
      }
    }
  } catch (e) {
    console.error(e);
  }
}

async function get(url: any) {

  const requestOptions = {
    headers: authHeader(url),
  };

  const response = await axios.get(url, requestOptions);

  return handleResponse(response);
}

async function getFile(url: any) {
  checkJWT(authHeader(url).Authorization);

  const response = await axios.get(url, {
    headers: authHeader(url),
    responseType: "blob",
  });

  return handleResponse(response);
}

async function post(
  url: any,
  body: any,
  contentType: string = "application/json",
  checkToken: boolean = true
  
) {
  const requestOptions = {
    headers: { "Content-Type": contentType, ...authHeader(url) },
  };

  if(checkToken){
    checkJWT(requestOptions.headers.Authorization);
  }
  const response = await axios.post(url, body, requestOptions).catch((error)=>{return error.response});
  if (!url.includes("login"))
  return handleResponse(response);
  else
  return handleResponse(response, url);
}

async function patch(
  url: any,
  body: any,
  contentType: string = "application/json"
) {
  const requestOptions = {
    headers: { "Content-Type": contentType, ...authHeader(url) },
  };

  checkJWT(requestOptions.headers.Authorization);

  const response = await axios.patch(url, body, requestOptions);

  return handleResponse(response);
}

async function deleteItem(
  url: any,
  body: any,
  contentType: string = "application/json"
) {
  const requestOptions = {
    headers: { "Content-Type": contentType, ...authHeader(url) },
    data: body,
  };

  checkJWT(requestOptions.headers.Authorization);

  const response = await axios.delete(url, requestOptions);

  return handleResponse(response);
}

function authHeader(url: any) {
  return Cookies.get("myCookie") ? { Authorization: `${Cookies.get("myCookie")}` } : {};
}

function handleResponse(response: any, url:any = "") {
  const data = response.data;
  if(!url.includes("login")) 
  if (response.statusText !== "OK") {       
    if ([401, 403].includes(response.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      logout();
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  
  }
  return data;
}

async function logout() {
  Cookies.remove("myCookie", { path: "/" });
  window.location.href = window.location.origin + "/login";
}

export default apiService;
