import { Box, Button, Container, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridToolbarQuickFilter,
  GridActionsCellItem,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import StarIcon from "@mui/icons-material/Star";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import saveAs from "file-saver";
import { Link } from "react-router-dom";

interface IDocument {
  id: number;
  name: string;
  body: string;
  created_by: string;
  created_at: Date;
  is_favorite: boolean;
}
interface IDraftDocument {
  id: number;
  name: string;
  body: string;
  created_by: string;
  created_at: Date;
}
const Processes = () => {
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [draftDocuments, setDraftDocuments] = useState<IDraftDocument[]>([]);

  let currentUser = useSelector((state: any) => state.reducer.user);
  const is_hr = currentUser.is_super_hr || currentUser.is_hr;
  useEffect(() => {
    const fetchDocuments = async () => {
      const result_info: any[] = await apiService.get(
        `${APP_URL}/processes/documents/`
      );

      let docs: IDocument[] = [];
      let draftDocs: IDraftDocument[] = [];
      result_info.forEach((document: any) => {
        if (document.is_draft) {
          draftDocs.push({
            id: document.id,
            name: document.name,
            body: document.body,
            created_at: new Date(document.created_at),
            created_by: `${document.created_by.first_name} ${document.created_by.last_name}`,
          });
        } else {
          docs.push({
            id: document.id,
            name: document.name,
            body: document.body,
            created_at: new Date(document.created_at),
            created_by: `${document.created_by.first_name} ${document.created_by.last_name}`,
            is_favorite: currentUser.favorite_documents.some(
              (favorite_document: any) => favorite_document.id === document.id
            ),
          });
        }
      });
      docs = docs.sort((document) => {
        return document.is_favorite ? -1 : 1;
      });
      setDocuments(docs);
      setDraftDocuments(draftDocs);
    };
    fetchDocuments();
  }, [setDocuments, currentUser, setDraftDocuments]);

  const handleFavorite = useCallback(
    async (params: GridValueGetterParams) => {
      let patchData = {
        document_id: params.row.id,
      };
      await apiService
        .patch(`${APP_URL}/processes/favorites/`, patchData)
        .catch((message) => {
          let updatedDocument = documents.map((document: any) => {
            if (document.id === params.row.id) {
              if (!document.is_favorite) {
                currentUser.favorite_documents.push(document);
              } else {
                let filteredFavoriteDocuments =
                  currentUser.favorite_documents.filter(
                    (item: any) => item.id !== document.id
                  );
                currentUser.favorite_documents = filteredFavoriteDocuments;
              }
              return { ...document, is_favorite: !document.is_favorite };
            }
            return document;
          });

          setDocuments(updatedDocument);
        });
    },
    [currentUser, documents]
  );

  const handleEdit = useCallback((params: GridValueGetterParams) => {}, []);

  const handleDelete = useCallback(
    async (params: GridValueGetterParams) => {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          apiService
            .deleteItem(`${APP_URL}/processes/documents/`, {
              document_id: params.row.id,
            })
            .then((resp) => {
              if (resp.message === `Something went wrong!`)
                Swal.fire(resp.message, "", "error");
              else {
                Swal.fire("Document deleted!", "", "success");
                let filteredDocuments = documents.filter(
                  (item) => item.id !== params.id
                );
                let filteredDraftDocuments = draftDocuments.filter(
                  (item) => item.id !== params.id
                );
                setDocuments(filteredDocuments);
                setDraftDocuments(filteredDraftDocuments);
              }
            });
        }
      });
    },
    [documents, draftDocuments]
  );

  const handleDownload = useCallback((params: GridValueGetterParams) => {
    apiService
      .getFile(`${APP_URL}/processes/documents/pdf/${params.row.id}/`)
      .then((resp) => {
        saveAs(resp, `${params.row.name}`);
      });
  }, []);

  const draftColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        hide: true,
        align: "left",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "name",
        headerName: "Document Name",
        align: "center",
        headerAlign: "center",
        type: "string",
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Box
              display="flex"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <DescriptionOutlinedIcon />

              <Typography marginLeft={1}>{params.formattedValue}</Typography>
            </Box>
          );
        },
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Created",
        align: "center",
        headerAlign: "center",
        type: "date",
        flex: 1,
      },
      {
        field: "created_by",
        headerName: "Created By",
        align: "center",
        headerAlign: "center",
        type: "string",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        filterable: false,
        sortable: false,
        flex: 1,
        type: "actions",
        // getActions: (params: GridValueGetterParams) => [
        //   <Link
        //     to={{
        //       pathname: `/processes/edit-document/${params.row.id}`,
        //     }}
        //     style={{ textDecoration: "none" }}
        //   >
        //     <GridActionsCellItem
        //       sx={{ "&:hover": { color: "green" } }}
        //       icon={<BorderColorOutlinedIcon />}
        //       label="Edit"
        //       onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //         handleEdit(params);
        //       }}
        //     />
        //   </Link>,
        //   <GridActionsCellItem
        //     sx={{ "&:hover": { color: "red" } }}
        //     icon={<DeleteOutlineOutlinedIcon />}
        //     label="Delete"
        //     onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //       handleDelete(params);
        //     }}
        //   />,
        // ],
      },
    ],
    [handleEdit, handleDelete]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        hide: true,
        align: "left",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "name",
        headerName: "Document Name",
        align: "center",
        headerAlign: "center",
        type: "string",
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Box
              display="flex"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <DescriptionOutlinedIcon />
              <Link
                to={{
                  pathname: `/processes/view-document/${params.row.id}`,
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Typography marginLeft={1}>{params.formattedValue}</Typography>
              </Link>
            </Box>
          );
        },
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Created",
        align: "center",
        headerAlign: "center",
        type: "date",
        flex: 1,
      },
      {
        field: "created_by",
        headerName: "Created By",
        align: "center",
        headerAlign: "center",
        type: "string",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        filterable: false,
        sortable: false,
        flex: 1,
        type: "actions",
        // getActions: (params: GridValueGetterParams) => [
        //   <GridActionsCellItem
        //     sx={{
        //       "&:hover": { color: params.row.is_favorite ? "black" : "gold" },
        //       color: params.row.is_favorite ? "gold" : "black",
        //     }}
        //     icon={<StarIcon />}
        //     label="Favorite"
        //     onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //       handleFavorite(params);
        //     }}
        //   />,

        //   is_hr ? (
        //     <Link
        //       to={{
        //         pathname: `/processes/edit-document/${params.row.id}`,
        //       }}
        //       style={{ textDecoration: "none" }}
        //     >
        //       <GridActionsCellItem
        //         sx={{ "&:hover": { color: "green" } }}
        //         icon={<BorderColorOutlinedIcon />}
        //         label="Edit"
        //         onClick={(
        //           e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        //         ) => {
        //           handleEdit(params);
        //         }}
        //       />
        //     </Link>
        //   ) : (
        //     <></>
        //   ),
        //   is_hr ? (
        //     <GridActionsCellItem
        //       sx={{ "&:hover": { color: "red" } }}
        //       icon={<DeleteOutlineOutlinedIcon />}
        //       label="Delete"
        //       onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //         handleDelete(params);
        //       }}
        //     />
        //   ) : (
        //     <></>
        //   ),
        //   <GridActionsCellItem
        //     sx={{ "&:hover": { color: "blue" } }}
        //     icon={<CloudDownloadOutlinedIcon />}
        //     label="Download"
        //     onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //       handleDownload(params);
        //     }}
        //   />,
        // ],
      },
    ],
    [is_hr, handleFavorite, handleEdit, handleDelete, handleDownload]
  );

  return (
    <Container> 
      <Box mt={12} sx={{ justifyContent: "space-between", display: "flex" }}>
        <Typography
          variant="h1"
          gutterBottom
          align="left"
          fontWeight={500}
          fontSize={48}
        >
          Processes
        </Typography>
        {is_hr && (
          <Link
            to={`/processes/create-document`}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#109CF1",
                color: "white",
                height: "40px",
                marginTop: "10px"
              }}
            >
               + Add document
            </Button>
          </Link>
        )}
      </Box>

      <DataGrid
        columns={columns}
        rows={documents}
        density="comfortable"
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        components={{ Toolbar: QuickSearchToolbar }}
        sx={{
          backgroundColor: "white",
          boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.05)",
          "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&>.MuiDataGrid-main": {
            "&>.MuiDataGrid-columnHeaders": {
              borderBottom: "none",
            },

            "& div div div div >.MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          },
        }}
      />

      {is_hr && draftDocuments.length > 0 && (
        <Box sx={{marginBottom: "20px"}}>
          <Typography variant="h3" align="left" sx={{ marginTop: "50px", marginBottom: "20px"  }}>
            Drafts
          </Typography>
          <DataGrid
            columns={draftColumns}
            rows={draftDocuments}
            density="comfortable"
            autoHeight
            disableSelectionOnClick
            disableColumnMenu
            components={{ Toolbar: QuickSearchToolbar }}
            sx={{
              backgroundColor: "white",
              boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.05)",
              "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&>.MuiDataGrid-main": {
                "&>.MuiDataGrid-columnHeaders": {
                  borderBottom: "none",
                },

                "& div div div div >.MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </Box>
      )}
    </Container>
  );
};

export default Processes;

const QuickSearchToolbar = () => {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        mb: 3,
        mt: 2,
      }}
    >
      <GridToolbarQuickFilter placeholder="Find document" />
    </Box>
  );
};
