import React from "react";
import Send from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export interface FormDataType {
  date: Date | undefined;
  performance_review: string;
  other_reasons: string;
}

const initFormData: FormDataType = {
  date: undefined,
  performance_review: "",
  other_reasons: "",
};

const RequestPopUp = () => {
  const [formData, setFormData] = useState(initFormData);

  const handleChangePR = (e: any) => {
    setFormData({ ...formData, performance_review: e.target.value });
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, other_reasons: e.target.value });
  };

  const handleClick = () => {
    console.log(formData);
  };

  const options = [
    "Changing teams/positions",
    "Good feedback",
    "Underperformance/ bad feedback",
  ];

  return (
    <Container maxWidth="md">
      <Box
        mt={15}
        // sx={{
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "end",
        // paddingRight: "4rem",
        // height: "10vw",
        // }}
      >
        <Typography variant="body1">Datum</Typography>
      </Box>
      <Box mt={3} sx={{ minWidth: 120 }}>
        <Typography>Why are you requesting performance review?</Typography>

        <FormControl sx={{ width: "50%" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.performance_review}
            name="performance_review"
            onChange={handleChangePR}
          >
            {options.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography marginTop={3}>Other reasons</Typography>
        <TextField
          sx={{ width: "50%" }}
          id="outlined-basic"
          label="Other reasons"
          variant="outlined"
          name="other_reasons"
          onChange={handleChange}
        ></TextField>
      </Box>
      <Box>
        <Button
          sx={{ width: "50%", marginTop: "3rem" }}
          variant="contained"
          endIcon={<Send />}
          onClick={handleClick}
        >
          SEND REQUEST
        </Button>
      </Box>
    </Container>
  );
};

export default RequestPopUp;
