import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { APP_URL } from "../utils/constants";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import apiService from "../services/apiService";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Stack from "@mui/material/Stack";
import { Box, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Swal from "sweetalert2";
import { decodeToken } from "react-jwt";

const classes = ({
  list: {
    display: "flex",
    // flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  listitem: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: "0.5rem 2rem",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Input = styled("input")({
  display: "none",
});

const nameOfDocuments = [
  "ID card",
  "Employment Contract",
  "Certificate of employment",
  "Degree of vocational education",
  "Scan of the highest certificate/diploma obtained",
  "Certificate of impunity",
  "Annex to the contract",
  "Notice of Annex to the contract",
  "Decision on annual leave",
  "NDA",
  "Equipment check-out form",
  "Bank documents",
  "Notice on processing employees’ personal data",
  "Protection of whistleblowers document",
  "Notice on prevention of harassment at work",
  "Benefits",
  "Decision of business trip",
  "Business trip order",
  "Decision of paid leave",
  "Decision of unpaid leave",
  "Equipment loan agreement",
  "Official doctors notice"
];

export default function UploadForm({ onDocumentUploaded, setLoaderDoc }: any) {
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);
  const [file, setFile] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [disabled, setDisabled] = useState(true)
  const params = useParams<{ profileId: string }>();

  let jwt = require("react-jwt");

  let cookie = Cookies.get("myCookie");
  let userId: string;
  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }

  const Toast = Swal.mixin({
    toast: true,
    width: 450,
    color: 'white',
    iconColor: 'white',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  })

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const addFile = (e: any) => {
    const files = e.target.files;
    if (files[0]?.size < 67108864) {
      setFile(files[0]);
    } else if(!files){
      Toast.fire({
        icon: "error",
        title: "File is too large! Max file size is 63MB!",
        background: "#f27474",
      });
    }
    e.target.value = "";
    setDisabled(false)
  };

  const postDocument = () => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("title", title);
    formData.append("user", params.profileId ? params.profileId : userId);
    setLoaderDoc(true);
    if (file) {
      apiService
        .post(`${APP_URL}/users/documents/`, formData, "multipart/form-data")
        .then(() => {
          onDocumentUploaded();
          Toast.fire({
            icon: 'success',
            title: 'Document successfully uploaded!',
            width: 480,
            background: '#a5dc86'
          })
          setTitle("");
          setFileName("");
          setLoaderDoc(false);
        })
        .catch(() => {
          setLoaderDoc(false);
          Toast.fire({
            icon: 'error',
            title: 'Please upload a valid file!',
            background: '#f27474'
          })
        });
        setDisabled(true)
    }
  };

  useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);


  const usePlaceholderStyles = ({
    placeholder: {
      color: "#aaa"
    }
  });
  
  const Placeholder = ({ children }: any) => {
    return <div style={usePlaceholderStyles.placeholder}>{children}</div>;
  };

  return (
    <Container maxWidth="md">
      <Box mt={5} mb={5}>
        <form onSubmit={submitForm} encType="multipart/form-data">
          <Box style={classes.list}>
            <Box style={classes.listitem}>
              <Stack>
                <label htmlFor="document-button-file">
                  <Input
                    id="document-button-file"
                    type="file"
                    accept=".jpeg, .jpg, .png, .pdf, .docx, .doc, .txt"
                    name="document-button-file"
                    onChange={(e) => addFile(e)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    startIcon={<AddCircleOutlineIcon />}
                    fullWidth
                  >
                    Add document
                  </Button>
                </label>
              </Stack>
            </Box>
            {fileName ? (
                <UploadFileIcon
                  style={{ marginRight: "19px", color: "#0963bc"}}
                />
            ) : (
              <UploadFileIcon
                style={{ marginRight: "19px", color: "#0963bc", visibility: "hidden"}}
            />            )}
            <Select
              style={{ width: "250px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="position.name"
              displayEmpty
              value={title}
              renderValue ={
                title !== "" ? undefined : () => <Placeholder>Document Type</Placeholder>
              }
              onChange={(e: any) => {
                setTitle(e.target.value);
              }}
            >
              {nameOfDocuments.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <Box style={classes.listitem}>
              <Button
                color="primary"
                variant="contained"
                onClick={postDocument}
                fullWidth
                startIcon={<UploadFileIcon />}
                disabled={(disabled || title==="")}
              >
                Upload
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
