import { useEffect, useState } from "react";
import { APP_URL } from "../utils/constants";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import apiService from "../services/apiService";
import { UserClass } from "../classes/usersFactory";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import SearchBar from "@mui/material";
import { TableSortLabel } from '@mui/material';
import { AvatarIcon } from "../components/Avatar";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";

const classes = ({
  table: {
    marginTop: "20px",
    background: "#ffffff",
  },
  button: {
    color: "#323C47",
  },
});

var jwt = require("react-jwt");

export default function InactiveEmployeeList({ setId }: any) {
  const [employeeList, setEmployeeList] = useState<UserClass[]>([]);
  const [empList, setEmpList] = useState<UserClass[]>([]);
  const [user, setUser] = useState<any>("");
  const [loader, setLoader] = useState(true);
  const [sort_by_name, setSortByName] = useState<boolean>(true);
  const [sort_by_client, setSortByClient] = useState<boolean>(true);
  const [sort_by_team, setSortByTeam] = useState<boolean>(true);
  const [sort_by_position, setSortByPosition] = useState<boolean>(true);
  const [sort_by_seniority, setSortBySeniority] = useState<boolean>(true);
  const [searched, setSearched] = useState<string>("");
  const [originalData, setOriginalData] = useState([]);

  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;
  let currentUser = useSelector((state: any) => state.reducer.user);

  const sortName = () => {

    if (sort_by_name === true) {
      setSortByName(false);
    }
    else if (sort_by_name === false) {
      setSortByName(true);
    }
    let newData;
    if (sort_by_name === true)
      newData = empList.sort(
        (objA, objB) => objA.user.first_name.localeCompare(objB.user.first_name),
      );
    else if (sort_by_name === false)
      newData = empList.sort(
        (objA, objB) => objB.user.first_name.localeCompare(objA.user.first_name),
      );

    setEmpList(newData);
  };

  const sortClient = () => {

    if (sort_by_client === true) {
      setSortByClient(false);
    }
    else if (sort_by_client === false) {
      setSortByClient(true);
    }
    let newData;
    if (sort_by_client === true)
      newData = empList.sort(
        (objA, objB) => objA.client?.name.localeCompare(objB.client?.name),
      );
    else if (sort_by_client === false)
      newData = empList.sort(
        (objA, objB) => objB.client?.name.localeCompare(objA.client?.name),
      );

    setEmpList(newData);
  };

  const sortTeam = () => {

    if (sort_by_team === true) {
      setSortByTeam(false);
    }
    else if (sort_by_team === false) {
      setSortByTeam(true);
    }
    let newData;
    if (sort_by_team === true)
      newData = empList.sort(
        (objA: any, objB: any) => objA?.team[0]?.name?.localeCompare(objB?.team[0]?.name),
      );
    else if (sort_by_team === false)
      newData = empList.sort(
        (objA: any, objB: any) => objB?.team[0]?.name?.localeCompare(objA?.team[0]?.name),
      );

    setEmpList(newData);
  };

  const sortPosition = () => {

    if (sort_by_position === true) {
      setSortByPosition(false);
    }
    else if (sort_by_position === false) {
      setSortByPosition(true);
    }
    let newData;
    if (sort_by_position === true)
      newData = empList.sort(
        (objA, objB) => objA.position?.name?.localeCompare(objB.position?.name),
      );
    else if (sort_by_position === false)
      newData = empList.sort(
        (objA, objB) => objB.position?.name?.localeCompare(objA.position?.name),
      );

    setEmpList(newData);
  };

  const sortSeniority = () => {

    if (sort_by_seniority === true) {
      setSortBySeniority(false);
    }
    else if (sort_by_seniority === false) {
      setSortBySeniority(true);
    }
    let newData;
    if (sort_by_seniority === true)
      newData = empList.sort(
        (objA, objB) => objA.seniority?.name?.localeCompare(objB.seniority?.name),
      );
    else if (sort_by_seniority === false)
      newData = empList.sort(
        (objA, objB) => objB.seniority?.name?.localeCompare(objA.seniority?.name),
      );

    setEmpList(newData);
  };


  const requestSearch = (searchedVal: string) => {
    const filteredRows = employeeList.filter((dataItem) => {
      let employeeFirstNameSearch = dataItem.user.first_name.toLowerCase().includes(searchedVal.toLowerCase());
      let employeeLastNameSearch = dataItem.user.last_name.toLowerCase().includes(searchedVal.toLowerCase());
      let employeeClientSearch = dataItem?.client?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      let tempObject: any = dataItem;
      let employeeTeamSearch = tempObject?.team[0]?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      let employeePositionSearch = dataItem?.position?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      let employeeSenioritySearch = dataItem?.seniority?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      return employeeFirstNameSearch || employeeLastNameSearch || employeeClientSearch || employeeTeamSearch || employeePositionSearch || employeeSenioritySearch;
    });
    setEmpList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };


  let navigate = useNavigate();


  useEffect(() => {
    setUser(currentUser);
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.is_super_hr && !user.is_hr) {
        navigate("/main/employee-list");
      } else {
        const url = `${APP_URL}/users/profile/inactive/`;
        apiService.get(url).then((resp: any) => {
          let reverseListEmp = resp.reverse();
          setEmployeeList(reverseListEmp);
          setEmpList(reverseListEmp);
          setOriginalData(reverseListEmp);
          setLoader(false);
        });
      }
    }
  }, [user]);

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Container>
      <Box>
        <Box mt={15}></Box>
      </Box>
      {/* <SearchBar
        value={searched}
        onChange={(searchVal: any) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
      /> */}
      <Table style={classes.table}>
        <TableHead style={{ whiteSpace: "nowrap" }}>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={true}
                onClick={sortName}
                direction={sort_by_name ? "asc" : "desc"}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={true}
                onClick={sortClient}
                direction={sort_by_client ? "asc" : "desc"}
              >
                Client
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={true}
                onClick={sortTeam}
                direction={sort_by_team ? "asc" : "desc"}
              >
                Team
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={true}
                onClick={sortPosition}
                direction={sort_by_position ? "asc" : "desc"}
              >
                Position
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={true}
                onClick={sortSeniority}
                direction={sort_by_seniority ? "asc" : "desc"}
              >
                Seniority
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ whiteSpace: "nowrap" }}>
          {empList.length > 0 &&
            empList.map((emp: any) => (
              <TableRow>
                <TableCell>
                  <div style={{display:"flex"}}>
                    <AvatarIcon
                      src={"data:image/jpeg;base64," + emp?.avatar_64}
                      firstName={emp?.user?.first_name}
                      lastName={emp?.user?.last_name}
                    />
                    <Button
                      style={classes.button}
                      component={RouterLink}
                      to={`/employee/${emp?.id}`}
                      onClick={() => {
                        setId(emp.id);
                      }}
                    >
                      <b>{emp.user?.first_name + " " + emp.user?.last_name}</b>
                    </Button>
                  </div>
                </TableCell>
                <TableCell>{emp?.client?.name}</TableCell>

                <TableCell>{emp?.team && emp?.team[0]?.name}</TableCell>
                <TableCell>{emp?.position?.name}</TableCell>
                <TableCell>{emp?.seniority?.name}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      
    </Container>
  );
}
