import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Comments from "../components/Comments/Comments";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Container,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AvatarIcon } from "./Avatar";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { SelectChangeEvent } from "@mui/material/Select";

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

export default function TravelRequestDetail({ travelId }: any) {
  const [employeesApi, setEmployeesApi] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const statusOpstions = ["Approved", "Declined", "Reviewing"];
  const [beDataStatus, setBeDataStatus] = useState<any>([]);
  const [travelRequest, setTravelRequest] = useState<any>();
  const [employeeName, setEmployeeName] = useState<any>([]);
  const [travel, setTravel] = useState<any>();
  const [trId, setTrId] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>();
  const [newStateDoc, setNewStateDoc] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderDoc, setLoaderDoc] = useState(false);
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [selectCount, setSelectCount] = useState(0);
  const [empChange, setEmpChange] = useState([]);
  let currentUser = useSelector((state: any) => state.reducer.user);
  const param1 = useParams<{ idTravel: any }>();
  let travelRequestId = useSelector(
    (state: any) => state.reducer.travelRequestId
  );

  useEffect(() => {
    apiService.get(`${APP_URL}/users/profile/`).then((resp1) => {
      setAllEmployees(resp1);
      apiService.get(`${APP_URL}/users/travels-by-user/`).then((resp) => {
        let res;
        if (param1.idTravel) {
          res = resp.travels.filter(
            (item: any) => item.id === parseInt(param1.idTravel)
          );
        } else {
          res = resp.travels.filter((item: any) => item.id === travelRequestId);
        }
        setTravel(res[0]);
        setValue([res[0].start_date, res[0].end_date]);
        setTravelRequest(res[0].travel_requests[0]);
        let employeeNameeArr: any[] = [];
        let documents: any = [];
        let employeeList: any = [];
        let employeeApi: any = [];
        res[0].travel_requests.map((item: any) => {
          let employeeInfo = {
            trId: item.id,
            id: item.employee.id,
            first_name: item.employee.first_name,
            last_name: item.employee.last_name,
          };
          employeeList = [...employeeList, employeeInfo];
          if (employeeNameeArr.length === 0) {
            employeeNameeArr.push(
              item.employee.first_name + " " + item.employee.last_name
            );
          } else {
            employeeNameeArr.push(
              "  " + item.employee.first_name + " " + item.employee.last_name
            );
          }

          for (let i = 0; i < item.document.length; i++) {
            documents = [...documents, item.document[i]];
          }
          for (let i = 0; i < resp1.length; i++) {
            if (item.employee.id === resp1[i].user.id) {
              employeeApi = [...employeeApi, resp1[i]];
            }
          }
        });
        setEmployeeData(employeeList);
        setEmployeeName(employeeNameeArr);
        setDocuments(documents);
        setEmployeesApi(employeeApi);
        setLoader(false);
        setLoaderDoc(false);
      });
    });
  }, [newStateDoc]);

  const handleFileAdd = (event: any) => {
    const files = event.target.files;
    if (files && files[0].size < 67108864) {
      let newFormData = new FormData();
      newFormData.append("document", files[0]);
      newFormData.append("travel_request_id", trId);
      setLoaderDoc(true);
      apiService
        .post(`${APP_URL}/users/travel-invite/`, newFormData)
        .then(() => {
          setNewStateDoc(files[0]);
        });
    } else {
      Toast.fire({
        icon: "error",
        title: "File is too large! Max file size is 63MB!",
        background: "#f27474",
      });
    }
  };

  function getNameOfDocument(path: string) {
    var splitted = path.split("/");
    var return_string: string = splitted[splitted.length - 1];
    return return_string;
  }

  const downloadFile = (item: any) => {
    const docName = getNameOfDocument(`${item.document}`);
    apiService
      .get(`${APP_URL}/users/travel-invite/${item.id}/`)
      .then((resp) => {
        saveAs("data:image/png;base64," + resp.file, docName);
      });
  };

  const handleChangeSelect = (event: any) => {
    let tempArr = [];
    let tempName = [];
    for (let i = 0; i < event.target.value.length; i++) {
      tempArr.push(event.target.value[i].user.id);
      if (tempName.length === 0) {
        tempName.push(
          event.target.value[i].user.first_name +
            " " +
            event.target.value[i].user.last_name
        );
      } else {
        tempName.push(
          "  " +
            event.target.value[i].user.first_name +
            " " +
            event.target.value[i].user.last_name
        );
      }
    }
    setEmployeeName(tempName);
    setEmpChange(tempArr);
    const {
      target: { value },
    } = event;
    setEmployeesApi(value);
  };

  const handleChangeDate = (e: any) => {
    setTravel({
      ...travel,
      start_date: moment(e[0]).format("yyyy-MM-DD"),
      end_date: moment(e[1]).format("yyyy-MM-DD"),
    });
    setValue(e);
  };

  const changeStatus = (e: any, id: any) => {
    let status = e.target.value;
    let data: any;
    if (status === "Approved") {
      data = { id: id, approved: true };
    } else if (status === "Declined") {
      data = { id: id, approved: false };
    } else {
      data = { id: id, approved: null };
    }
    setBeDataStatus(data);
  };

  const handleStatusView = (item: any) => {
    switch (item.approved) {
      case true:
        return "Approved";
      case false:
        return "Declined";
      case null && item.progress_description === null:
        return "Reviewing";
      default:
        return "In progress";
    }
  };

  const handleSubmit = () => {
    let data = {
      id: travelRequestId,
      title: travel.title,
      additional_info: travel.additional_info,
      location: travel.location,
      reason: travel.reason,
      start_date: travel.start_date,
      end_date: travel.end_date,
      approved: beDataStatus.approved,
      employees: empChange,
    };
    apiService.patch(`${APP_URL}/users/travel/`, data).then((resp) => {
      Toast.fire({
        icon: "success",
        title: "Successfully sent!",
        background: "#a5dc86",
      });
      setDisabled(true);
    });
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <Box display="flex" flexDirection="column" mt={15}>
        <h1>Travel request form</h1>
        <Typography>Trip Title</Typography>
        <TextField
          sx={{ mt: 1 }}
          size="small"
          value={travel?.title}
          disabled={
            (!currentUser.is_super_hr && !currentUser.is_hr) || disabled
          }
          style={{ paddingBottom: "20px" }}
          onChange={(e) =>
            setTravel({
              ...travel,
              title: e.target.value,
            })
          }
        />
        <Typography>Select Employee</Typography>
        {disabled ? (
          <TextField
            sx={{ mt: 1 }}
            size="small"
            id="demo-helper-text-aligned"
            multiline
            disabled={disabled}
            value={employeeName}
            rows={3}
            required
          />
        ) : (
          <FormControl sx={{ mt: 1 }} style={{ paddingBottom: "20px" }}>
            <InputLabel id="demo-multiple-checkbox-label">Employee</InputLabel>
            <Select
              multiple
              defaultValue={employeesApi}
              labelId="engineer-label"
              id="engineer-select"
              label="Engineer"
              disabled={disabled}
              onChange={handleChangeSelect}
              renderValue={(selected) =>
                selected
                  .map((user: any, index: any) => {
                    if (index < 2) {
                      return user.user.first_name + " " + user.user.last_name;
                    }
                    setSelectCount(index - 1);
                    return null;
                  })
                  .filter(Boolean)
                  .join(", ") +
                (selected.length > 2 ? " +" + selectCount + " others" : "")
              }
            >
              {allEmployees?.map((user: any) => (
                <MenuItem
                  key={user.user.id}
                  value={user}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "rgba(16, 156, 241, 0.3)",
                      // color: "white"
                    },
                    "&:hover": {
                      color: "black",
                    },
                  }}
                  selected
                >
                  <AvatarIcon
                    src={"data:image/jpeg;base64," + user.avatar_64}
                    firstName={user.user.first_name}
                    lastName={user.user.last_name}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  {user.user.first_name} {user.user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {currentUser?.is_hr || currentUser.is_super_hr ? (
          <FormControl
            variant="outlined"
            color="primary"
            style={{ paddingBottom: "20px" }}
          >
            <Typography>Status</Typography>
            <Select
              native
              disabled={
                (!currentUser.is_super_hr && !currentUser.is_hr) || disabled
              }
              onChange={(e) => changeStatus(e, travel.id)}
              defaultValue={handleStatusView(travel)}
            >
              {statusOpstions.map((status) => (
                <option>{status}</option>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div></div>
        )}
        <Grid style={{ paddingBottom: "20px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Typography style={{ paddingBottom: "15px" }}>Date</Typography>
            <DateRangePicker
              startText="Check-in"
              endText="Check-out"
              disabled={
                (!currentUser.is_super_hr && !currentUser.is_hr) || disabled
              }
              value={value}
              onChange={(e: any) => handleChangeDate(e)}
              renderInput={(startProps: any, endProps: any) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ paddingRight: "50px !important" }}>
                    <TextField
                      value={travel ? travel.end_date : null}
                      {...startProps}
                    />
                  </div>
                  <TextField
                    style={{ marginLeft: "100px" }}
                    value={travel ? travel.start_date : null}
                    {...endProps}
                  />
                </div>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Typography>Location</Typography>
        <TextField
          sx={{ mt: 1 }}
          size="small"
          disabled={
            (!currentUser.is_super_hr && !currentUser.is_hr) || disabled
          }
          value={travel?.location}
          style={{ paddingBottom: "20px" }}
          onChange={(e) =>
            setTravel({
              ...travel,
              location: e.target.value,
            })
          }
        />
        <Typography>Reason</Typography>
        <TextField
          sx={{ mt: 1 }}
          size="small"
          value={travel?.reason}
          disabled={
            (!currentUser.is_super_hr && !currentUser.is_hr) || disabled
          }
          style={{ paddingBottom: "20px" }}
          onChange={(e) =>
            setTravel({
              ...travel,
              reason: e.target.value,
            })
          }
        />
        <Typography>Add info</Typography>
        <TextField
          sx={{ mt: 1 }}
          size="small"
          multiline
          rows={3}
          disabled={
            (!currentUser.is_super_hr && !currentUser.is_hr) || disabled
          }
          value={travel?.additional_info}
          style={{ paddingBottom: "20px" }}
          onChange={(e) =>
            setTravel({
              ...travel,
              additional_info: e.target.value,
            })
          }
        />
        {currentUser.is_super_hr || currentUser.is_hr ? (
          <Grid
            style={{
              justifyContent: "space-around",
              display: "flex",
              paddingBottom: "40px",
              paddingTop: "20px",
            }}
          >
            <Button
              style={{ width: "30%" }}
              variant="contained"
              onClick={() => setDisabled(!disabled)}
            >
              {disabled ? <div>Edit</div> : <div>Cancel</div>}
            </Button>
            <Button
              style={{ width: "30%" }}
              variant="contained"
              onClick={handleSubmit}
              disabled={disabled}
            >
              Save
            </Button>
          </Grid>
        ) : (
          <div></div>
        )}

        <Grid>
          {documents.length > 0 && <h2>Documents</h2>}
          {loaderDoc ? (
            <CircularProgress style={{ marginTop: "200px" }} />
          ) : (
            documents.map((item) => (
              <div>
                <Button onClick={() => downloadFile(item)}>
                  {getNameOfDocument(item.document)}
                </Button>
              </div>
            ))
          )}
        </Grid>

        <FormControl style={{ paddingTop: "20px" }}>
          <h2>Select user for upload</h2>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => setTrId(e.target.value)}
          >
            {employeeData?.length > 0 &&
              employeeData?.map((user: any) => (
                <MenuItem value={user.trId}>
                  {user.first_name} {user.last_name}
                </MenuItem>
              ))}
          </Select>
          <Button component="label" disabled={!trId}>
            <p>Select file</p>
            <input
              type="file"
              hidden
              onChange={(event) => handleFileAdd(event)}
            />
          </Button>
        </FormControl>

        <Comments trId={travel?.id} handleFileAdd={handleFileAdd} />
      </Box>
    </Container>
  );
}
