import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Container,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import { Link, useParams } from "react-router-dom";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EngineerAddModal from "./SelectWatcherModal";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { AvatarIcon } from "../Avatar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PositionCreateModal from "../CreatePositionModal";
import CandidateColumn from "./CandidateColumn";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex - 1, 1);
  console.log(removed);
  result.splice(endIndex - 1, 0, removed);

  result.map((col: any) => (col.displayIndex = result.indexOf(col) + 1));

  return result;
};

function CandidateDnD() {
  const [profiles, setProfiles] = useState([]);
  const [columns, setColumns] = useState([]);
  const [position, setPosition] = useState<any>();
  const [show, setShow] = useState(false);
  const [colGroupId, setColGroupId] = useState();
  const params = useParams<{ id: string }>();
  const [popoverAnchor, setPopoverAnchor] = useState<any>(null);
  const [currentWatcher, setCurrentWatcher] = useState<any>(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [showAddColumnBtn, setShowAddColumnBtn] = useState(true);
  const [showAddColumnInput, setShowAddColumnInput] = useState(false);
  const [columnName, setColumnName] = useState("");

  const onDragEnd = (result: any, columns: any) => {
    let colAnswer: any = { columns: [] };
    if (!result.destination) return;
    const { source, destination } = result;

    if (result.type === "droppableColumn") {
      const orderedColumns = reorder(
        columns,
        result.source.index,
        result.destination.index
      );

      setColumns(orderedColumns);
      apiService.patch(`${APP_URL}/job/candidates/group-patch/`, {
        id: colGroupId,
        columns: orderedColumns,
      });
    } else if (result.type === "droppableCandidate") {
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns.find(
          (col: any) =>
            col.displayIndex.toString() === source.droppableId.toString()
        );
        const destColumn = columns.find(
          (col: any) =>
            col.displayIndex.toString() === destination.droppableId.toString()
        );
        const sourceItems = [...sourceColumn.candidates];

        const destItems = [...destColumn.candidates];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        columns?.map((col: any) => {
          if (col.id === sourceColumn.id) {
            col.candidates = sourceItems;
          } else if (col.id === destColumn.id) {
            col.candidates = destItems;
          }
          for (let candidate of col.candidates) {
            if (
              candidate.job_application_progress.id !==
              col.job_application_phase.id
            ) {
              let patchCandidate = { id: "", job_application_progress: "" };
              patchCandidate["id"] = candidate["id"];
              patchCandidate["job_application_progress"] =
                col.job_application_phase.id;
              apiService.patch(`${APP_URL}/job/candidates/`, patchCandidate);
            }
          }
          colAnswer["columns"].push(col);
        });
      } else {
        const column = columns?.find(
          (col: any) => col.displayIndex.toString() === source.droppableId
        );
        const copiedItems = [...column.candidates];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        column.candidates = copiedItems;
        colAnswer["columns"].push(column);
      }
    }

    apiService.patch(`${APP_URL}/job/candidates/column-patch/`, colAnswer);
  };

  const getColumnsData = () => {
    apiService
      .get(`${APP_URL}/job/candidates/group-by-position/${params.id}/`)
      .then((data) => {
        setColumns(data.columns);
        setColGroupId(data.id);
      });
  };
  useEffect(() => {
    apiService
      .get(`${APP_URL}/users/profile/`)
      .then((data) => setProfiles(data));
    apiService
      .get(`${APP_URL}/job/alljobs/${params.id}/`)
      .then((data) => setPosition(data));
    getColumnsData();
  }, []);

  function deletePosition() {
    apiService
      .deleteItem(`${APP_URL}/job/alljobs/ `, { id: params.id })
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Succesfully deleted!",
          background: "#a5dc86",
        });
        handleClose();
      })
      .then(() => {
        setTimeout(() => {
          navigate("/main/recruitment-landing");
        }, 2000);
      });
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function showModal() {
    setShow(true);
  }
  function handleShow() {
    setShowAddColumnBtn(false);
    setShowAddColumnInput(true);
  }
  function handleColumnName(e: any) {
    setColumnName(e.target.value);
  }
  function removeWatcher() {
    const watcherIds = position.watchers
      .map((watcher: any) => watcher.user.id)
      .filter((watcherId: any) => watcherId !== currentWatcher.user.id);
    const patchPos = position;
    patchPos.watchers = watcherIds;
    apiService
      .patch(`${APP_URL}/job/alljobs/`, patchPos)
      .then(() =>
        apiService
          .get(`${APP_URL}/job/jobposition/${params.id}/`)
          .then((data) => setPosition(data))
      );
    setCurrentWatcher(null);
    setPopoverAnchor(null);
  }

  const addColumn = () => {
    const data = {
      id: parseInt(params.id),
      name: columnName,
    };
    if (columnName !== "") {
      apiService
        .post(`${APP_URL}/job/newphase/`, data)
        .then(() => getColumnsData());
    }
    setColumnName("");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        padding: "10px",
      }}
    >
      <div onClick={showModal} style={{ cursor: "pointer", marginTop: 80 }}>
        <Tooltip title="Add new watcher">
          <Avatar style={{ float: "right", marginLeft: 10 }}>
            <AddIcon />
          </Avatar>
        </Tooltip>
      </div>
      <Badge
        color="primary"
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        badgeContent={"HR"}
        style={{ float: "right", marginLeft: 20 }}
      >
        <AvatarGroup max={4}>
          {profiles
            ?.filter((profile) => profile.is_hr || profile.is_super_hr)
            .map((profile: any) => {
              return (
                <Tooltip
                  title={profile.user.first_name + " " + profile.user.last_name}
                >
                  <AvatarIcon
                    alt=""
                    src={"data:image/jpeg;base64," + profile.avatar_64}
                    firstName={profile.user.first_name}
                    lastName={profile.user.last_name}
                  ></AvatarIcon>
                </Tooltip>
              );
            })}
        </AvatarGroup>
      </Badge>
      <Badge
        invisible={position?.watchers.length === 0}
        color="secondary"
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        badgeContent={<VisibilityIcon style={{ fontSize: "17px" }} />}
        style={{ float: "right" }}
      >
        <AvatarGroup>
          {position?.watchers?.map((watcher: any) => {
            return (
              <IconButton
                edge="start"
                style={{ padding: 0 }}
                onClick={(e: any) => {
                  setPopoverAnchor(e.target);
                  setCurrentWatcher(watcher);
                }}
              >
                <AvatarIcon
                  src={"data:image/jpeg;base64," + watcher.avatar_64}
                  firstName={watcher?.user?.first_name}
                  lastName={watcher?.user?.last_name}
                />
              </IconButton>
            );
          })}
        </AvatarGroup>
      </Badge>
      <Typography variant="h3" style={{ paddingTop: 10 }}>
        {position?.name}
      </Typography>
      <EngineerAddModal
        onClose={() => {
          setShow(false);
          apiService
            .get(`${APP_URL}/job/alljobs/${params.id}/`)
            .then((data) => setPosition(data));
        }}
        show={show}
        position={position}
        profiles={profiles}
      />
      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(popoverAnchor)}
        onClose={() => {
          setPopoverAnchor(null);
        }}
      >
        <div
          style={{
            minHeight: "10vh",
            minWidth: "10vw",
            background: "linear-gradient(180deg, blue 50%, white 50%)",
            display: "flex",
            alignItems: "stretch",
            padding: 10,
            flexDirection: "row-reverse",
          }}
        >
          <IconButton
            onClick={() => {
              setPopoverAnchor(null);
            }}
            style={{ padding: 0, margin: 0, alignSelf: "flex-start" }}
          >
            <CloseIcon style={{ fontSize: "18px", color: "white" }} />
          </IconButton>
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <Typography
              paragraph
              color="white"
              style={{
                paddingRight: 10,
                paddingBottom: 10,
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {currentWatcher?.user.first_name} {currentWatcher?.user.last_name}
            </Typography>
          </div>
          <Avatar
            src={"data:image/jpeg;base64," + currentWatcher?.avatar_64}
            style={{
              marginTop: 15,
              marginRight: 10,
              border: "2px solid white",
            }}
            sx={{ width: 76, height: 76 }}
          ></Avatar>
        </div>
        <div style={{ padding: 7 }}>
          <Link
            to={`/employee/${currentWatcher?.user.id}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              fullWidth
              variant="text"
              style={{
                display: "block",
                margin: 0,
                paddingLeft: 0,
                color: "black",
                textTransform: "none",
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              See profile
            </Button>
          </Link>
          <div
            style={{ borderBottom: "1px solid", borderColor: "#091e4221" }}
          ></div>
          <Button
            fullWidth
            variant="text"
            style={{
              display: "block",
              margin: 0,
              paddingLeft: 0,
              color: "black",
              textTransform: "none",
              fontWeight: 400,
              textAlign: "left",
            }}
            onClick={removeWatcher}
          >
            Remove from board
          </Button>
        </div>
      </Popover>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          aria-label="delete"
          color="success"
          style={{ display: "flex" }}
        >
          <EditIcon
            onClick={() => {
              setShowPositionModal(true);
            }}
          />
        </IconButton>
        {showPositionModal && (
          <PositionCreateModal
            deletePosition={deletePosition}
            position={position}
            onClose={() => {
              setShowPositionModal(false);
            }}
          />
        )}
        <IconButton
          aria-label="delete"
          color="error"
          style={{ display: "flex" }}
          onClick={handleClickOpen}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{"DELETE POSITION"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              Do you really want to delete this position?{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={deletePosition} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns)}>
        <Droppable
          droppableId="columns"
          type="droppableColumn"
          direction="horizontal"
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "5vh",
                width: "100%",
              }}
            >
              {columns?.map((column: any) => {
                return <CandidateColumn column={column} />;
              })}
              {provided.placeholder}
              {showAddColumnBtn && (
                <Button
                  onClick={handleShow}
                  variant="contained"
                  startIcon={<AddIcon />}
                  style={{
                    background: "rgba(0,0,0,0.1)",
                    minWidth: "230px",
                    color: "black",
                    height: "50px",
                    borderRadius: "5px",
                    display: "flex",
                    padding: "10px",
                  }}
                >
                  Add column
                </Button>
              )}

              {showAddColumnInput && (
                <Box
                  style={{
                    background: "rgba(0,0,0,0.1)",
                    width: "230px",
                    height: "110px",
                    padding: "5px",
                  }}
                >
                  <TextField
                    value={columnName}
                    onChange={handleColumnName}
                    id="outlined-basic"
                    label="Add Column"
                    variant="outlined"
                    sx={{ display: "flex" }}
                  />
                  <Button
                    onClick={addColumn}
                    variant="contained"
                    style={{
                      width: "80px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      float: "left",
                      color: "white",
                      height: "30px",
                    }}
                  >
                    ADD
                  </Button>
                  <Button
                    onClick={() => {
                      setShowAddColumnInput(false);
                      setShowAddColumnBtn(true);
                    }}
                    variant="outlined"
                    style={{ marginTop: "5px", float: "right", height: "30px" }}
                  >
                    X
                  </Button>
                </Box>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

export default CandidateDnD;
