import React, { useEffect, useRef, useState } from "react";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import Comment from "./Comment";
import Cookies from "js-cookie";
import SendIcon from "../../assets/img/SendIcon.svg";
import { Button, Paper, TextField } from "@mui/material";
import { decodeToken } from "react-jwt";

var jwt = require("react-jwt");

export default function Comments(data: any) {
  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;
  const [beComments, setBeComments] = useState([]);
  const [newComment, setNewComment] = useState([]);
  const [newState, setNewState] = useState([]);
  const bottomRef = useRef(null);


  useEffect(() => {
    data.trId &&
    apiService
      .get(`${APP_URL}/users/travel/${data.trId}/comments/`)
      .then((resp) => {
        setBeComments(resp);
      });
  }, [newState, data.trId]);

  useEffect(() => {
    if (beComments.length > 0)
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [beComments]);

  const handleComment = (e: any) => {
    setNewComment(e.target.value);
  };

  const sendComment = () => {
    apiService
      .post(`${APP_URL}/users/travel/comments/`, {
        body: newComment,
        user: userId,
        travel: data.trId,
      })
      .then((resp) => {
        setNewState(newComment);
        setNewComment([]);
      });


  };

  return (
    <div style={{ paddingBottom: "100px" }}>
      <h3>Comment box</h3>
      <Paper style={{ maxHeight: "600px", overflow: "auto" }}>
        {beComments.map((item, index) => (
          <Comment comment={item} userId={userId} key={index} />
        ))}
        <div ref={bottomRef} />
      </Paper>
      <Paper>
        <TextField
          variant="outlined"
          placeholder="Type something..."
          sx={{ mt: 1 }}
          size="small"
          onChange={(e) => handleComment(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendComment();
            }
          }}
          value={newComment}
          style={{ paddingBottom: "20px", width: "380px", marginLeft: "15px" }}
        />

        <Button onClick={sendComment}>
          <img src={SendIcon} style={{ padding: "10px" }} />
        </Button>
      </Paper>

    </div>
  );
}
