import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { createUser } from "../services/service";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { APP_URL } from "../utils/constants";
import Cookies from "js-cookie";
import Copyright from "../components/CopyrightFunction";
import apiService from "../services/apiService";

import { newNavbar, userAuthenticate } from "../store/actions";
import { useDispatch } from "react-redux";
import axios from "axios";

import Swal from "sweetalert2";
import { decodeToken } from "react-jwt";

const LoginG: React.FC = () => {
  const googleOauth = () => {
    window.location.href = `${APP_URL}/login/accounts/google/login/`;
  };
  return (
    <div>
      <div>
        <Button
          onClick={googleOauth}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
        >
          Login with Google
        </Button>
      </div>
    </div>
  );
};

interface Props {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  openSnackError: string;
  setOpenSnackError: React.Dispatch<React.SetStateAction<string>>;
  forgotPassModal: boolean;
  setForgotPassModal: React.Dispatch<React.SetStateAction<boolean>>;
  passEmail: string;
  setPassEmail: React.Dispatch<React.SetStateAction<string>>;
  token: any;
  successMessage: any;
  setSuccessMessage: React.Dispatch<React.SetStateAction<any>>;
  postUser: () => void;
}

const SignInSide: React.FC<Props> = ({
  email,
  setEmail,
  password,
  setPassword,
  postUser,
  openSnackError,
  setOpenSnackError,
  forgotPassModal,
  setForgotPassModal,
  passEmail,
  setPassEmail,
  successMessage,
  setSuccessMessage,
}) => {
  const theme = createTheme();
  const [isVisible, setIsVisible] = useState(false);
  var sectionStyle = {
    width: "100%",
    height: "150px",
    backgroundImage: `url(/static/images/QCERRIS.svg)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const sendEmail = () => {
    axios
      .post(`${APP_URL}/auth/reset-password/`, {
        email: passEmail,
      })
      .then(() => {
        setForgotPassModal(false);
        setSuccessMessage(
          "If you entered correct email you will recive new password set up"
        );
      });
  };

  const handlePasswordReset = async () => {
    
    await Swal.fire({
      title: "Reset Password",
      input: "email",
      inputPlaceholder: "Enter your email address",
      confirmButtonText: "SEND",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${APP_URL}/auth/reset-password/`, {
            email: result.value,
          })
          .then((resp) => {
            if (resp.data.message === `failed`)
              Swal.fire(resp.data.reason, "", "error");
            else Swal.fire("Email sent!", "", "success");
          });
      }
    });
  };
  const togglePasswordVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <section style={sectionStyle}></section>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  postUser();
                }
              }}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={isVisible?("text"):("password")}
              id="password"
              value={password}
              sx={{backgroundColor:'white'}}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  postUser();
                }
              }}
              InputProps={{
                endAdornment: (
                  <Button  onClick={togglePasswordVisibility}>
                    {isVisible?(<VisibilityIcon/>):(<VisibilityOffIcon/>)}
                    
                  </Button>
                ),
              }}
            />
            <br />
            <br />

            <Button
              onClick={postUser}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <br />
            <Grid container>
              <Grid item xs>
                <Link onClick={handlePasswordReset} variant="body2" style={{cursor: 'pointer'}}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog
          open={forgotPassModal}
          onClose={() => setForgotPassModal(false)}
          style={{ alignItems: "center" }}
        >
          <DialogTitle>Please enter your email</DialogTitle>
          <DialogContent>
            <TextField
              label="Email"
              sx={{ mt: 1 }}
              size="small"
              onChange={(e) => setPassEmail(e.target.value)}
            />
            <div>
              <Button
                style={{ marginLeft: "70%", padding: "20px" }}
                onClick={sendEmail}
              >
                Send
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [passEmail, setPassEmail] = useState<string>("");
  const [forgotPassModal, setForgotPassModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [password, setPassword] = useState<string>("");
  const [openSnackError, setOpenSnackError] = useState("");
  const [token, setToken] = useCookies<any>(["tokens"]);
  const [isVisible, setIsVisible] = useState(false);

  let navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-left',
    width: 430,
    color: 'white',
    iconColor: 'white',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  })

  
  

  const postUser = async () => {
    await createUser(email, password).then((resp) => {
      
      if (resp.message === "success") {
        setToken("tokens", resp);
        var jwt = require("react-jwt");
        Cookies.set("myCookie", resp.jwt, { path: "/" });
        // let userId = jwt.decode(resp.jwt).user_id;
        let userId = (decodeToken(resp.jwt) as any).user_id
        apiService
          .get(`${APP_URL}/users/profile/${userId}/`)
          .then((response) => {
            dispatch(userAuthenticate(response));
            dispatch(newNavbar("new"));
            navigate(
              response.is_client ? "/main/client/overview" : "/main/overview"
            );
          });
      } else {
        if (resp.message === "failed") {
          Toast.fire({
            icon: 'error',
            title: resp.reason,
            background: '#f27474'
          })
        }
       navigate("/login");
      }
    })
  };

  return (
    <div>
      <SignInSide
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        postUser={postUser}
        openSnackError={openSnackError}
        setOpenSnackError={setOpenSnackError}
        forgotPassModal={forgotPassModal}
        setForgotPassModal={setForgotPassModal}
        passEmail={passEmail}
        setPassEmail={setPassEmail}
        token={token}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </div>
  );
};
