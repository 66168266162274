import React, { useEffect } from "react";
import { FormControl, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { IMultiFilter, ISelectionBox } from "../OurTeam";

interface IProps {
  selectionBox: ISelectionBox;
  multiFilter: IMultiFilter;
  setMultiFilter: React.Dispatch<React.SetStateAction<IMultiFilter>>;
  handleMultiFilter: () => void;
}

const FilterSelectionBox = ({ selectionBox, multiFilter, setMultiFilter, handleMultiFilter }: IProps) => {
  useEffect(() => {
    handleMultiFilter();
  }, [multiFilter]);

  return (
    <div>
      <FormControl sx={{ mr: 3, minWidth: 180, mt: 3, mb: 5 }}>
        <Select
          disabled={selectionBox.type == "seniority"}
          sx={{
            backgroundColor: selectionBox.selectionBoxTextStatePair[0].length === 0 ? "white" : "#86D1FF",
            borderRadius: 5,
          }}
          displayEmpty
          value={selectionBox.selectionBoxTextStatePair[0]}
          onChange={(e) => {
            setMultiFilter({
              ...multiFilter,
              [selectionBox.type]: e.target.value,
            });
            selectionBox.selectionBoxTextStatePair[1](e.target.value);
          }}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return (
              <Typography
                color={selected.length === 0 ? "text.secondary" : "#224AA0"}
                align="left"
                fontWeight={selected.length === 0 ? 200 : 600}
                fontFamily="Noto Sans"
              >
                {selected.length === 0 ? selectionBox.type[0].toUpperCase() + selectionBox.type.slice(1) : selected}
              </Typography>
            );
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
              },
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="">
            <Typography color="text.secondary">
              {selectionBox.type[0].toUpperCase() + selectionBox.type.slice(1)}
            </Typography>
          </MenuItem>
          {selectionBox.selectionChoicesStatePair[0].map((selectionChoice) => (
            <MenuItem key={selectionChoice} value={selectionChoice}>
              {selectionChoice}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterSelectionBox;
