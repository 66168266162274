import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Button, Container, IconButton, Stack } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import GeneralInfo from "../components/GeneralInfo";
import { FileListFunction } from "../components/UploadDocuments";
import apiService from "../services/apiService";
import _pick from "lodash/pick";
import Cookies from "js-cookie";
import { APP_URL } from "../utils/constants";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "./Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { AvatarIcon } from "../components/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import PerformanceReviewHistoryList from "../components/PerformanceReviewHistoryList";
import TravelRequestHistory from "../components/TravelRequestHistory";
import { travelRequestId } from "../store/actions";
import { ChangeAvatar } from "../components/AvatarFunction";
import Swal from "sweetalert2";
import { decodeToken } from "react-jwt";
import PerformanceReviewHistoryListEmployeeView from "../components/PerformanceReviewHistoryListEmployeeView";

const Input = styled("input")({
  display: "none",
});

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const classes = {
  picture: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "0.5rem",
  },
  upload: {
    color: blue[500],
  },
};

const Employee = () => {
  const params = useParams<{ profileId: string }>();
  const [formData, setFormData] = useState<any>();
  const [positions, setPositions] = useState<AxiosResponse>();
  const [senioritis, setSenioritis] = useState<AxiosResponse>();
  const [clients, setClients] = useState<AxiosResponse>();
  const [teams, setTeams] = useState<AxiosResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);
  const [icon, setIcon] = useState<any>();
  const [user, setUser] = useState<any>();
  const [iconView, setIconView] = useState<any>();
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState(true);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);

  let currentUser = useSelector((state: any) => state.reducer.user);

  var jwt = require("react-jwt");
  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;

  const Toast = Swal.mixin({
    toast: true,
    width: 450,
    color: "white",
    iconColor: "white",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  const handleCloseSnack = (
    err: string,
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    err === "success" ? setOpenSnackSuccess(false) : setOpenSnackError(false);
  };

  const handleTravelId = (id: any) => {
    //setTravelId(id);
    dispatch(travelRequestId(id));
    navigate(`/travel-request-detail`);
  };

  useEffect(() => {
    apiService
      .get(`${APP_URL}/users/profile/${params.profileId}/`)
      .then((resp) => {
        const initData = _pick(resp, Object.keys(resp));
        setFormData(initData);
        setLoader(false);
      });
    setUser(currentUser);
    apiService.get(`${APP_URL}/users/position/`).then((resp) => {
      setPositions(resp);
    });
    apiService.get(`${APP_URL}/users/seniority/`).then((resp) => {
      setSenioritis(resp);
    });
    currentUser.is_super_hr &&
      apiService.get(`${APP_URL}/users/client/`).then((resp) => {
        setClients(resp);
      });
    apiService.get(`${APP_URL}/users/teams/`).then((resp) => {
      setTeams(resp);
      setIsLoading(false);
    });
  }, [params.profileId]);

  useEffect(() => {
    if (formData?.avatar_64) {
      setLoader(true);
    }
    if (formData) {
      setIcon("data:image/jpeg;base64," + formData?.avatar_64);
      setIconView("data:image/jpeg;base64," + formData?.avatar_64);
      setLoader(false);
    }
  }, [formData?.avatar_64]);

  const addImage = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0].size < 67108864) {
      //for send
      setIcon(e.target.files[0]);
      setUploadDisabled(false);
      //for view
      setIconView(URL.createObjectURL(e.target.files[0]));
    } else {
      setOpenSnackError(true);
    }
  };

  const handleUploadPicture = () => {
    const data = new FormData();
    data.append("id", formData.user.id);
    data.append("avatar", icon);

    apiService
      .patch(`${APP_URL}/users/profile/`, data, "multipart/form-data")
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Profile picture uploaded successfully!",
          width: 480,
          background: "#a5dc86",
        });
        setUploadDisabled(true);
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: "Please upload a valid image file!",
          background: "#f27474",
        });
        setUploadDisabled(true);
      });

    if (parseInt(params.profileId) === currentUser.id) {
      currentUser.avatar_64 = iconView;
      dispatch({
        type: "USER_AUTHENTICATE_SUCCESS",
        payload: currentUser,
      });
      ChangeAvatar(iconView);
    }
  };
  {
    /* <swal-footer>...</swal-footer> */
  }
  const resetPasswordForOthers = async () => {
    await Swal.fire({
      title: "Reset Password",
      text: `Are you sure you want to reset password for ${formData.user.first_name} ${formData.user.last_name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        apiService
          .post(`${APP_URL}/auth/reset-password/`, {
            email: formData.user.email,
          })
          .then(() =>
            Swal.fire(`Email sent to ${formData.user.email}!`, "", "success")
          );
      }
    });
  };

  const resetOwnPassword = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Change password",
      html:
        '<input id="swal-input1" class="swal2-input" type="password" placeholder="Old password">' +
        '<input id="swal-input2" class="swal2-input" type="password" placeholder="New password">' +
        '<input id="swal-input3" class="swal2-input" type="password" placeholder="Confirm password">',
      focusConfirm: false,
      didOpen: () => {
        const oldPassInp = document.getElementById(
          "swal-input1"
        ) as HTMLInputElement;
        const newPassInp = document.getElementById(
          "swal-input2"
        ) as HTMLInputElement;
        const confirmPassInp = document.getElementById(
          "swal-input3"
        ) as HTMLInputElement;
        const inputs = [oldPassInp, newPassInp, confirmPassInp];
        inputs.forEach((input) =>
          input.addEventListener("keyup", (e) => {
            if (e.key === "Enter") {
              Swal.clickConfirm();
            }
          })
        );
      },
      preConfirm: () => {
        const oldPassInp = document.getElementById(
          "swal-input1"
        ) as HTMLInputElement;
        const newPassInp = document.getElementById(
          "swal-input2"
        ) as HTMLInputElement;
        const confirmPassInp = document.getElementById(
          "swal-input3"
        ) as HTMLInputElement;
        return [oldPassInp.value, newPassInp.value, confirmPassInp.value];
      },
    });

    if (formValues) {
      const oldPassword = formValues[0];
      const newPassword = formValues[1];
      const confirmPassword = formValues[2];
      // Swal.fire("Error", "Old password is incorrect", "error");
      if (newPassword !== confirmPassword) {
        Swal.fire(
          "Error",
          "New password and confirm password don't match",
          "error"
        );
      } else {
        apiService
          .patch(`${APP_URL}/users/setpassword/`, {
            email: formData.user.email,
            old_password: oldPassword,
            password: newPassword,
          })
          .then(() => Swal.fire("Password successfuly changed!", "", "success"))
          .catch((e) => {
            const errMsg: string = e.response.data.message;
            if (errMsg === "No password provided!") {
              Swal.fire("Error", errMsg, "error");
            } else if (errMsg === "Incorrect password!") {
              Swal.fire(
                "Error",
                "You entered an incorrect previous password!",
                "error"
              );
            } else {
              Swal.fire("Error", "Unknown error!", "error");
            }
          });
      }
    }
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <>
      <Dashboard />
      <Container maxWidth="lg">
        <Box mt={10} mb={2}>
          <Box sx={classes.picture}>
            <AvatarIcon
              src={iconView}
              firstName={formData?.user.first_name}
              lastName={formData?.user.last_name}
              style={{
                fontSize: "50px !important",
                width: 150,
                height: 150,
              }}
            />
            {(user.is_super_hr || formData?.user?.id === userId) && (
              <Stack>
                <label htmlFor="image-file">
                  <Input
                    accept="image/*"
                    id="image-file"
                    type="file"
                    name="image-file"
                    onChange={(e) => addImage(e)}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Stack>
            )}
            {!uploadDisabled && (
              <Button
                style={classes.upload}
                variant="outlined"
                onClick={handleUploadPicture}
              >
                Upload image
              </Button>
            )}
            {formData?.user?.id === userId ? (
              <Button onClick={resetOwnPassword}>CHANGE PASSWORD</Button>
            ) : user.is_super_hr ? (
              <Button onClick={resetPasswordForOthers}>RESET PASSWORD</Button>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <TabsUnstyled defaultValue={0}>
          <TabsList>
            <Tab>General info</Tab>
            {user?.is_client ||
            (!user?.is_super_hr && formData?.user?.id !== userId) ? null : (
              <Tab>Documents</Tab>
            )}
            {user?.is_client ||
            (!user?.is_super_hr && formData?.user?.id !== userId) ? null : (
              <Tab
                onClick={() => {
                  setShow(false);
                }}
              >
                Performance Reviews
              </Tab>
            )}
            {user?.is_client ||
            (!user?.is_super_hr && formData?.user?.id !== userId) ? null : (
              <Tab>Travel Request History</Tab>
            )}
          </TabsList>
          <TabPanel value={0}>
            <GeneralInfo
              formData={formData}
              setFormData={setFormData}
              id={params.profileId}
              positions={positions}
              senioritis={senioritis}
              clients={clients}
              teams={teams}
              isLoading={isLoading}
            />
          </TabPanel>
          {!user?.is_super_hr && formData?.user?.id !== userId ? null : (
            <TabPanel value={1}>
              <FileListFunction />
            </TabPanel>
          )}
          {!user?.is_super_hr && formData?.user?.id !== userId ? null : (
            <TabPanel value={2}>
              <PerformanceReviewHistoryListEmployeeView
                employee={formData?.user?.id}
                show={show}
                setShow={setShow}
              />
            </TabPanel>
          )}
          {!user?.is_super_hr && formData?.user?.id !== userId ? null : (
            <TabPanel value={3} sx={{ mt: -13 }}>
              <TravelRequestHistory
                employeeId={params.profileId}
                handleTravelId={handleTravelId}
              />
            </TabPanel>
          )}
        </TabsUnstyled>
      </Container>
    </>
  );
};

export default Employee;
