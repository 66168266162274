// APP TEXT
export const APP_TITLE = "Welcome, Developer";
export const FOOTER_TEXT = `${new Date().getFullYear()} Built with ♡ by Welcome, Developer`;
// PAGES TITLE
export const PAGE_TITLE_HOME = "Home";
export const PAGE_TITLE_DASHBOARD = "Dashboard";
export const PAGE_TITLE_GH_PRIVATE = "GitHub Private";
export const PAGE_TITLE_GH_PUBLIC = "GitHub Public";
export const PAGE_TITLE_CODE = "Code Editor";
export const PAGE_TITLE_SETTINGS = "Settings";
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
// APP URL

export const APP_URL = "https://hr-be.qcerris.net";
//export const APP_URL = process.env.REACT_APP_APP_URL
//  ? process.env.REACT_APP_APP_URL
//  : "http://127.0.0.1:8000";

export function stringAvatar(name: string) {
  if (name.trim().length === 0) return { children: "" };
  try {
    const [firstName, lastName] = name.split(" ");
    return {
      children: `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`,
    };
  } catch (e: any) {
    console.error(e);
    return { children: "Undefined" };
  }
}
