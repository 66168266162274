import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import Cookies from "universal-cookie";
import { useNavigate} from "react-router-dom";
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService";
import { useSelector } from "react-redux";
import { AvatarIcon } from "../components/Avatar";
import { decodeToken } from "react-jwt";

const theme = createTheme();

const UpdateInfoPage: React.FC = () => {
  const [user, setUser] = useState<any>("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  const cookies = new Cookies();
  var jwt = require("react-jwt");

  let cookie = cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;
  let currentUser = useSelector((state: any) => state.reducer.user);

  useEffect(() => {
    setUser(currentUser);
    setFirstName(currentUser.user.first_name);
    setLastName(currentUser.user.last_name);
  }, []);

  const updateUserInfo = () => {
    apiService.patch(`${APP_URL}/users/`, {
      id: userId,
      first_name: firstName,
      last_name: lastName,
    });

    navigate("/clientPage");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Paper elevation={0}>
              <CardMedia
                component="img"
                height="200"
                image="/static/images/QCERRIS.svg"
                alt="green iguana"
              />
            </Paper>
          </Box>
          <Typography variant="h5" mb={5}>
            Update your info.
          </Typography>

          <AvatarIcon
            firstName={firstName}
            lastName={lastName}
            style={{
              width: 150,
              height: 150,
              marginTop: -3,
              marginBottom: 2,
            }}
          />
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            size="medium"
            id="demo-helper-text-aligned"
            label="First Name"
            name="first_name"
            variant="outlined"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            size="medium"
            id="demo-helper-text-aligned"
            label="Last name"
            name="last_name"
            variant="outlined"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <Button
            sx={{ mt: 3, mb: 5, p: 1.5 }}
            fullWidth
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            onClick={updateUserInfo}
          >
            Update info
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default UpdateInfoPage;
