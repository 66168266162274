import { Box, Button, Container, Link, Typography } from "@mui/material";
import StructureImage from "../assets/img/Structure.png"

export const Structure = () => {
    return (
        <>
        <Box component="img"
        sx={{
            mt:5,
            ml:20,
          height: "190vh",
          width: "50vw",
        }}
        alt="Structure."
        src={StructureImage}></Box>
    </>
    );
}