import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AvatarFunc from './AvatarFunction';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { APP_URL } from '../utils/constants';
import Notifications from './NotificationBelt';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { ToggleDrawer } from './Sidebar';
import { newNavbar, userAuthenticate } from '../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../store/actions';
import { Link, useLocation } from 'react-router-dom';
import { decodeToken } from 'react-jwt';

var jwt = require('react-jwt');

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed',
  background: '#ffffff',
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function Navbar() {
  const [user, setUser] = useState<any>();
  const location = useLocation()
  
  if (
    !location.pathname.startsWith('/performance-review-request-detail/') &&
    !location.pathname.startsWith('/main/performance-review-list')
  ) {
    const defaultPagination = { page: 1, page_size: 10, search: '' };
    localStorage.setItem('info', JSON.stringify(defaultPagination));
  }

  const handleDrawerOpen = () => {
    ToggleDrawer();
  };
  let cookie = Cookies.get('myCookie');
  let userId: any;

  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }
  let navbar = {};
  navbar = useSelector((state: any) => state);
  let currentUser = useSelector((state: any) => state.reducer.user);

  const dispatch = useDispatch();

  if (cookie !== undefined && currentUser === undefined) {
    // let userId = jwt.decode(cookie).user_id;
    
    apiService
      .get(`${APP_URL}/users/profile/${userId}/`)
      .then((response) => {
        dispatch(userAuthenticate(response));
        dispatch(newNavbar('new'));
        window.location.reload();
      })
      .catch(() => {
        dispatch(userLogout(undefined));
        Cookies.remove('myCookie', { path: '/' });
        window.location.href = window.location.origin + '/login';
      });
  }

  useEffect(() => {
    setUser(currentUser);
  }, [navbar]);
  return (
    <AppBar
      style={{
        boxShadow: 'none',
        borderBottom: '1px solid lightgray',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Toolbar
        sx={{ pr: '24px' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex' }}>
          {cookie ? (
            <IconButton
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            ' '
          )}
          <Link to='/main/overview'>
            <img
              src='/static/images/logo-final-website-color.png'
              alt='Logo'
              height='40px'
              style={{
                margin: '10px 0px 10px 10px',
                width: '150px',
              }}
              width='100px'
            ></img>
          </Link>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {cookie ? <Notifications /> : ' '}
          {cookie ? <AvatarFunc /> : ' '}
        </div>
      </Toolbar>
    </AppBar>
  );
}
