import React from "react";
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import SimpleBarReact from "simplebar-react";
// import "simplebar/dist/simplebar.min.css";
import { alpha, styled } from "@mui/material/styles";
import { Popover } from "@mui/material";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Navigate, Link as RouterLink } from "react-router-dom";
import PRD from "../assets/img/notifications/PRD.png";
import PR from "../assets/img/notifications/PR.png";
import TR from "../assets/img/notifications/TR.png";
import PERSON from "../assets/img/notifications/PERSON.png";
import DOC from "../assets/img/notifications/DOC.png";
import CHAT from "../assets/img/notifications/CHAT.png";
import { useNavigate } from 'react-router-dom';

const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.01),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  open: PropTypes.any,
  anchorEl: PropTypes.any,
  onClose: PropTypes.any,
};

function MenuPopover({ children, sx, ...other }: any) {
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        elevation: 0,
        sx: {
          p: 1,
          width: 200,
          mt: 5,
          overflow: "inherit",
          ...sx,
        },
      }}
      {...other}
    >
      <ArrowStyle className="arrow" />

      {children}
    </Popover>
  );
}

const RootStyle = styled("div")(() => ({
  flexGrow: 1,
  height: "60vh",
  overflow: "hidden",
}));

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

function Scrollbar({ children, sx, ...other }: any) {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: "auto", ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarReact
        timeout={500}
        clickOnTrack={false}
        sx={sx}
        {...other}
        style={{ maxHeight: "60vh" }}
      >
        {children}
      </SimpleBarReact>
    </RootStyle>
  );
}

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
};

function Iconify({ icon, sx, ...other }: any) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}

function fToNow(date: any) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

interface INotification {
  id: any;
  title: any;
  type: any;
  createdAt: any;
  isUnRead: any;
  url: any;
}

export default function Notifications() {
  const anchorRef = useRef(null);
  const [readNotifications, setReadNotifications] = useState<any>({});
  const [unreadNotifications, setUnreadNotifications] = useState<any>({});
  const [allNotifications, setAllNotifications] = useState<any>({});
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [open, setOpen] = useState<any>(null);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    const fetchData = async () => {
      const result_info = await apiService.get(
        `${APP_URL}/notification/api/all_list/`
      );

      var listUnread: any = [];
      result_info.all_list
        .filter((item: any) => item.unread === true)
        .map((not: any) => {
          var helper: INotification = {
            id: undefined,
            title: undefined,
            type: undefined,
            createdAt: undefined,
            isUnRead: undefined,
            url: undefined,
          };
          helper.id = not.id;
          helper.title = not.description;
          helper.type = not.verb;
          helper.createdAt = not.timestamp;
          helper.isUnRead = not.unread;
          helper.url = not.url;
          listUnread.push(helper);
        });
      var listRead: any = [];
      result_info.all_list
        .filter((item: any) => item.unread === false)
        .map((not: any) => {
          var helper: INotification = {
            id: undefined,
            title: undefined,
            type: undefined,
            createdAt: undefined,
            isUnRead: undefined,
            url: undefined,
          };
          helper.id = not.id;
          helper.title = not.description;
          helper.type = not.verb;
          helper.createdAt = not.timestamp;
          helper.isUnRead = not.unread;
          helper.url = not.url;
          listRead.push(helper);
        });
      var listAll: any = [];
      result_info.all_list.map((not: any) => {
        var helper: INotification = {
          id: undefined,
          title: undefined,
          type: undefined,
          createdAt: undefined,
          isUnRead: undefined,
          url: undefined,
        };
        helper.id = not.id;
        helper.title = not.description;
        helper.type = not.verb;
        helper.createdAt = not.timestamp;
        helper.isUnRead = not.unread;
        helper.url = not.url;
        listAll.push(helper);
      });
      setAllNotifications(listAll);
      setReadNotifications(listRead);
      setUnreadNotifications(listUnread);
      setNotificationCount(listUnread.length);
    };
    let timerId = setInterval(() => fetchData(), 15000);
    fetchData();
  }, []);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const SetNotifications = () => {
    apiService
      .get(`${APP_URL}/notification/api/all_list/`)
      .then((result_info: any) => {
        var listUnread: any = [];
        result_info.all_list
          .filter((item: any) => item.unread === true)
          .map((not: any) => {
            var helper: INotification = {
              id: undefined,
              title: undefined,
              type: undefined,
              createdAt: undefined,
              isUnRead: undefined,
              url: undefined,
            };
            helper.id = not.id;
            helper.title = not.description;
            helper.type = not.verb;
            helper.createdAt = not.timestamp;
            helper.isUnRead = not.unread;
            helper.url = not.url;
            listUnread.push(helper);
          });
        var listRead: any = [];
        result_info.all_list
          .filter((item: any) => item.unread === false)
          .map((not: any) => {
            var helper: INotification = {
              id: undefined,
              title: undefined,
              type: undefined,
              createdAt: undefined,
              isUnRead: undefined,
              url: undefined,
            };
            helper.id = not.id;
            helper.title = not.description;
            helper.type = not.verb;
            helper.createdAt = not.timestamp;
            helper.isUnRead = not.unread;
            helper.url = not.url;
            listRead.push(helper);
          });
        var listAll: any = [];
        result_info.all_list.map((not: any) => {
          var helper: INotification = {
            id: undefined,
            title: undefined,
            type: undefined,
            createdAt: undefined,
            isUnRead: undefined,
            url: undefined,
          };
          helper.id = not.id;
          helper.title = not.description;
          helper.type = not.verb;
          helper.createdAt = not.timestamp;
          helper.isUnRead = not.unread;
          helper.url = not.url;
          listAll.push(helper);
        });
        setAllNotifications(listAll);
        setReadNotifications(listRead);
        setUnreadNotifications(listUnread);
        setNotificationCount(listUnread.length);
      });
  };

  const handleMarkAllAsRead = async () => {
    // for (let i = 0; i < unreadNotifications.length; i++) {
    apiService
      .patch(`${APP_URL}/notification/mark-all-as-read/`, {})
      .then(() => SetNotifications());
    // }
  };

  const handleFilter = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string
  ) => {
    setFilter(newFilter);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={notificationCount} color="error">
          <Iconify icon="eva:bell-fill" sx={{ width: 30, height: 30 }} />
        </Badge>
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 4, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {notificationCount} unread messages
            </Typography>
          </Box>

          {notificationCount > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify
                  icon="eva:done-all-fill"
                  sx={{ width: 20, height: 20 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <ListSubheader
          disableSticky
          sx={{ py: 1, px: 2.5, typography: "overline" }}
        >
          <StyledToggleButtonGroup
            size="large"
            exclusive
            value={filter}
            onChange={handleFilter}
          >
            <ToggleButton value="all" size="small" sx={{ borderRadius: "40%" }}>
              All
            </ToggleButton>
            <ToggleButton
              value="unread"
              size="small"
              sx={{ borderRadius: "40%" }}
            >
              Unread
            </ToggleButton>
          </StyledToggleButtonGroup>
        </ListSubheader>
        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: "auto", sm: "auto" } }}>
          {filter === "unread" &&
            unreadNotifications &&
            unreadNotifications.length > 0 && (
              <List disablePadding>
                {unreadNotifications.map((notification: any) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    setOpen={setOpen}
                  />
                ))}
              </List>
            )}
          <List disablePadding>
            {filter === "all" &&
              allNotifications &&
              allNotifications.length > 0 &&
              allNotifications.map((notification: any) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  setOpen={setOpen}
                />
              ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button
            onClick={() => setOpen(false)}
            fullWidth
            disableRipple
            component={RouterLink}
            to={"/notification-list"}
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

function NotificationItem({ notification,setOpen }: any) {
  const { avatar, title } = renderContent(notification);
  var helper = notification.url.split("/");
  const navigate = useNavigate();
  const handleNotificationButtonClick = (id: any) => {
    apiService.patch(`${APP_URL}/notification/mark-as-read/${id}/`, {});
    setOpen(null)
    navigate(helper[helper.length - 2] + '/' + helper[helper.length - 1]);
  };

  return (
    <Button
      id={notification.id}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        color: "black",
        textAlign: "left",
        whiteSpace: "normal",
        overflowWrap: "anywhere",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => handleNotificationButtonClick(notification.id)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </Button>
  );
}

function renderContent(notification: any) {
  const title = (
    <Typography variant="subtitle2">{notification.title}</Typography>
  );

  const performanceReviewList = ["PRA", "PRR", "PRF", "FPR"];

  if (performanceReviewList.indexOf(notification.type) !== -1) {
    return {
      avatar: <img src={PR} />,
      title,
    };
  } else if (notification.type === "TR") {
    return {
      avatar: <img src={TR} />,
      title,
    };
  } else if (notification.type === "PRD") {
    return {
      avatar: <img src={PRD} />,
      title,
    };
  } else if (notification.type === "DOC") {
    return {
      avatar: <img src={DOC} />,
      title,
    };
  } else if (notification.type === "COM") {
    return {
      avatar: <img src={CHAT} />,
      title,
    };
  } else {
    return {
      avatar: <img src={PERSON} />,
      title,
    };
  }
}