import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Link, Typography } from "@mui/material";
import OpenPositionsBackground from "../assets/img/OpenPositionsBackground.png"
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getPositionList } from "../classes/job_position";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";

const classes = ({
  root: {
    backgroundImage: `url(${OpenPositionsBackground})`,
    backgroundSize: "cover",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    paddingTop: "50px",
    width: "103%",
    height: "100vh",
    marginLeft: -20,
    marginBottom: -25,
    padding:0
  },
  title: {
    marginTop: "63px",
  },
  descBox: {
    marginTop: "50px",
    backdropFilter: "blur",
    background: 'linear-gradient(119.87deg, rgba(255, 255, 255, 0.8) 13.98%, rgba(237, 235, 235, 0.648) 100%)',
    boxShadow: '1px 1px 15px 0px #FFFFFF14 inset, 0px 10px 30px 0px #00000040',
  },
});

export default function ApplyFormPosition() {
  const [positions, setPositions] = useState([]);
  useEffect(() => {
    apiService.get(`${APP_URL}/job/jobposition/`).then((data) => setPositions(data));

  }, []);
  
  return (
    <Container maxWidth={false} style={classes.root}>
      <Grid container spacing={1} direction="row">
        <Grid item md={5} xs={12}>
        <div style={classes.title}>
         <Box textAlign="left" sx={{ml: "10%"}}>
         <Typography variant="h2" style={{ fontWeight: 700, fontStyle: "normal", marginBottom: 15}}>Open positions</Typography>
         <Typography paragraph style={{fontWeight: 700, marginBottom: 50, marginLeft: 10}}>Apply now</Typography>
         </Box>
         <Box sx={{
        overflow: "scroll",
        overflowY: "auto",
        overflowX: "auto",
        maxHeight: "65vh"
          }}>
         {positions?.map((pos: any) => (
           <Link href={`/apply/${pos.id}`} underline="none">
           <Box sx={{
            p: 2,
            backgroundColor: 'black',
            color: 'white',
            fontWeight: 'bold',
            mb: 6,
            width: "auto",
            textAlign: "center",
            maxWidth: "60%",
            ml: "10%"
          }
          }>
             <Typography style={{ fontWeight: 700 , fontStyle: "normal"}} variant="h2">{pos.name}</Typography>
           </Box>
           </Link>
           
         ))}
        </Box>
        </div>
        </Grid>
        <Grid item container md={1}>

        </Grid>
        <Grid item container md={4} justifyContent="center">
        <div style={classes.title}>
          <Box style={classes.descBox} sx={{p:5, backdropFilter: "Blur(11px)"}}>
          <Typography variant="h1" style={{textAlign: "left", paddingBottom: 10}}>WE'RE HIRING!</Typography>
          <Typography paragraph style={{textAlign: "left", color: "#666666"}}>
          We are hard core technologists who dedicated our careers to digital transformation of traditional industries. In some cases we’ve done the disruption bit, but our main goal is to be the trusted adviser and technology partner on our customers’ long haul journeys. As a part of our team you’ll enjoy a competitive salary, option to work completely remotely, flexible vacation and paid time off, and a fun environment where work-life balance is valued.
          </Typography>
          <Box display="flex"
               justifyContent="flex-start"
               alignItems="flex-start">
          <Button variant="contained" color="primary">About us<ArrowForwardIosIcon fontSize="small"/></Button>
          </Box>
          
          </Box>
        </div>
        </Grid>
        
      </Grid>
    </Container>
      
  );
}
