


export function cutFullName(user: any, cutoff_length:number) 
{
    let Name:string = user.first_name + " ";
                    
    if(user.last_name.split(" ").length > 1)
    {
      for(let i = 0; i < user.last_name.split(" ").length; i++)
      {
        if(i < user.last_name.split(" ").length - 1)
        {
          
          Name += user.last_name.split(" ")[i][0] + " ";
        }
        else
        {
          Name += user.last_name.split(" ")[i];
        }
      }
    }
    else{
      Name += user.last_name;
    }
    if(Name.length > cutoff_length)
      {
        Name = Name.substring(0, cutoff_length - 3) + "..."
      }
    return Name;
}


export function cutLastName(user: any, cutoff_length:number) 
{
    let Name:string = "";
     if(user !== undefined)
     {               
    if(user.last_name.split(" ").length > 1)
    {
      for(let i = 0; i < user.last_name.split(" ").length; i++)
      {
        if(i < user.last_name.split(" ").length - 1)
        {
          console.log(user.last_name.split(" ")[i] + ' - ' + user.last_name.split(" ")[i]);
          Name += user.last_name.split(" ")[i][0] + " ";
        }
        else
        {
          Name += user.last_name.split(" ")[i];
        }
      }
    }
    else{
      Name += user.last_name;
    }
    if(Name.length > cutoff_length)
      {
        Name = Name.substring(0, cutoff_length-3) + "..."
      }
    return Name;
     }
     else{
        return "";
     }
}