import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import TextEditor from "./components/TextEditor/TextEditor";
import _pick from "lodash/pick";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

const EditDocument = () => {
  const params = useParams<{ documentId: string }>();

  const [documentName, setDocumentName] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [document, setDocument] = useState<any>([]);
  const [publish, setPublish] = useState(false);

  let navigate = useNavigate();
  let currentUser = useSelector((state: any) => state.reducer.user);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchDocument = () => {
      if (!(currentUser.is_hr || currentUser.is_super_hr)) {
        navigate("/processes/");
      }
      apiService
        .get(`${APP_URL}/processes/documents/${params.documentId}/`)
        .then((doc) => {
          const initData = _pick(doc, Object.keys(doc));
          setDocumentName(initData.name);
          setEditorContent(initData.body);
          let newDate = new Date(initData.created_at);

          initData.created_at = `${newDate.getDate()}/${
            newDate.getMonth() + 1
          }/${newDate.getFullYear()}`;

          setDocument(initData);
          setLoader(false);
        });
    };
    fetchDocument();
  }, [currentUser, navigate, params.documentId]);

  const handleDraft = useCallback(async () => {
    setPublish(true);

    if (documentName.trim() === "") {
      return;
    }
    let patchData = {
      id: params.documentId,
      is_draft: true,
      name: documentName,
      body: editorContent,
    };
    await apiService
      .patch(`${APP_URL}/processes/documents/`, patchData)
      .catch((message) => {})
      .then(() => {
        Swal.fire("Document saved as draft!", "", "success");
        navigate("/processes/");
      });
  }, [editorContent, params.documentId, documentName, navigate]);

  const handlePublish = useCallback(async () => {
    setPublish(true);
    if (documentName.trim() === "") {
      return;
    }
    let patchData = {
      id: params.documentId,
      is_draft: false,
      name: documentName,
      body: editorContent,
    };
    await apiService
      .patch(`${APP_URL}/processes/documents/`, patchData)
      .catch((message) => {})
      .then(() => {
        Swal.fire("Document published!", "", "success");
        navigate("/processes");
      });
  }, [editorContent, params.documentId, documentName, navigate]);

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Box mt={10} sx={{ backgroundColor: "white" }}>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          marginLeft: "15px",
        }}
      >
        <Box>
          <TextField
            variant="outlined"
            placeholder="Document name"
            autoComplete="off"
            value={documentName}
            error={publish && documentName.trim() === ""}
            helperText={
              publish && documentName.trim() === ""
                ? "This field is required"
                : ""
            }
            onChange={(e) => {
              setDocumentName(e.target.value);
            }}
            sx={{ width: "300px", marginTop: "15px" }}
          />
          <Typography
            align="left"
            fontSize={12}
            marginTop="2px"
            marginLeft="5px"
            color="#8A8A8A"
          >
            Created at {document.created_at} by {document.created_by.first_name}{" "}
            {document.created_by.last_name}
          </Typography>
        </Box>
        <Box>
          {" "}
          <Button
            style={{
              marginRight: "25px",
              marginTop: "15px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
              height: "50%",
            }}
            onClick={handleDraft}
          >
            Save as Draft
          </Button>
          <Button
            style={{
              marginRight: "25px",
              marginTop: "15px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
              height: "50%",
            }}
            onClick={handlePublish}
          >
            Publish
          </Button>
        </Box>
      </Box>

      <Box marginTop={"30px"}>
        <TextEditor
          getEditorContent={setEditorContent}
          content={editorContent}
        />
      </Box>
    </Box>
  );
};
export default EditDocument;
