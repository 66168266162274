import React, { useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  DraftEditorCommand,
} from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "draft-js/dist/Draft.css";
import { mediaBlockRenderer } from "./Media";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./styles.css";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import { IconButton, Input, Stack } from "@mui/material";
const TextEditor: React.FC<{ getEditorContent: any; content: string }> = ({
  getEditorContent,
  content,
}) => {
  const [formats, setFormats] = useState([]);
  const styles = ["BOLD", "UNDERLINE", "ITALIC", "STRIKETHROUGH"];

  const converter = {
    htmlToEntity: (nodeName: any, node: any, createEntity: any) => {
      // a tags will become LINK entities, marked as mutable, with only the URL as data.
      if (nodeName === "a") {
        return createEntity("LINK", "MUTABLE", { url: node.href });
      }

      if (nodeName === "img") {
        return createEntity("image", "IMMUTABLE", {
          src: node.src,
        });
      }

      if (nodeName === "hr") {
        return createEntity("hr", "IMMUTABLE", {});
      }

      return null;
    },
    htmlToBlock: (nodeName: any) => {
      if (nodeName === "hr" || nodeName === "img") {
        // "atomic" blocks is how Draft.js structures block-level entities.
        return "atomic";
      }

      return null;
    },
  };

  let contentState = convertFromHTML(converter)(content);
  const initialState = EditorState.createWithContent(contentState);

  const [editorState, setEditorState] =
    React.useState<EditorState>(initialState);

  const handleAddLink = () => {
    const selection = editorState.getSelection();
    const link = prompt("Please enter the URL of your link");
    if (!link) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
      return;
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "apply-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
  };

  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleToggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    const currentFormats = newFormats.filter((format: string) =>
      editorState.getCurrentInlineStyle().has(format)
    );
    setFormats(currentFormats);
  };

  function handleEditor(e: any) {
    const activeStyles: string[] = [];
    setEditorState(e);
    // eslint-disable-next-line array-callback-return
    styles.map((style: string) => {
      if (editorState.getCurrentInlineStyle().has(style)) {
        activeStyles.push(style);
      }
    });
    setFormats(activeStyles);
    // const data = convertToHTML(editorState.getCurrentContent());
    console.log(convertToHTML(editorState.getCurrentContent()));
    getEditorContent(currentContentAsHTML);
  }
  let currentContentAsHTML = convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (entity.type === "image") {
        return `<figure><img src="${entity.data.src}" /></figure>`;
      } else if (entity.type === "LINK") {
        console.log("link");
        return `<a href="${entity.data.url}" target="_blank">${originalText}</a>`;
      }
      return originalText;
    },
  })(editorState.getCurrentContent());

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addImage = async (e: React.ChangeEvent<any>) => {
    if (e.target.files[0].size < 67108864) {
      const data = new FormData();
      data.append("file", e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      // eslint-disable-next-line no-new-wrappers
      const image = await convertToBase64(e.target.files[0]);

      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "image",
        "IMMUTABLE",
        { src: image }
      );

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentState,
      });

      setEditorState(
        AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
      );
    }
  };
  return (
    <div className="texteditor" style={{ alignItems: "center" }}>
      <ToggleButtonGroup size="small" value={formats} onChange={handleFormat}>
        <ToggleButtonGroup sx={{ marginRight: 2 }}>
          <ToggleButton
            value="header-one"
            onMouseDown={(e) => handleBlockClick(e, "header-one")}
          >
            H1
          </ToggleButton>
          <ToggleButton
            value="header-two"
            onMouseDown={(e) => handleBlockClick(e, "header-two")}
          >
            H2
          </ToggleButton>
          <ToggleButton
            value="header-three"
            onMouseDown={(e) => handleBlockClick(e, "header-three")}
          >
            H3
          </ToggleButton>
          <ToggleButton
            value="unstyled"
            onMouseDown={(e) => handleBlockClick(e, "unstyled")}
          >
            Normal
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButton
          value="BOLD"
          onMouseDown={(e) => handleToggleClick(e, "BOLD")}
        >
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton
          value="UNDERLINE"
          onMouseDown={(e) => handleToggleClick(e, "UNDERLINE")}
        >
          <FormatUnderlinedIcon />
        </ToggleButton>
        <ToggleButton
          value="ITALIC"
          onMouseDown={(e) => handleToggleClick(e, "ITALIC")}
        >
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          value="STRIKETHROUGH"
          onMouseDown={(e) => handleToggleClick(e, "STRIKETHROUGH")}
        >
          <StrikethroughSIcon />
        </ToggleButton>
        <ToggleButtonGroup exclusive sx={{ paddingLeft: 2 }}>
          <ToggleButton
            value="ordered-list-item"
            onMouseDown={(e) => handleBlockClick(e, "ordered-list-item")}
          >
            <FormatListNumberedIcon />
          </ToggleButton>
          <ToggleButton
            value="unordered-list-item"
            onMouseDown={(e) => handleBlockClick(e, "unordered-list-item")}
          >
            <FormatListBulletedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup sx={{ paddingLeft: 2 }}>
          <ToggleButton value="Image">
            <Stack>
              <label htmlFor="image-file">
                <Input
                  hidden={true}
                  id="image-file"
                  type="file"
                  name="image-file"
                  onChange={(e) => addImage(e)}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <ImageIcon />
                </IconButton>
              </label>
            </Stack>
          </ToggleButton>

          <ToggleButton
            value="Link"
            disabled={editorState.getSelection().isCollapsed()}
            onMouseDown={(e) => {
              e.preventDefault();
              handleAddLink();
            }}
          >
            <LinkIcon />
          </ToggleButton>
          <ToggleButton
            value="Undo"
            disabled={editorState.getUndoStack().size <= 0}
            onMouseDown={() => setEditorState(EditorState.undo(editorState))}
          >
            <UndoIcon />
          </ToggleButton>
          <ToggleButton
            value="Redo"
            disabled={editorState.getRedoStack().size <= 0}
            onMouseDown={() => setEditorState(EditorState.redo(editorState))}
          >
            <RedoIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </ToggleButtonGroup>
      <Editor
        editorState={editorState}
        onChange={handleEditor}
        handleKeyCommand={handleKeyCommand}
        blockRendererFn={mediaBlockRenderer}
      />
    </div>
  );
};

export default TextEditor;
