import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import DownloadIcon from "../assets/img/DownloadIcon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Modal from "@mui/material/Modal";
import { saveAs } from "file-saver";
import { AvatarIcon } from "./Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { cutFullName } from "../utils/functions";
import ViewerPdf from "./PDFViewer/ViewerPdf";

const PerformanceReviewHistoryList = (props: any) => {
  const [state, setState] = useState<any>(null);
  const [data, setData] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [openedDocument, setOpenedDocument] = useState<any>();

  const url = props.employee
    ? `${APP_URL}/performance-review/pr_request/employee/${props.employee}/`
    : `${APP_URL}/performance-review/pr_history/`;

  const openModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/pdf2/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

    setState(id);
  };

  useEffect(() => {
    apiService.get(url).then((resp) => {
      let response = resp.reverse();
      setData(resp);
      setLoader(false);
    });
  }, []);

  const getNameOfDocument = (path: string) => {
    let splitted = path.split("/");
    let return_string: string = splitted[splitted.length - 1];
    return return_string;
  };

  const downloadFile = (id: any) => {
    const docName = getNameOfDocument(`${id}`);
    apiService
      .getFile(`${APP_URL}/performance-review/pdf2/${id}/`)
      .then((resp) => {
        saveAs(resp, `Form ${docName}`);
      });
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Box sx={{ minWidth: 950 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Requested for</TableCell>
              <TableCell align="center">Requested by</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Preview</TableCell>
              <TableCell align="center">Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any) => (
              <>
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ background: item?.deleted_by != null && "#F5C4C5" }}
                >
                  <TableCell align="center">
                    <Box ml={2}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <AvatarIcon
                          src={
                            "data:image/jpeg;base64," +
                            item?.employee?.avatar_64
                          }
                          firstName={item?.employee.first_name}
                          lastName={item?.employee.last_name}
                        />
                      </Box>
                      {/* {item?.title ? item?.title : item?.deny_reason} */}
                      {item?.deny_reason
                        ? `Deny reason: ${item?.deny_reason}`
                        : `Performance review for ${item.employee.first_name} ${item.employee.last_name}`}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AvatarIcon
                        src={
                          "data:image/jpeg;base64," + item?.requester?.avatar_64
                        }
                        firstName={item?.requester.first_name}
                        lastName={item?.requester.last_name}
                      />
                      <Box ml={2}>{cutFullName(item?.requester, 25)}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {item?.end_date
                      ? moment(item?.end_date).format("MMM Do YY")
                      : "Blank date"}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      // disabled={!item.approved}
                      onClick={() => openModal(item?.id)}
                    >
                      <VisibilityIcon style={{ color: "black" }} />
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      // disabled={!item.approved}
                      onClick={() => downloadFile(item?.id)}
                    >
                      <img src={DownloadIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            ))}
            {openedDocument && (
              <Modal
                open={state}
                onClose={() => setState(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  marginTop: 5,
                  height: 680,
                  width: 680,
                  left: "30%",
                }}
              >
                <ViewerPdf file={`${openedDocument}`} />
              </Modal>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PerformanceReviewHistoryList;
