import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { decodeToken } from "react-jwt";

const classes = ({
  table: {
    marginTop: "20px",
    background: "#ffffff",
  },
  button: {
    color: "#323C47",
  },
});

var jwt = require("react-jwt");

export default function AllEmployeesList({ setId }: any) {
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [empList, setEmpList] = useState<any>([]);
  const [project, setProject] = useState<any>([]);
  const [clients, setClients] = useState<any>([]);
  const [user, setUser] = useState<any>("");
  const [loader, setLoader] = useState(true);

  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;
  let currentUser = useSelector((state: any) => state.reducer.user);

  useEffect(() => {
    setUser(currentUser);
  }, []);

  useEffect(() => {
    if (user) {
      if (user.is_hr || user.is_super_hr || user.is_regular_user) {
        const url = `${APP_URL}/users/profile/`;
        apiService.get(url).then((resp: any) => {
          let popEmp = resp.filter((emp: any) => emp.user.id !== userId);
          setEmployeeList(popEmp);
          setEmpList(popEmp);
          setLoader(false);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    apiService.get(`${APP_URL}/users/client/`).then((resp: any) => {
      setClients(resp);
    });
  }, []);

  if (clients) {
    var clientNameList: string[] = clients.map((c: any) => c.name);
    const removeFilters: string = "No filters";
    clientNameList.unshift(removeFilters);
  }
  const changeClient = (e: any) => {
    if (e.target.value === "No filters") {
      setEmpList(employeeList);
    } else {
      let empListHelper = employeeList.filter(
        (emp: any) => e.target.value === emp.client.name
      );
      setEmpList(empListHelper);
    }
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Container>
      <Box>
        <Box mt={15}>
          <FormControl sx={{ width: "20%" }}>
            <InputLabel id="demo-simple-select-label">
              Sort by client
            </InputLabel>
            <Select
              native
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Sort by project"
              onChange={(e) => changeClient(e)}
            >
              {clientNameList.map((t: any) => (
                <option>{t}</option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Table style={classes.table}>
        <TableHead style={{ whiteSpace: "nowrap" }}>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell>CLIENT</TableCell>
            <TableCell>PROJECT</TableCell>
            <TableCell>TEAM</TableCell>
            <TableCell>POSITION</TableCell>
            <TableCell>SENIORITY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ whiteSpace: "nowrap" }}>
          {empList.length > 0 &&
            empList.map((emp: any) => (
              <TableRow>
                <TableCell>
                  <Button
                    style={classes.button}
                    component={RouterLink}
                    to={`/employee/${emp.id}`}
                    onClick={() => {
                      setId(emp.id);
                    }}
                  >
                    <b>{emp.user.first_name + " " + emp.user.last_name}</b>
                  </Button>
                </TableCell>
                <TableCell>{emp.client?.name}</TableCell>
                <TableCell>
                  {emp.project.length > 0 && emp.project[0].name}
                </TableCell>
                <TableCell>
                  {emp.team.length > 0 && emp.team[0]?.name}
                </TableCell>
                <TableCell>{emp.position?.name}</TableCell>
                <TableCell>{emp.seniority?.name}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          component={RouterLink}
          to={"/create-user"}
        >
          Create New User
        </Button>
        </div>
    </Container>
  );
}
