import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams, useNavigate } from "react-router-dom";
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService";
import {
  Typography,
  Container,
  FormControl,
  Select,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import JobAppBackground from "../assets/img/JobAppBackground.png"
import Swal from "sweetalert2";

const classes = ({
  root: {
    backgroundColor: "#f5f6f8",
    backgroundImage: `url(${JobAppBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: "strech",
    width: "100vw",
    paddingTop: "70px"

  },
  title: {
    marginTop: "100px",
  },
  margin: {
    marginTop: "50px",
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
  descBox: {
    backdropFilter: "blur",
    background: 'linear-gradient(119.87deg, rgba(255, 255, 255, 0.8) 13.98%, rgba(237, 235, 235, 0.648) 100%)',
    boxShadow: '1px 1px 15px 0px #FFFFFF14 inset, 0px 10px 30px 0px #00000040',
  }
}); 

type FormDataType = {
  seniority: any;
  first_name: string;
  last_name: string;
  job_position: string;
  email: string;
  linkedIn: string;
  cv: any;
  hear: string;
};

const initFormData: FormDataType = {
  first_name: "",
  last_name: "",
  job_position: "",
  email: "",
  linkedIn: "",
  seniority: undefined,
  hear: "",
  cv: undefined,
};

export default function ApplicationForm() {
  const params = useParams<{ position: string }>();
  // const classes = useStyles();

  const [formData, setFormData] = useState(initFormData);
  const [jobPositionObject, setJobPositionObject] = useState<any>();
  const navigate = useNavigate()

  const hearOptions = [
    "",
    "Recommendation",
    "Sales",
    "Regruter",
    "Infostud",
    "LinkedIn",
    "Career Development Center",
  ];

  const seniority = [
    "",
    "Entry",
    "Junior",
    "Medior",
    "Senior",
  ];
  const url = `/apply-positions`;

  const Toast = Swal.mixin({
    toast: true,
    color: "white",
    iconColor: "white",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });





  useEffect(() => {
    setFormData((_formState) => ({
      ..._formState,
      job_position: params.position,
    }));
  }, []);

  useEffect(() => {
    let pos_id = params.position;
    let url = `${APP_URL}/job/jobposition/${pos_id}/`;
    apiService.get(url).then((resp) => {
      setJobPositionObject(resp);
    });
  }, []);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    event.preventDefault();
  };

  const changeSeniority = (e: any) => {
    setFormData((_formState) => ({
      ..._formState,
      seniority: e.target.value,
    }));
  };

  const changeHear = (e: any) => {
    setFormData((_formState) => ({
      ..._formState,
      hear: e.target.value,
    }));
  };

  const addFile = (e: any) => {
    if (e.target.files) {
      const files = e.target.files;
      setFormData((_formState) => ({
        ..._formState,
        cv: files[0],
      }));
    }
  };

  return (
    <Container style={classes.root} maxWidth={false}>
      <div style={classes.mainDiv}>
      <Grid container spacing={1} direction="row">
      <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={5} justifyContent="center">
              <Box sx={{
                p: 2,
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 'bold',
                mb: 6,
                width: "auto",
                textAlign: "center",
                maxWidth: "60%",
                ml: "20%"
              }
              }
              >
              <Typography style={{ fontWeight: 700 , fontStyle: "normal"}} variant="h2">{jobPositionObject && jobPositionObject.name}</Typography>
              </Box>
              <Box style={classes.descBox} sx={{px:5, py:3, backdropFilter: "Blur(11px)", textAlign: "center", maxHeight: "65vh", overflow: "auto"}}>
              <div style={{textAlign: "left"}}>
              <p dangerouslySetInnerHTML={{__html: jobPositionObject?.job_description}}></p>
              </div>
              </Box>
            </Grid>
        <Grid item xs={1} md={1}></Grid>
        <Grid item xs={12} md={4}>
        <form
          onSubmit={submitForm}
          encType="multipart/form-data"
          style={classes.title}
        >
              <Grid spacing={2} container direction="row">
              <Grid item xs={12} md={6}>
                <p style={{marginBottom: "5px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",}}>First Name</p>
                <TextField
                  required
                  style={{ width: "100%" }}
                  variant="outlined"
                  id="first_name"
                  name="first_name"
                  onChange={(e:any) =>
                    setFormData((_formState) => ({
                      ..._formState,
                      first_name: e.target.value,
                    }))
                  }
                />
                </Grid>
                <Grid item xs={12} md={6}>

                <p style={{marginBottom: "5px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",}}>Last Name</p>
                <TextField
                  required
                  style={{ width: "100%" }}
                  variant="outlined"
                  id="last_name"
                  name="last_name"
                  onChange={(e:any) =>
                    setFormData((_formState) => ({
                      ..._formState,
                      last_name: e.target.value,
                    }))
                  }
                />
                </Grid>
                </Grid>
                <p style={{marginBottom: "5px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",}}>Email</p>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="email"
                  name="email"
                  onChange={(e:any) =>
                    setFormData((_formState) => ({
                      ..._formState,
                      email: e.target.value,
                    }))
                  }
                />
                <p style={{marginBottom: "5px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",}}>LinkedIn</p>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="linkedIn"
                  name="linkedIn"
                  onChange={(e:any) =>
                    setFormData((_formState) => ({
                      ..._formState,
                      linkedIn: e.target.value,
                    }))
                  }
                />
              <FormControl
                required
                fullWidth
                variant="outlined"
                color="primary"
              >
                <p style={{marginBottom: "5px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",}}>Seniority</p>
                <Select native onChange={(e:any) => changeSeniority(e)} fullWidth>
                  {seniority.map((item: any) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                required
                fullWidth
                variant="outlined"
                color="primary"
              >
                <p style={{marginBottom: "5px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",}}>How did you hear about us</p>
                <Select native onChange={(e:any) => changeHear(e)}>
                  {hearOptions.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </FormControl>

              <div>
                <p>Upload CV</p>
                <input
                  required
                  style={{ marginLeft: "80px" }}
                  type="file"
                  id="cv"
                  name="cv"
                  accept=".pdf, .docx"
                  onChange={(e) => addFile(e)}
                />
              </div>

              <br></br>

              <div>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    let newFormData = new FormData();
                    newFormData.append("first_name", formData.first_name);
                    newFormData.append("last_name", formData.last_name);
                    newFormData.append("email", formData.email);
                    newFormData.append("job_position", formData.job_position);
                    newFormData.append("cv", formData.cv);
                    newFormData.append("linkedin", formData.linkedIn);
                    newFormData.append("seniority", formData.seniority);
                    newFormData.append("heard_for_position_by", formData.hear);
                    apiService.post(`${APP_URL}/job/apply/`, newFormData, "application/json", false)
                    .then(() => {
                      Toast.fire({
                        icon: "success",
                        title: "Application submitted succesfully!",
                        background: "#a5dc86",
                      })
                      .then(()=>{
                        navigate("/apply-positions")
                      })
                    })
                    .catch(()=>{
                      Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                        background: "#f27474",
                      });
                    })
                }}
                >
                  APPLY
                </Button>
                
              </div>
        </form>
        </Grid>
        <br />
        <br />
        <br />
        <Grid container direction="row-reverse">
        <Grid xs={12} md={2}>
        <Button href={url} variant="text" style={{textTransform: 'none', fontSize: "16px", paddingRight: "0", marginTop: "50px"}}>
          Check out other available positions <ArrowForwardIosIcon />
        </Button>
        </Grid>
        </Grid>
        </Grid>
        </div>

    </Container>
  );
}

