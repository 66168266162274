import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import apiService from '../services/apiService';
import { APP_URL } from '../utils/constants';
import ViewerPdf from './PDFViewer/ViewerPdf';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '../assets/img/DownloadIcon.svg';
import Swal from 'sweetalert2';

const classes = {
  approved: {
    backgroundColor: "#64dd17",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  declined: {
    backgroundColor: "#d50000",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  reviewing: {
    backgroundColor: "#007FFF",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  inProgress: {
    backgroundColor: "#26a69a",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  notInitiated: {
    backgroundColor: "#FAA49D",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  cell: {
    width: 200,
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  add: {
    display: "flex",
    marginLeft: "3rem",
  },
  addForm: {
    backgroundColor: "teal",
    display: "flex",
    marginTop: "0.9rem",
  },
};

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const PerformanceReviewEmployeeViewProperties: React.FC<any> = ({
  data,
  userId,
}) => {
  const [state, setState] = useState<any>(null);
  const [openedDocument, setOpenedDocument] = useState<any>();
  let navigate = useNavigate();

  const handleClassName = (item: any) => {
    switch (item.in_progress) {
      case true:
        return classes.inProgress;
      case false:
        return classes.reviewing;
      default:
        return classes.approved;
    }
  };

  const handleStatusView = (item: any) => {
    if (item.in_progress === null) {
      return "New";
    } else if (item.in_progress === true) {
      return "In progress";
    } else if (item.in_progress === false) {
      return "Completed";
    }
  }

  const openModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/form/pdf2/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

    setState(id);
  };

  const openModalActionPlan = (id: string) => {
    apiService
      .getFile(
        `${APP_URL}/performance-review/action-plan-new/pdf/${data.action_plan.id}/`
      )
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });
    setState(id);
  };

  const handleFile = (e: any) => {
    const files = e.target.files;
    const formData = new FormData();
    formData.append('performance_review_request', data.id);
    formData.append('document', files[0]);
    formData.append('user_profile', userId);

    apiService
      .post(
        `${APP_URL}/performance-review/action-plan-new/create/`,
        formData,
        'multipart/form-data'
      )
      .catch(function (res) {
        if (res === 'Bad Request') {
          Toast.fire({
            icon: "error",
            title: `Action plan already exists for this PR`,
            background: "#f27474",
          });
        } else if (res === 'Created') {
          Toast.fire({
            icon: "success",
            title: `Actions plan successfully added`,
            background: "#a5dc86",
          }); 
        }
      });
  };

  let firstName = data.action_plan?.user_profile.user.first_name;
  let lastName = data.action_plan?.user_profile.user.last_name;

  return (
    <>
      <Box sx={{ minWidth: 950 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b>Filling the form</b>
                </TableCell>
                <TableCell align="center">
                  <b>Type of form</b>
                </TableCell>
                <TableCell align="center">
                  <b>Date of new request</b>
                </TableCell>
                <TableCell align="center">
                  <b>Status</b>
                </TableCell>
                <TableCell align="center">
                  <b>Preview</b>
                </TableCell>
                <TableCell align="center">
                  <b>Download</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.review_forms?.map((item: any) => (
                <>
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  />
                  <TableCell align="center">{`${item.user.user.first_name} ${item.user.user.last_name}`}</TableCell>
                  <TableCell align="center">{item?.name}</TableCell>
                  <TableCell align="center">
                    {moment(item?.created_at).format('MMM Do YY')}
                  </TableCell>
                  <TableCell style={{ width: 120 }} align="center">
                      <Box style={handleClassName(item)}>
                        {handleStatusView(item)}
                      </Box>
                    </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => openModal(item?.id)}>
                      <VisibilityIcon style={{ color: 'black' }} />
                    </Button>
                    {openedDocument && (
                      <Modal
                        open={state}
                        onClose={() => setState(null)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                          marginTop: 5,
                          height: 680,
                          width: 680,
                          left: '30%',
                        }}
                      >
                        <ViewerPdf file={`${openedDocument}`} />
                      </Modal>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Button>
                      <img src={DownloadIcon} />
                    </Button>
                  </TableCell>
                </>
              ))}
              {data.action_plan != null && (
                <>
                  <TableRow
                    key={data.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align="center">{`${firstName} ${lastName}`}</TableCell>
                    <TableCell align="center">Action Plan</TableCell>
                    <TableCell align="center">
                      {moment(data?.created_at).format('MMM Do YY')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => openModalActionPlan(data.action_plan.id)}
                      >
                        <VisibilityIcon style={{ color: 'black' }} />
                      </Button>
                      {openedDocument && (
                        <Modal
                          open={state}
                          onClose={() => setState(null)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          sx={{
                            marginTop: 5,
                            height: 680,
                            width: 680,
                            left: '30%',
                          }}
                        >
                          <ViewerPdf file={`${openedDocument}`} />
                        </Modal>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Button>
                        <img src={DownloadIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          component="label"
          onChange={(e) => {
            handleFile(e);
          }}
          style={{ float: 'left' }}
        >
          ADD ACTION PLAN
          <input hidden accept="/*" type="file" />
        </Button>
      </Box>
    </>
  );
};

export default PerformanceReviewEmployeeViewProperties;
