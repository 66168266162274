import { useEffect, useState } from "react";
import apiService from "../../services/apiService";
import { Tooltip, Typography } from "@mui/material";
import { APP_URL } from "../../utils/constants";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AvatarIcon } from "../Avatar";

const classes = {
  rigthComments: {
    fontSize: "18px",
    padding: "5px",
    display: "flex",
    flexDirection: "row-reverse",
  },
  leftComments: {
    fontSize: "18px",
    padding: "5px",
    display: "flex",
  },
  userRight: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: 10,
  },
  userLeft: {
    display: "flex",
    padding: 10,
  },
};

export default function Comment(data: any) {
  const [icon, setIcon] = useState<any>();
  const [user, setUser] = useState<any>();

  let classNameComments =
    data.comment.user.id === data.userId
      ? classes.leftComments
      : classes.rigthComments;

  let classNameUser =
    data.comment.user.id === data.userId ? classes.userLeft : classes.userRight;

  useEffect(() => {
    if (data.comment.user.avatar) {
      apiService.get(`${data.comment.user.avatar}`).then((resp) => {
        setIcon("data:image/png;base64," + resp);
      });
      apiService
        .get(`${APP_URL}/users/profile/${data.userId}/`)
        .then((resp) => setUser(resp));
    }
  }, []);

  return (
    <>
      <div style={classNameUser}>
        <AvatarIcon
          src={icon}
          firstName={data.comment.user.user.first_name}
          lastName={data.comment.user.user.last_name}
          style={{
            marginRight: "10px",
            marginLeft: "10px",
          }}
        />
        <div
          style={{
            backgroundColor:
              classNameUser === classes.userLeft ? "#EBEEF1" : "#DBA9B2",
            borderRadius: "20px",
            padding: 10,
            maxWidth: "450px",
            whiteSpace: "normal",
            overflowWrap: "anywhere",
            textAlign: "left",
          }}
        >
          <Typography
            style={{ paddingLeft: 5, paddingRight: 5, fontWeight: "500" }}
          >
            {data.comment.user.user.first_name + " "}
            {data.comment.user.user.last_name}
            {data.comment.is_confidential ? (
              <Tooltip title="Confidential">
                <VisibilityOffIcon
                  sx={{
                    float: "right",
                    marginLeft: 1,
                  }}
                ></VisibilityOffIcon>
              </Tooltip>
            ) : (
              ""
            )}
          </Typography>
          <Typography style={classNameComments}>{data.comment.body}</Typography>
        </div>
      </div>
    </>
  );
}
