import React from "react";
import { stringAvatar } from "../utils/constants";
import { Avatar } from "@mui/material";

export const AvatarIcon = (props: any) => {
  return (
    <Avatar
      alt=""
      src={props.src}
      {...stringAvatar(props.firstName + " " + props.lastName)}
      sx={props.style}
    />
  );
};
