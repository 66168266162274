import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { APP_URL } from "../utils/constants";
import React from "react";
import Cookies from "js-cookie";
import moment from "moment";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";
import _cloneDeep from "lodash/cloneDeep";
import apiService from "../services/apiService";
import { FormDataType } from "../interfaces/FormDataType";
import { FormDataTypeError } from "../interfaces/FormDataTypeError";
import { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import { AvatarIcon } from "./Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { cutFullName } from "../utils/functions";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { decodeToken } from "react-jwt";

var jwt = require("react-jwt");
let employees: any = [];

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const classes = {
  button: {
    "&:hover": {
      backgroundColor: "#003A75",
    },
    backgroundColor: "#2979ff",
  },
  buttonsPopUp: {
    alignItems: "center",
    justifyContent: "end",
  },
  buttonPopUp: {
    "&:hover": {
      backgroundColor: "#003A75",
    },
    backgroundColor: "#2962ff",
    margin: "0.3rem",
  },
};

const initFormData: FormDataType = {
  start_date: undefined, //start_date
  end_date: undefined, //end_date
  location: "", //location
  reason: "", //reason
  additional_info: "", //additional_info
  title: "",
  employees: [],
};

const initFormDataErrors: FormDataTypeError = {
  start_date: false, //start_date
  end_date: false, //end_date
  location: false, //location
  reason: false, //reason
  title: false,
  employees: false,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
};

const TravelRequest = () => {
  const [formData, setFormData] = useState(initFormData);
  const [formDataErrors, setFormDataErrors] = useState(initFormDataErrors);
  const [loader, setLoader] = useState<any>(false);
  const [usersPerClient, setUsersPerClient] = useState<any>();
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [selectCount, setSelectCount] = useState(0);

  const [empDict, setEmpDict] = useState<any>([]);

  const navigate = useNavigate();

  const handleChangeSelect = (event: SelectChangeEvent<any>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

    setFormDataErrors({
      ...formDataErrors,
      [event.target.name]: _isEmpty(event.target.value),
    });
  };

  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;

  let currentUser = useSelector((state: any) => state.reducer.user);
  if(!currentUser.is_client && !currentUser.is_super_hr)
  {
    navigate("/main/travel-request-history");
  }
  useEffect(() => {
    let employee_dict: any = [];
    if (currentUser.is_hr || currentUser.is_super_hr) {
      apiService.get(`${APP_URL}/users/profile/`).then((resp) => {
        for (var i = 0; i < resp.length; i++) {
          employee_dict = [
            ...employee_dict,
            {
              name: `${resp[i].user.first_name} ${resp[i].user.last_name}`,
              id: resp[i].user.id,
            },
          ];
        }
        setEmpDict(employee_dict);
        setUsersPerClient(resp);
      });
    } else {
      apiService.get(`${APP_URL}/users/per-client/`).then((resp) => {
        for (var i = 0; i < resp.users.length; i++) {
          employee_dict = [
            ...employee_dict,
            {
              name: `${resp.users[i].user.first_name} ${resp.users[i].user.last_name}`,
              id: resp.users[i].user.id,
            },
          ];
        }
        setEmpDict(employee_dict);
        setUsersPerClient(resp.users);
      });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name !== "additional_info") {
      setFormDataErrors({
        ...formDataErrors,
        [e.target.name]: _isEmpty(e.target.value),
      });
    }
  };

  const handleChangeDate = (e: any) => {
    setFormData({
      ...formData,
      start_date: moment(e[0]).format("yyyy-MM-DD"),
      end_date: moment(e[1]).format("yyyy-MM-DD"),
    });
    setValue(e);

    setFormDataErrors({
      ...formDataErrors,
      start_date: _isNull(e[0]),
      end_date: _isNull(e[1]),
    });
  };

  const handleSubmit = () => {
    const errors: any = _cloneDeep(formDataErrors);
    for (const [key, value] of Object.entries(formData)) {
      if (_isEmpty(value) && key !== "additional_info") {
        errors[key] = true;
      }
    }
    setFormDataErrors(errors);

    if (Object.values(errors).filter((x) => x === true).length > 0) {
      return;
    } else {
      for (let j = 0; j < formData.employees.length; j++) {
        for (var i = 0; i < Object.keys(empDict).length; i++) {
          if (formData.employees[j].id === empDict[i].id) {
            employees = [...employees, empDict[i].id];
          }
        }
      }
      setLoader(true);
      const data: any = _cloneDeep(formData);
      apiService
        .post(`${APP_URL}/users/travel-request/`, {
          formData,
          client: userId,
          employees,
          title: formData.title,
        })
        .then(() => {
          Toast.fire({
            icon: "success",
            title: "Travel request successfully sent!",
            background: "#a5dc86",
          });
          setFormData(initFormData);
          setLoader(false);
          employees = [];
          setValue([null, null]);
        })
        .catch((resp) => {
          Toast.fire({
            icon: "error",
            title: resp,
            background: "#f27474",
          });
          employees = [];
          setLoader(false);
        });
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <form>
        <Box display="flex" flexDirection="column" mt={15}>
          <h1>Travel request form</h1>
          <TextField
            sx={{ mt: 1 }}
            size="small"
            id="demo-helper-text-aligned"
            label="Trip Title"
            name="title"
            error={formDataErrors.title}
            value={formData.title}
            onChange={handleChange}
            inputProps={{ maxLength: 50 }}
          />

          <FormControl sx={{ mt: 1 }} style={{ paddingBottom: "8px" }}>
            <InputLabel id="demo-multiple-checkbox-label">Employees</InputLabel>
            <Select
              multiple
              value={formData.employees}
              name="employees"
              label="Employees"
              error={formDataErrors.employees}
              onChange={handleChangeSelect}
              renderValue={(selected) =>
                selected
                  .map((user: any, index: any, selected: any) => {
                    if (index < 2) {
                      return cutFullName(user, 25);
                    }
                    setSelectCount(index - 1);
                    return null;
                  })
                  .filter(Boolean)
                  .join(", ") +
                (selected.length > 2 ? " +" + selectCount + " others" : "")
              }
            >
              {usersPerClient &&
                usersPerClient.map((user: any) => (
                  <MenuItem
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "rgba(16, 156, 241, 0.3)",
                        // color: "white"
                      },
                      "&:hover": {
                        color: "black",
                      },
                    }}
                    key={user.user.id}
                    value={user.user}
                  >
                    <AvatarIcon
                      src={"data:image/jpeg;base64," + user.avatar_64}
                      firstName={user.user.first_name}
                      lastName={user.user.last_name}
                      style={{
                        marginRight: "10px",
                      }}
                    />
                    {user.user.first_name} {user.user.last_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Check-in"
              endText="Check-out"
              value={value}
              onChange={(e: any) => handleChangeDate(e)}
              renderInput={(startProps: any, endProps: any) => (
                <>
                  <TextField
                    value={formData.start_date}
                    {...startProps}
                    error={formDataErrors.start_date}
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField
                    value={formData.end_date ? formData.end_date : null}
                    {...endProps}
                    error={formDataErrors.end_date}
                  />
                </>
              )}
            />
          </LocalizationProvider>

          <TextField
            sx={{ mt: 1 }}
            size="small"
            id="demo-helper-text-aligned"
            label="Location"
            name="location"
            onChange={handleChange}
            value={formData.location}
            error={formDataErrors.location}
            required
          />
          <TextField
            sx={{ mt: 1 }}
            size="small"
            id="demo-helper-text-aligned"
            label="Reason of journey"
            name="reason"
            onChange={handleChange}
            error={formDataErrors.reason}
            value={formData.reason}
            required
          />
          <TextField
            sx={{ mt: 1 }}
            size="small"
            id="demo-helper-text-aligned"
            label="Additional info (e.g. Hotel preferences)"
            name="additional_info"
            onChange={handleChange}
            value={formData.additional_info}
            multiline
            rows={3}
            required
          />

          <Button
            style={classes.button}
            sx={{ mt: 1, mb: 5 }}
            variant="contained"
            endIcon={!loader && <SendIcon />}
            onClick={handleSubmit}
            disabled={loader}
          >
            SEND REQUEST
            {loader && (
              <CircularProgress
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "10px",
                  color: "white",
                }}
              />
            )}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default TravelRequest;
