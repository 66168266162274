// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import OverviewBackground from "../assets/img/OverviewBackground.png";
import Andrija from "../assets/HRAvatars/Andrija.png";
import Tanja from "../assets/HRAvatars/Tanja.png";
import JovanaTanovic from "../assets/HRAvatars/JovanaTanovic.png";
import JovanaMatejic from "../assets/HRAvatars/JovanaMatejic.png";
import Q from "../assets/img/Q.png";
import {useSelector } from "react-redux";
import { Box, Button, Link, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { useEffect, useState } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { UserClass } from "../classes/usersFactory";
import GroupsIcon from "@mui/icons-material/Groups";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import { useNavigate } from "react-router-dom";

let withWrapper: any;
let heightWrapper: any;
let mlWrapper: any;
export function ChangeDisplayClient(toogle: boolean) {
  if (toogle) {
    let win = getWindowDimensions();
    if (win.width > 1710) {
      withWrapper("87.6vw");
      mlWrapper(24);
    } else {
      withWrapper("83.68vw");
      mlWrapper(14);
    }
    heightWrapper("100%");
  } else {
    let win = getWindowDimensions();
    if (win.width > 1710) {
      withWrapper("102vw");
      mlWrapper(60);
    } else {
      withWrapper("102.5vw");
      mlWrapper(50);
    }

    heightWrapper("100%");
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const ClientHomePage = () => {
  const [employees, setEmployees] = useState<any>(0);
  const [teams, setTeams] = useState<any>(0);
  const [positions, setPositions] = useState<any>(0);
  const [widthChange, setWidthChange] = useState<any>("87.6vw");
  const [heightChange, setHeightChange] = useState<any>("100%");
  const [mlChange, setMLChange] = useState<any>(24);
  const [employeeList, setEmployeeList] = useState<UserClass[]>([]);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  let currentUser = useSelector((state: any) => state.reducer.user);
  let navbar = {};
  navbar = useSelector((state: any) => state);
  let url = currentUser.is_client ? `${APP_URL}/users/per-client/` : `${APP_URL}/users/profile/`;

  withWrapper = setWidthChange;
  heightWrapper = setHeightChange;
  mlWrapper = setMLChange;

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.is_client) {
      navigate("/main/overview");
      return;
    }
    apiService.get(url).then((resp) => {
      if (resp.length > 0) {
        setEmployees(resp.length);
      }
    });
    apiService.get(`${APP_URL}/users/teams/`).then((resp) => {
      setTeams(resp);
    });
    apiService.get(`${APP_URL}/users/position/`).then((resp) => {
      setPositions(resp);
    });
    apiService.get(`${APP_URL}/users/per-client/`).then((resp) => {
      setEmployeeList(resp.users);
    });
    setWindowDimensions(getWindowDimensions());
    if (windowDimensions.width <= 1710) {
      setWidthChange("83.68vw");
      mlWrapper(14);
    }
  }, []);

  return (
    <>
      {windowDimensions.width > 1710 ? (
        <Box
          sx={{
            background: `url(${OverviewBackground})`,
            backgroundPosition: "center",
            backgroundRepeat: "none",
            backgroundSize: "cover",
            width: widthChange,
            height: heightChange,
            mr: -10,
            mb: -10,
            ml: -10,
            mt: 5,
            display: "flex",
          }}
          style={{ boxShadow: "inset 0 0 100vw 100vh rgba(255, 255, 255, 0.63)" }}
        >
          <Box sx={{ mt: 7, ml: mlChange, display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
                <Typography
                  paragraph
                  style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#8F92A1" }}
                  sx={{ display: "flex" }}
                >
                  Hello {currentUser?.user?.first_name}, Welcome back
                </Typography>
                <Typography
                  paragraph
                  style={{ fontSize: "36px", fontWeight: 400, lineHeight: "54px" }}
                  sx={{ display: "flex" }}
                >
                  QcaaS Platform
                </Typography>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", height: "39.5vh" }}>
                    {/*Qcaas platform Calendar*/}
                    <Box
                      style={{
                        background: "#109CF1",
                        borderRadius: "10px",
                        textAlign: "left",
                        padding: 20,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        width: "40vw",
                        height: "21.5vh",
                      }}
                    >
                      <Typography
                        paragraph
                        style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                      >
                        QCAAS Platform
                      </Typography>
                      <Typography
                        paragraph
                        style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                      >
                        Qcaas platform unites all the procceses beetwen a contractor IT company and it’s clients,
                        sorting them into an organised system giving everyone the best user experience. If you want to
                        learn all the perks, tools, tips and tricks, click on the Get Started button.
                      </Typography>
                      <Button
                        size="large"
                        variant="outlined"
                        sx={{
                          float: "right",
                          backgroundColor: "white",
                          color: "4778EC",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "white",
                          },
                        }}
                      >
                        Get started
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ ml: 19 }}>
                {/* <CalendarComponent
                  style={{ borderRadius: "20px", boxShadow: "16px 16px 8px -7px #969696", fontSize: "30px" }}
                ></CalendarComponent> */}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mr: -10, mb: 9, mt: -15 }}>
              {/*Qcaas Website, Client Component*/}
              <Box
                sx={{
                  display: "flex",
                  background: "#8f9eb3",
                  borderRadius: "10px",
                  textAlign: "left",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "20vw",
                }}
              >
                {/*WEBSITE*/}
                <Link href="https://qcerris.com/" sx={{ textDecoration: "none" }}>
                  <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                      <img src={Q} />
                    </div>
                    <Typography
                      paragraph
                      style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                    >
                      QCerris website
                    </Typography>
                    <Typography
                      paragraph
                      style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                    >
                      We are an end-to-end full service software design and development shop. Beautiful and useful
                      interfaces. Reliable, responsive and secure back end. Those are the traits of the software we are
                      delivering to our customers. We grew and evolved together with our partners and we are committed
                      to stay your trusted guide as you refine and implement you vision.
                    </Typography>
                  </Box>
                </Link>
              </Box>
              <Box
                sx={{
                  ml: 10,
                  mt: 10,
                  height: "32.5vh",
                  background: "#ffffff",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "40vw",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/*Client Component*/}
                <Box>
                  {/*Title and cards*/}
                  <Box style={{ textAlign: "left", padding: 20 }}>
                    <Typography
                      paragraph
                      style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "black" }}
                    >
                      QCerris on {currentUser.client?.name}
                    </Typography>
                  </Box>
                  {/*Teams */}
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      sx={{
                        ml: 2,
                        border: "1px solid",
                        borderRadius: "4px",
                        background: "#3F3F3F",
                        boxSizing: "border-box",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        alignItems: "center",
                        width: "7vw",
                      }}
                    >
                      <GroupsIcon sx={{ fontSize: "64px", color: "#ffffff" }} />
                      <Typography
                        paragraph
                        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#FFFFFF" }}
                      >
                        Teams
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#FFFFFF" }}
                      >
                        {teams.length}
                      </Typography>
                    </Box>
                    {/*Employees */}
                    <Box
                      sx={{
                        ml: 2,
                        border: "1px solid #1DA7FF",
                        borderRadius: "4px",
                        background: "#1DA7FF",
                        boxSizing: "border-box",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        alignItems: "center",
                        width: "7vw",
                      }}
                    >
                      <PeopleIcon sx={{ fontSize: "64px", color: "#ffffff" }} />
                      <Typography
                        paragraph
                        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#ffffff" }}
                      >
                        Contractors
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#ffffff" }}
                      >
                        {employeeList.length}
                      </Typography>
                    </Box>
                    {/*Positions */}
                    <Box
                      sx={{
                        ml: 2,
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        background: "FFFFFF",
                        boxSizing: "border-box",
                        backgroundColor: "white",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        alignItems: "center",
                        width: "7vw",
                      }}
                    >
                      <DevicesOtherIcon sx={{ fontSize: "64px", color: "#000000" }} />
                      <Typography
                        paragraph
                        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#000000" }}
                      >
                        Positions
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#000000" }}
                      >
                        {positions.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ borderLeft: 1, color: "lightgray", ml: 2, height: "29vh", mt: 2 }}></Box>
                <Box sx={{ mt: 6, ml: 3 }}>
                  {/*TLs and QCHR*/}
                  <Box>
                    <Box sx={{ mb: 1, ml: -14 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#000000" }}>
                        Contractors
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        background: "#D9D9D9",
                        boxSizing: "border-box",
                        width: "10vw",
                        display: "flex",
                      }}
                    >
                      <AvatarGroup max={4} sx={{ mt: 1, mb: 1, ml: 2.5 }}>
                        {employeeList.map((emp: any) => (
                          <Avatar src={"data:image/jpeg;base64," + emp?.avatar_64} />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1, ml: -14 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#000000" }}>
                        Qcerris HR
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        background: "#D9D9D9",
                        boxSizing: "border-box",
                        width: "10vw",
                        display: "flex",
                      }}
                    >
                      <AvatarGroup max={4} sx={{ mt: 1, mb: 1, ml: 2.5 }}>
                        <Avatar src={Tanja} />
                        <Avatar src={JovanaTanovic} />
                        <Avatar src={Andrija} />
                        <Avatar src={JovanaMatejic} />
                      </AvatarGroup>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            background: `url(${OverviewBackground})`,
            backgroundPosition: "center",
            backgroundRepeat: "none",
            backgroundSize: "cover",
            width: widthChange,
            height: heightChange,
            mr: -10,
            mb: -10,
            ml: -10,
            mt: 4,
            display: "flex",
          }}
          style={{ boxShadow: "inset 0 0 100vw 100vh rgba(255, 255, 255, 0.63)" }}
        >
          <Box sx={{ mt: 7, ml: mlChange, display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
                <Typography
                  paragraph
                  style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#8F92A1" }}
                  sx={{ display: "flex" }}
                >
                  Hello {currentUser?.user?.first_name}, Welcome back
                </Typography>
                <Typography
                  paragraph
                  style={{ fontSize: "36px", fontWeight: 400, lineHeight: "54px" }}
                  sx={{ display: "flex" }}
                >
                  QcaaS Platform
                </Typography>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", height: "51.5vh" }}>
                    {/*Qcaas platform Calendar*/}
                    <Box
                      style={{
                        background: "#109CF1",
                        borderRadius: "10px",
                        textAlign: "left",
                        padding: 20,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        width: "40vw",
                        height: "26.5vh",
                      }}
                    >
                      <Typography
                        paragraph
                        style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                      >
                        QCAAS Platform
                      </Typography>
                      <Typography
                        paragraph
                        style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                      >
                        Qcaas platform unites all the procceses beetwen a contractor IT company and it’s clients,
                        sorting them into an organised system giving everyone the best user experience. If you want to
                        learn all the perks, tools, tips and tricks, click on the Get Started button.
                      </Typography>
                      <Button
                        size="large"
                        variant="outlined"
                        sx={{
                          float: "right",
                          backgroundColor: "white",
                          color: "4778EC",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "white",
                          },
                        }}
                      >
                        Get started
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ ml: 19 }}>
                {/* <CalendarComponent
                  style={{ borderRadius: "20px", boxShadow: "16px 16px 8px -7px #969696", fontSize: "30px" }}
                ></CalendarComponent> */}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mr: -10, mb: 10, mt: -15 }}>
              {/*Qcaas Website, Client Component*/}
              <Box
                sx={{
                  display: "flex",
                  background: "#8f9eb3",
                  borderRadius: "10px",
                  textAlign: "left",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "20vw",
                }}
              >
                {/*WEBSITE*/}
                <Link href="https://qcerris.com/" sx={{ textDecoration: "none" }}>
                  <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                      <img src={Q} />
                    </div>
                    <Typography
                      paragraph
                      style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                    >
                      QCerris website
                    </Typography>
                    <Typography
                      paragraph
                      style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                    >
                      We are an end-to-end full service software design and development shop. Beautiful and useful
                      interfaces. Reliable, responsive and secure back end. Those are the traits of the software we are
                      delivering to our customers. We grew and evolved together with our partners and we are committed
                      to stay your trusted guide as you refine and implement you vision.
                    </Typography>
                  </Box>
                </Link>
              </Box>
              <Box
                sx={{
                  ml: 10,
                  mt: 10,
                  height: "37.5vh",
                  background: "#ffffff",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "42vw",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/*Client Component*/}
                <Box>
                  {/*Title and cards*/}
                  <Box style={{ textAlign: "left", padding: 20 }}>
                    <Typography
                      paragraph
                      style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "black" }}
                    >
                      QCerris on {currentUser.client?.name}
                    </Typography>
                  </Box>
                  {/*Teams */}
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      sx={{
                        ml: 2,
                        border: "1px solid",
                        borderRadius: "4px",
                        background: "#3F3F3F",
                        boxSizing: "border-box",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        alignItems: "center",
                        width: "7vw",
                      }}
                    >
                      <GroupsIcon sx={{ fontSize: "64px", color: "#ffffff" }} />
                      <Typography
                        paragraph
                        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#FFFFFF" }}
                      >
                        Teams
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#FFFFFF" }}
                      >
                        {teams.length}
                      </Typography>
                    </Box>
                    {/*Employees */}
                    <Box
                      sx={{
                        ml: 2,
                        border: "1px solid #1DA7FF",
                        borderRadius: "4px",
                        background: "#1DA7FF",
                        boxSizing: "border-box",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        alignItems: "center",
                        width: "7vw",
                      }}
                    >
                      <PeopleIcon sx={{ fontSize: "64px", color: "#ffffff" }} />
                      <Typography
                        paragraph
                        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#ffffff" }}
                      >
                        Employees
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#ffffff" }}
                      >
                        {employeeList.length}
                      </Typography>
                    </Box>
                    {/*Positions */}
                    <Box
                      sx={{
                        ml: 2,
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        background: "FFFFFF",
                        boxSizing: "border-box",
                        backgroundColor: "white",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        alignItems: "center",
                        width: "7vw",
                      }}
                    >
                      <DevicesOtherIcon sx={{ fontSize: "64px", color: "#000000" }} />
                      <Typography
                        paragraph
                        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#000000" }}
                      >
                        Positions
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#000000" }}
                      >
                        {positions.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ borderLeft: 1, color: "lightgray", ml: 2, mr: 2, height: "29vh", mt: 2 }}></Box>
                <Box sx={{ mt: 6, ml: 3 }}>
                  {/*TLs and QCHR*/}
                  <Box>
                    <Box sx={{ mb: 1, ml: -14 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#000000" }}>
                        Contractors
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        background: "#D9D9D9",
                        boxSizing: "border-box",
                        width: "12vw",
                        display: "flex",
                      }}
                    >
                      <AvatarGroup max={4} sx={{ mt: 1, mb: 1, ml: 2.5 }}>
                        {employeeList.map((emp: any) => (
                          <Avatar src={"data:image/jpeg;base64," + emp?.avatar_64} />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1, ml: -14 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#000000" }}>
                        Qcerris HR
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        background: "#D9D9D9",
                        boxSizing: "border-box",
                        width: "12vw",
                        display: "flex",
                      }}
                    >
                      <AvatarGroup max={4} sx={{ mt: 1, mb: 1, ml: 2.5 }}>
                        <Avatar src={Tanja} />
                        <Avatar src={JovanaTanovic} />
                        <Avatar src={Andrija} />
                        <Avatar src={JovanaMatejic} />
                      </AvatarGroup>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
