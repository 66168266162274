import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import { getCandidateList } from "../../classes/candidate";
import { getPositionList } from "../../classes/job_position";
import moment from "moment";
import { Link } from "react-router-dom";
import { decodeToken } from "react-jwt";



var jwt = require("react-jwt");

export default function AllCandidatesList({ setId }: any) {
  const [candidateList, setCandidateList] = useState<any>([]);
  const [candList, setCandList] = useState<any>([]);
  const [positions, setPositions] = useState<any>([]);
  const [user, setUser] = useState<any>("");
  const [loader, setLoader] = useState(true);

  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;

  useEffect(() => {
    apiService.get(`${APP_URL}/users/profile/${userId}/`).then((resp: any) => {
      setUser(resp);
    });
  }, []);

  useEffect(() => {
    if (user) {
      if (user.is_hr || user.is_super_hr) {
        getCandidateList().then((data) => {
          setCandidateList(data);
          setCandList(data.sort((a,b) =>{
              return moment(b.created_at).valueOf() - moment(a.created_at).valueOf();
          }));
          setLoader(false);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    getPositionList().then((data: any) => {
      setPositions(data);
    });
  }, []);
  
  if (positions) {
    var positionList: string[] = positions.map((p: any) => p.name);
    const removeFilters: string = "No filters";
    positionList.unshift(removeFilters);
  }
  const changePosition = (e: any) => {
    if (e.target.value === "No filters") {
      setCandList(candidateList);
    } else {
      let candListHelper = candidateList.filter(
        (cand: any) => e.target.value === cand.job_position.name
      );
      setCandList(candListHelper);
    }
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Container>
      <Box>
        <Box mt={15}>
          <FormControl sx={{ width: "20%" }}>
            <InputLabel id="demo-simple-select-label">
              Sort by position
            </InputLabel>
            <Select
              native
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Sort by project"
              onChange={(e) => changePosition(e)}
            >
              {positionList.map((pos: any) => (
                <option>{pos}</option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Table style={{marginTop: "20px", background: "#ffffff"}}>
        <TableHead style={{ whiteSpace: "nowrap" }}>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell>POSITION</TableCell>
            <TableCell>EMAIL</TableCell>
            <TableCell>SENIORITY</TableCell>
            <TableCell>DATE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ whiteSpace: "nowrap" }}>
          {candList.length > 0 &&
            candList.map((cand: any) => (
              <TableRow>
                <TableCell>
                <Link to={{
                    pathname: `/main/candidate-details/${cand.id}`,
                  }} style={{textDecoration: "none"}}>
                  <Button style={{color: "#323C47"}}>
                    <b>{cand.first_name + " " + cand.last_name}</b>
                  </Button>
                </Link>
                </TableCell>
                <TableCell>{cand.job_position.name}</TableCell>
                <TableCell>
                    {cand.email}
                </TableCell>
                <TableCell>
                    {cand.seniority.name}
                </TableCell>
                <TableCell>
                    {moment(cand.created_at).format("MMM Do YY")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Container>
  );
}
