import { Box, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import _pick from "lodash/pick";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const EditDocument = () => {
  const params = useParams<{ documentId: string }>();

  const [documentName, setDocumentName] = useState("");
  const [documentContent, setDocumentContent] = useState("");
  const [document, setDocument] = useState<any>([]);
  let navigate = useNavigate();
  let currentUser = useSelector((state: any) => state.reducer.user);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchDocument = () => {
      apiService
        .get(`${APP_URL}/processes/documents/${params.documentId}/`)
        .then((doc) => {
          const initData = _pick(doc, Object.keys(doc));
          setDocumentName(initData.name);
          setDocumentContent(initData.body);
          let newDate = new Date(initData.created_at);

          initData.created_at = `${newDate.getDate()}/${
            newDate.getMonth() + 1
          }/${newDate.getFullYear()}`;

          setDocument(initData);
          setLoader(false);
        });
    };
    fetchDocument();
  }, [currentUser, navigate, params.documentId]);

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Box mt={10} sx={{ backgroundColor: "white" }}>
      <Box>
        <Box>
          <Typography variant="h2">{documentName}</Typography>
          <Typography
            fontSize={12}
            marginTop={"10x"}
            marginLeft={"5px"}
            color="#8A8A8A"
          >
            Created at {document.created_at} by {document.created_by.first_name}{" "}
            {document.created_by.last_name}
          </Typography>
        </Box>
      </Box>

      <Box
        marginTop={"70px"}
        marginLeft={"150px"}
        marginRight={"150px"}
        textAlign={"left"}
        dangerouslySetInnerHTML={{ __html: documentContent }}
      ></Box>
    </Box>
  );
};
export default EditDocument;
