import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import DownloadIcon from "../assets/img/DownloadIcon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Modal from "@mui/material/Modal";
import { saveAs } from "file-saver";
import { AvatarIcon } from "./Avatar";
import ViewerPdf from "./PDFViewer/ViewerPdf";

const PerformanceReviewHistory = ({ lastReview }: any) => {
  const [state, setState] = useState<any>(null);
  const [openedDocument, setOpenedDocument] = useState<any>();

  const openModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/pdf2/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

    setState(id);
  };

  const getNameOfDocument = (path: string) => {
    let splitted = path.split("/");
    let return_string: string = splitted[splitted.length - 1];
    return return_string;
  };

  const downloadFile = (id: any) => {
    const docName = getNameOfDocument(`${id}`);
    apiService
      .getFile(`${APP_URL}/performance-review/pdf2/${id}/`)
      .then((resp) => {
        saveAs(resp, `Form ${docName}`);
      });
  };

  return (
    <Box sx={{ minWidth: 950 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name of PR</TableCell>
              <TableCell align="center">Requested by</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Preview</TableCell>
              <TableCell align="center">Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lastReview?.map((item: any) => (
              <>
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    {/* {item?.title ? item?.title : "Blank title"} */}
                    {item?.deny_reason ? `Deny reason: ${item?.deny_reason}` : `Performance review for ${item.employee.first_name} ${item.employee.last_name}` }
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AvatarIcon
                        src={
                          "data:image/jpeg;base64," + item?.requester?.avatar_64
                        }
                        firstName={item?.requester?.first_name}
                        lastName={item?.requester?.last_name}
                      />
                      <Box ml={2}>
                        {item?.requester?.first_name}{" "}
                        {item?.requester?.last_name}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {item?.end_date
                      ? moment(item?.end_date).format("MMM Do YY")
                      : "Blank date"}
                  </TableCell>

                  <TableCell align="center">
                    <Button onClick={() => openModal(item?.id)}>
                      <VisibilityIcon style={{ color: "black" }} />
                    </Button>
                    {openedDocument && (
                      <Modal
                        open={state}
                        onClose={() => setState(null)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                          marginTop: 5,
                          height: 680,
                          width: 680,
                          left: "30%",
                        }}
                      >
                        <ViewerPdf file={`${openedDocument}`} />
                      </Modal>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => downloadFile(item?.id)}>
                      <img src={DownloadIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PerformanceReviewHistory;
