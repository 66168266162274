import React from "react";
import { Box, Card, CardContent, Fade, Tooltip, Typography } from "@mui/material";
import { AvatarIcon } from "../../../components/Avatar";
import { useSelector } from "react-redux";

const UserProfileCard = ({ userProfile, teamSelected }: any) => {
  let currentUser = useSelector((state: any) => state.reducer.user);

  return (
    <Card
      sx={{
        width: 221,
        height: 305,
        ":hover": {
          boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
        },
        borderRadius: 3,
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" pt={1}>
        <AvatarIcon
          src={"data:image/jpeg;base64," + userProfile?.avatar_64}
          firstName={userProfile?.user?.first_name}
          lastName={userProfile?.user?.last_name}
          style={{
            height: "120px",
            width: "120px",
            fontSize: "48px",
            marginTop: "10px",
          }}
        />
      </Box>

      <CardContent>
        <Typography gutterBottom align="center" fontSize={16} fontWeight={500}>
          {userProfile?.user?.first_name + " " + userProfile?.user?.last_name}
        </Typography>
        <Typography gutterBottom fontSize={14} fontWeight={400} color="text.secondary">
          {userProfile?.position?.name}
        </Typography>
        <Typography gutterBottom fontSize={14} fontWeight={400} color="text.secondary">
          {userProfile?.technologies?.[0]?.name}
        </Typography>
        <Typography gutterBottom fontSize={14} fontWeight={400} color="text.secondary">
          {userProfile?.project?.[0]?.name}
        </Typography>
        <Tooltip
          arrow
          title={
            userProfile?.team?.length > 1 ? (
              <span>
                {userProfile?.team?.map((team: any, index: number) => (
                  <p key={index}>{team.name}</p>
                ))}
              </span>
            ) : (
              ""
            )
          }
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <Typography gutterBottom fontSize={14} fontWeight={400} color="text.secondary">
            {teamSelected
              ? teamSelected + (userProfile?.team?.length > 1 ? " +" + (userProfile?.team?.length - 1) : "")
              : userProfile?.team?.[0]
              ? userProfile?.team?.[0]?.name +
                (userProfile?.team?.length > 1 ? " +" + (userProfile?.team?.length - 1) : "")
              : ""}
          </Typography>
        </Tooltip>
        {(currentUser.is_hr || currentUser.is_super_hr || currentUser.is_regular_user) && (
          <Typography gutterBottom fontSize={14} fontWeight={400} color="text.secondary">
            {userProfile?.client?.name}
          </Typography>
        )}
        {(currentUser.is_hr || currentUser.is_super_hr || currentUser.is_regular_user) && (
          <Typography gutterBottom fontSize={14} fontWeight={400} color="text.secondary">
            {userProfile?.seniority?.name}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default UserProfileCard;
