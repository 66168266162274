import { ThemeProvider, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService";
// import PerformanseReview from "../components/PerformanseReview";
import ClientPage from "../pages/ClientPage";
import InactiveEmployeeList from "../pages/InactiveEmployeeList";
import { CompanyOverview } from "../components/CompanyOverview";
import { ClientHomePage } from "../components/ClientHomePage";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import TravelRequestTabs from "../components/TravelRequestTabs";
import TravelRequest from "../components/TravelRequest";
import TravelRequestList from "../components/TravelRequestHistory";
import { useNavigate, useLocation } from "react-router";
import EmployeeProfile from "../pages/EmployeeProfile";
import TravelRequestDetail from "../components/TravelRequestDetail";
import Cookies from "js-cookie";

import homeIcon from "../assets/img/HomeIcon.svg";
import homeIconBlue from "../assets/img/HomeIconBlue.svg";
import generalInfoIcon from "../assets/img/GeneralInfoIcon.svg";
import generalInfoIconBlue from "../assets/img/GeneralInfoIconBlue.svg";
import employeeListIcon from "../assets/img/EmployeeListIcon.svg";
import employeeListIconBlue from "../assets/img/EmployeeListIconBlue.svg";
import performanceReviewIcon from "../assets/img/PerformanceReviewIcon.png";
import performanceReviewIconBlue from "../assets/img/PerformanceReviewIconBlue.svg";
import travelRequestIcon from "../assets/img/TravelRequestIcon.png";
import LastPerformanseReview from "../components/LastPerformanseReview";
import circleRed from "../assets/img/CircleRed.svg";
import circleBlue from "../assets/img/CircleBlue.svg";
import circlePurple from "../assets/img/CirclePurple.svg";
import GeneralInfoTabs from "../components/GeneralInfoTabs";
import CircularProgress from "@mui/material/CircularProgress";
import PerformanceReviewQuestion from "./PerformanceReviewQuestions/PerformanceReviewQuestions";
import PerformanceReviewRequest from "../components/PerformanceReviewRequest";
import PerformanceReviewRequestDetail from "../components/PerformanceReviewRequestDetail";
import PerformanceReviewList from "../components/PerformanceReviewList";
import AllEmployeesList from "../components/HRDashboard/ListEmployees";

import { useDispatch, useSelector } from "react-redux";
import { travelRequestId } from "../store/actions";
import PerformanceReviewHistoryList from "../components/PerformanceReviewHistoryList";
import CandidatesDetail from "./CandidatesDetail";
import RecruitmentLanding from "./RecruitmentLanding";
import ContainerDnd from "../components/DragAndDrop/ContainerDnd";
import ListCandidates from "../components/HRDashboard/ListCandidates";
import { decodeToken } from "react-jwt";

var jwt = require("react-jwt");

const classes = {
  user: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "150px",
  },
  userInfo: {
    fontSize: "20px",
  },
  drawerPaper: {
    background: "#ffffff",
  },
};

const Dashboard: React.FC = () => {
  const [tab, setTab] = useState("overview");
  const [openTravel, setOpenTravel] = useState(false);
  const [openPerformanceReview, setOpenPerformanceReview] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ pageName: string }>();
  const [employeeId, setEmployeeId] = useState();
  const [isRegularUser, setIsRegularUser] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState<any>();
  const [travelId, setTravelId] = useState();
  const [perfId, setPerfId] = useState();
  const [openRecruitment, setOpenRecruitment] = useState(false);

  let jwt = require("react-jwt");
  let cookie = Cookies.get("myCookie");
  // let userId: number = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;
  const dispatch = useDispatch();
  let currentUser = useSelector((state: any) => state.reducer.user);

  useEffect(() => {
    setIsRegularUser(currentUser?.is_regular_user);
    setIsLoading(false);
  }, []);

  const handleTravelId = (id: any) => {
    //setTravelId(id);
    dispatch(travelRequestId(id));
    navigate(`travel-request-detail`);
  };

  const handlePerformanceReviewId = (id: any) => {
    setPerfId(id);
    navigate(`/performance-review-request-detail/${perfId}`);
  };

  const setId = (id: any) => {
    setEmployeeId(id);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress style={{ marginTop: "15rem" }} />
      ) : (
        <Container component="main" maxWidth="lg">
          {params.pageName === "overview" && (
            <main>
              <div />
              <Container maxWidth="lg">
                {currentUser.is_client ? (
                  <ClientHomePage />
                ) : (
                  <CompanyOverview />
                )}
              </Container>
            </main>
          )}
          {params.pageName === "employee-list" && (
            <Container>
              <ClientPage setId={setId} />
            </Container>
          )}
          {params.pageName === "inactive-employee-list" && (
            <Container>
              <InactiveEmployeeList setId={setId} />
            </Container>
          )}
          {params.pageName === "travel-request" && (
            <Container maxWidth="md">
              <TravelRequest />
            </Container>
          )}
          {params.pageName === "travel-request-history" && (
            <Container>
              <TravelRequestList handleTravelId={handleTravelId} />
            </Container>
          )}
          {params.pageName === "travel-request-detail" && (
            <Container>
              <TravelRequestDetail travelId={travelId} />
            </Container>
          )}
          {/* {params.pageName === "performance-review-request-detail" && (
            <Container style={{ minWidth: "1300px" }}>
              <PerformanceReviewRequestDetail perfId={perfId} />
            </Container>
          )} */}
          {params.pageName === "performance-review-request" && (
            <Container maxWidth="md" style={{ marginTop: "100px" }}>
              <PerformanceReviewRequest />
            </Container>
          )}
          {params.pageName === "performance-review-history" && (
            <Container style={{ marginTop: "100px" }}>
              <PerformanceReviewHistoryList />
            </Container>
          )}

          {params.pageName === "candidates" && (
            <Container style={{ marginTop: "70px" }}>
              <ListCandidates />
            </Container>
          )}
        </Container>
      )}
    </>
  );
};

export default Dashboard;
