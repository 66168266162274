// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import OverviewBackground from "../assets/img/OverviewBackground.png";
import Q from "../assets/img/Q.png";
import Slack from "../assets/img/slack.png";
import WikiIcon from "../assets/img/wiki_icon.png";
import { useSelector } from "react-redux";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { useEffect, useState } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { useNavigate} from "react-router-dom";

let withWrapper: any;
let heightWrapper: any;
let mlWrapper: any;
export function ChangeDisplay(toogle: boolean) {
  if (toogle) {
    let win = getWindowDimensions();
    if (win.width > 1710) {
      withWrapper("87.6vw");
      mlWrapper(24);
    } else {
      withWrapper("83.68vw");
      mlWrapper(14);
    }
    heightWrapper("100%");
  } else {
    let win = getWindowDimensions();
    if (win.width > 1710) {
      withWrapper("102.9vw");
      mlWrapper(60);
    } else {
      withWrapper("102.5vw");
      mlWrapper(50);
    }

    heightWrapper("100%");
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const CompanyOverview = () => {
  const [employees, setEmployees] = useState<any>(0);
  const [widthChange, setWidthChange] = useState<any>("87.6vw");
  const [heightChange, setHeightChange] = useState<any>("100%");
  const [mlChange, setMLChange] = useState<any>(24);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const navigate = useNavigate();

  let currentUser = useSelector((state: any) => state.reducer.user);
  let navbar = {};
  navbar = useSelector((state: any) => state);
  let url = currentUser.is_client ? `${APP_URL}/users/per-client/` : `${APP_URL}/users/profile/`;

  withWrapper = setWidthChange;
  heightWrapper = setHeightChange;
  mlWrapper = setMLChange;

  useEffect(() => {
    if (currentUser.is_client) {
      navigate("/main/client/overview");
      return;
    }
    apiService.get(url).then((resp) => setEmployees(resp.length));
    setWindowDimensions(getWindowDimensions());
    if (windowDimensions.width <= 1710) {
      setWidthChange("83.68vw");
      setMLChange(14);
    }
  }, []);

  return (
    <Box
    style={{
      background: `url(${OverviewBackground})`,
      backgroundPosition: "center",
      backgroundRepeat: "none",
      backgroundSize: "cover",
      width: "100%",
      height: "100vh",
      boxShadow: "inset 0 0 100vw 100vh rgba(255, 255, 255, 0.63)",
      display: "flex",
      flexDirection: "column",
    }}
    >
   <Box sx={{display: "flex", justifyContent:"center" }}>

      <Box sx={{p: 2, width: "40vw", minHeight:"500px",display: "flex",flexDirection: "column"}}>
          <Box sx={{textAlign: "left", mt:10}}>
          <Typography
            paragraph
            style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#8F92A1" }}
            sx={{ display: "flex" }}
          >
            Hello {currentUser?.user?.first_name}, Welcome back
          </Typography>
          <Typography
            paragraph
            style={{ fontSize: "36px", fontWeight: 400, lineHeight: "54px" }}
            sx={{ display: "flex" }}
          >
            QcaaS Platform
          </Typography>
          </Box>

          <Box
                style={{
                  background: "#109CF1",
                  borderRadius: "10px",
                  textAlign: "left",
                  padding: 20,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Typography
                  paragraph
                  style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                >
                  QCAAS Platform
                </Typography>
                <Typography
                  paragraph
                  style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                >
                  Qcaas platform unites all the procceses beetwen a contractor IT company and it’s clients, sorting
                  them into an organised system giving everyone the best user experience. If you want to learn all the
                  perks, tools, tips and tricks, click on the Get Started button.
                </Typography>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{
                    float: "right",
                    backgroundColor: "white",
                    color: "4778EC",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  Get started
                </Button>
          </Box>

          <Box sx={{mt: 3,
                  display: "flex",}}>
              {/*WEBSITE, WIKI, SLACK*/}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "10px",
                  textAlign: "left",
                  bgcolor: "#8f9eb3",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                {/*WEBSITE*/}
                <Link href="https://qcerris.com/" sx={{ textDecoration: "none" }}>
                  <Box sx={{ px: 2,  display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                      <img src={Q} />
                    </div>
                    <Typography
                      paragraph
                      style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                    >
                      QCerris website
                    </Typography>
                    <Typography
                      paragraph
                      style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                    >
                      We are an end-to-end full service software design and development shop. Beautiful and useful
                      interfaces. Reliable, responsive and secure back end. Those are the traits of the software we
                      are delivering to our customers. We grew and evolved together with our partners and we are
                      committed to stay your trusted guide as you refine and implement you vision.
                    </Typography>
                  </Box>
                </Link>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", ml: 10 }}>
                {/*WIKI, SLACK*/}

                <Link href="https://wiki.qcerris.com/" target="blank" sx={{ textDecoration: "none" }}>
                  <Box
                    style={{
                      width: "300px",
                      background: "#eb505b",
                      borderRadius: "10px",
                      textAlign: "left",
                      padding: 20,
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>
                      <img src={WikiIcon} />
                    </div>
                    <Typography
                      paragraph
                      style={{ fontSize: "22px", fontWeight: 500, lineHeight: "29px", color: "white" }}
                    >
                      QCerris Wiki
                    </Typography>
                    <Typography
                      paragraph
                      style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                    >
                      Want to find all our company info, HR procedures and our knowledge sharing base? Check out our
                      Wiki page.
                    </Typography>
                  </Box>
                </Link>
                <Link
                  href="https://qcerris.slack.com/"
                  target="blank"
                  sx={{ textDecoration: "none", marginTop: 2.5 }}
                >
                  <Box
                    style={{
                      marginTop: "30px",
                      width: "300px",
                      background: "#59a2db",
                      borderRadius: "10px",
                      textAlign: "left",
                      padding: 20,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
                      <img src={Slack} />
                    </div>
                    <Typography
                      paragraph
                      style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", color: "white" }}
                    >
                      Join QCerris workspace on Slack and start chating with your colleagues.
                    </Typography>
                  </Box>
                </Link>
              </Box>
          </Box>


      </Box>

      <Box sx={{p:2}}>
            {currentUser.is_super_hr  || currentUser.is_hr ? (
            <Box
              sx={{
                ml: 10,
                mt: 30,
                mb: 6,
                border: "1px solid #E5E5E5",
                borderRadius: "4px",
                boxSizing: "border-box",
                py: 3,
                px: 8,
                bgcolor: "white",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                alignItems: "center",
              }}
            >
              <PeopleIcon sx={{ fontSize: "64px", color: "9E9E9E" }} />
              <Typography
                variant="h5"
                style={{ fontSize: "32px", fontWeight: 400, lineHeight: "42px", color: "#9E9E9E" }}
              >
                {employees}
              </Typography>
              <Typography
                paragraph
                style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "#9E9E9E" }}
              >
                employees
              </Typography>
            </Box>
            ) : null
            }
            <Box sx={{ ml: 10}}>
              {/* <CalendarComponent
                style={{ borderRadius: "20px", boxShadow: "16px 16px 8px -7px #969696", fontSize: "30px" }}
              ></CalendarComponent> */}
            </Box>
          </Box>
   </Box>
    </Box>
  )
}
