import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ButtonGroup from "@mui/material/ButtonGroup";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Dashboard from "../pages/Dashboard";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ViewerPdf from "./PDFViewer/ViewerPdf";
import Swal from "sweetalert2";
import { format } from "date-fns/esm";

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  patchIndexOrdering(result);

  return result;
};

const patchIndexOrdering = (list: any) => {
  list.map((item: any, index: number) => {
    var helper: number = 100 + index + 1;
    let patchData = { forms: [{ id: item.id, ordering_index: helper }] };
    apiService.patch(
      `${APP_URL}/performance-review/question-form-patch/`,
      patchData
    );
  });
};

function PerformanceReviewEdit() {
  const [state, setState] = useState<any>([]);
  const [stateEdit, setStateEdit] = useState<any>();
  const [changedQuestion, setChangedQuestion] = useState<any>("");
  const [trigerChanges, setTrigerChanges] = useState<boolean>(false);
  const [loader, setLoader] = useState(true);
  const [title, setTitle] = useState<string>();
  const [quest, setQuest] = useState<string>();
  const [isText, setIsText] = useState<boolean>(true);
  const param1 = useParams<{ employeeId: string }>();
  const param2 = useParams<{ formId: string }>();
  const param3 = useParams<{ form: string }>();
  const [userForm, setUserForm] = useState<any>();
  const [openedDocument, setOpenedDocument] = useState<any>();
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);


  const onClickSaveForAll = () => {
    let patchForm = { id: param2.formId };
    var listHelper: any = [];
    state.map((item: any) => {
      let patchQuestion = {
        id: item.questionID,
        title: item.primary,
        question: item.secondary,
        is_text: item.is_text,
        is_hr_question: userForm.is_hr,
        is_employee_question: userForm.is_regular_user,
        is_client_question: userForm.is_client,
        is_team_lead_question: userForm.is_team_lead,
      };
      listHelper.push(patchQuestion);
    });

    apiService
      .patch(`${APP_URL}/performance-review/question-patch/`, listHelper)
      .then(() => {
        apiService
          .patch(`${APP_URL}/performance-review/form-save-template/`, patchForm)
          .then(() => {
            setTrigerChanges(false);
            Toast.fire({
              icon: "success",
              title: "Successfully saved!",
              background: "#a5dc86",
            });
          })
          .catch(() => {
            Toast.fire({
              icon: "error",
              title: "Error!",
              background: "#f27474",
            });
          });
      });
  };

  const onClickOpenPreviewModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/pr-form/pdf/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      })
      .then(() => setOpenPreviewModal(true));
  };

  const onClickADD = () => {
    setLoader(true);
    apiService
      .post(`${APP_URL}/performance-review/questions/`, {
        title: title,
        question: quest,
        is_text: isText,
        is_hr_question: false,
        is_employee_question: false,
        is_client_question: false,
        is_team_lead_question: false,
      })
      .then(async () => {
        const result = await apiService.get(
          `${APP_URL}/performance-review/questions/`
        );
        var lastId = result[result.length - 1].id;
        let patchData = {
          id: param2.formId,
          add_questions: [lastId],
        };
        apiService
          .patch(`${APP_URL}/performance-review/pr-form/`, patchData)
          .then(() => setQuestionForms())
          .then(() => setTrigerChanges(true))
          .then(() => setOpenAddModal(false));
      });
  };

  const setQuestionForms = () => {
    apiService
      .get(`${APP_URL}/performance-review/prf-questions/${param1.employeeId}/`)
      .then((resp) => {
        var list: any = [];
        resp.question_form[param3.form].map((item: any) => {
          var helper = {
            id: "",
            questionID: "",
            primary: "",
            orderingIndex: "",
            secondary: "",
            isText: "",
          };
          helper.id = `${item.id}`;
          helper.primary = item.question.title;
          helper.questionID = item.question.id;
          helper.secondary = item.question.question;
          helper.orderingIndex = `${item.ordering_index}`;
          helper.isText = item.question.is_text;
          list.push(helper);
        });
        setState(list);
        setLoader(false);
      });
  };

  useEffect(() => {
    apiService
      .get(`${APP_URL}/performance-review/prf-questions/${param1.employeeId}/`)
      .then((resp) => {
        const fetchData = async () => {
          var list: any = [];
          var userID = resp.question_form[param3.form][0].user.id;
          apiService.get(`${APP_URL}/users/profile/${userID}/`).then((resp) => {
            setUserForm(resp);
          });
          resp.question_form[param3.form].map((item: any) => {
            var helper = {
              id: "",
              primary: "",
              secondary: "",
              questionID: "",
              isText: "",
            };
            helper.id = `${item.id}`;
            helper.primary = item.question.title;
            helper.questionID = item.question.id;
            helper.secondary = item.question.question;
            helper.isText = item.question.is_text;
            list.push(helper);
          });
          setState(list);
          patchIndexOrdering(list);
          setLoader(false);
        };

        fetchData();
      });
  }, []);

  const typeOfQuestion = (type: any) => {
    if (type === "R") {
      setIsText(false);
    } else {
      setIsText(true);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems = reorder(
      state,
      result.source.index,
      result.destination.index
    );

    setTrigerChanges(true);

    setState(newItems);
  };

  const onRemoveClick = (id: any) => {
    setLoader(true);
    let patchData = {
      id: param2.formId,
      remove_question_forms: [id],
    };
    apiService
      .patch(`${APP_URL}/performance-review/form/`, patchData)
      .then(() => {
        setQuestionForms();
        setTrigerChanges(true);
      });
  };

  const onClickConfirmEdit = (
    id: any,
    question: any,
    title: any,
    isText: any
  ) => {
    let patchData = {
      id: id,
      question: question,
      title: title,
      isText: isText,
    };
    apiService
      .patch(
        `${APP_URL}/performance-review/question-form-edit-question/`,
        patchData
      )
      .then(() => setQuestionForms())
      .then(() => setStateEdit(""))
      .then(() => setTrigerChanges(true));
  };

  return (
    <div>
      <Dashboard />
      <h2
        style={{
          fontSize: "20px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 8,
        }}
      >
        Performance Review Questions
      </h2>
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          marginTop: 15,
          width: 1200,
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Performance Review Questions
        </h2>

        {loader ? (
          <CircularProgress style={{ marginTop: "20%" }} />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              paddingTop: "5vh",
            }}
          >
            <DragDropContext
              onDragEnd={(result: DropResult) => onDragEnd(result)}
            >
              <div
                style={{
                  margin: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Droppable droppableId="prf-questions">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {state &&
                        state.length > 0 &&
                        state.map((item: any, index: any) => {
                          return (
                            <Draggable
                              key={item.id}
                              index={index}
                              draggableId={
                                item.id +
                                "-" +
                                item.primary +
                                "-" +
                                item.secondary
                              }
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    padding: 0,
                                    margin: "0 0 8px 0",
                                    minHeight: "70px",
                                    width: "100%",
                                    display: "block",
                                    borderRadius: "10px",
                                    background: "#FFFFFF",
                                    boxShadow:
                                      "0px 14px 14px rgba(0, 0, 0, 0.25)",
                                    marginBottom: 13,
                                    backgroundColor: snapshot.isDragging
                                      ? "grey"
                                      : "white",
                                    color: snapshot.isDragging
                                      ? "white"
                                      : "black",
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <h4
                                    style={{
                                      float: "right",
                                      fontSize: "12px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      margin: 5,
                                    }}
                                  >
                                    <Button
                                      color="inherit"
                                      title="Edit question"
                                      onClick={() => {
                                        setChangedQuestion("");
                                        setStateEdit(item.id);
                                      }}
                                    >
                                      <EditIcon />
                                    </Button>
                                    <Button
                                      color="inherit"
                                      title="Remove question"
                                      onClick={() => onRemoveClick(item.id)}
                                    >
                                      <RemoveCircleIcon />
                                    </Button>
                                  </h4>
                                  <h4
                                    style={{
                                      float: "left",
                                      fontSize: "12px",
                                      margin: 5,
                                    }}
                                  >
                                    {item.isText === false && (
                                      <CircleOutlinedIcon />
                                    )}
                                  </h4>

                                  <br />
                                  <p style={{ fontSize: "18px" }}>
                                    {stateEdit !== item.id ? (
                                      <b>{item.secondary}</b>
                                    ) : (
                                      <div>
                                        <TextField
                                          id="standard-basic"
                                          variant="outlined"
                                          style={{
                                            outline: "none",
                                            color: "inherit",
                                            width: "auto",
                                            minWidth: 500,
                                          }}
                                          defaultValue={item.secondary}
                                          onChange={(e) =>
                                            setChangedQuestion(e.target.value)
                                          }
                                        />
                                        <ButtonGroup
                                          variant="text"
                                          aria-label="text button group"
                                        >
                                          <Button
                                            onClick={() => {
                                              onClickConfirmEdit(
                                                item.id,
                                                changedQuestion,
                                                item.primary,
                                                item.isText
                                              );
                                            }}
                                            disabled={
                                              changedQuestion
                                                ? changedQuestion ===
                                                  item.secondary
                                                  ? true
                                                  : false
                                                : true
                                            }
                                            color="inherit"
                                          >
                                            <CheckIcon />
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              setStateEdit("");
                                              setChangedQuestion(
                                                item.secondary
                                              );
                                            }}
                                            color="inherit"
                                          >
                                            <CloseIcon />
                                          </Button>
                                        </ButtonGroup>
                                      </div>
                                    )}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: 5,
                                    }}
                                  >
                                    <b>Title: {item.primary}</b>
                                  </p>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
            <List component={Stack} direction="column">
              <ListItem>
                <Button
                  sx={{ maxHeight: 70, maxWidth: 150 }}
                  startIcon={<SaveIcon />}
                  onClick={() => onClickSaveForAll()}
                  disabled={!trigerChanges}
                  variant="contained"
                  color="inherit"
                >
                  Save for all forms
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  variant="contained"
                  color="inherit"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddModal(true)}
                  sx={{ maxHeight: 70, maxWidth: 150 }}
                >
                  Add new question
                </Button>
                <Modal
                  open={openAddModal}
                  onClose={() => setOpenAddModal(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography align="left" variant="h6">
                      Title:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="title"
                      onChange={(e: any) => setTitle(e.target.value)}
                      fullWidth
                    />
                    <Typography align="left" variant="h6">
                      Question:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="question"
                      onChange={(e: any) => setQuest(e.target.value)}
                      fullWidth
                    />
                    <Typography variant="h6" align="left">
                      Type of question:
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label-role"
                      id="demo-simple-select-role"
                      name="role"
                      defaultValue={"T"}
                      onChange={(e) => typeOfQuestion(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value={"T"}>Text Question</MenuItem>
                      <MenuItem value={"R"}>Rating Question</MenuItem>
                    </Select>
                    <div>
                      <Button variant="contained" onClick={onClickADD}>
                        Add question
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </ListItem>
              <ListItem>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onClickOpenPreviewModal(param2.formId)}
                  startIcon={<VisibilityIcon />}
                  sx={{ maxHeight: 70, maxWidth: 150 }}
                >
                  Preview Form
                </Button>
                <Modal
                  open={openPreviewModal}
                  onClose={() => setOpenPreviewModal(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{
                    marginTop: 5,
                    height: 680,
                    width: 680,
                    left: "30%",
                  }}
                >
                  <ViewerPdf file={`${openedDocument}`} />
                </Modal>
              </ListItem>
            </List>
          </div>
        )}
      </Container>
    </div>
  );
}

export default PerformanceReviewEdit;
