import Send from "@mui/icons-material/Send";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});

const InvitationLetter = () => {
  const handleClick = (e: any) => {
    console.log("Dzoniza");
  };

  return (
    <Container maxWidth="md">
      {/* BOX 1 */}
      <Box mt={5}>
        <Typography mt={15} variant="h4">
          Request approved!
        </Typography>
      </Box>
      {/* BOX 2 */}
      <Box mt={5} sx={{ minWidth: 120 }}>
        <Typography variant="h6">
          The employee you requsted travel for, is avaliable for the set period.
        </Typography>
        <Typography variant="h6">
          Please upload the official Invitation letter.
        </Typography>
      </Box>
      {/* BOX 3 */}
      <Box
        mt={5}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Stack direction="row" alignItems="center">
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
            />
            <Button variant="contained" component="span">
              Upload
            </Button>
          </label>
        </Stack>
        <Button
          sx={{ width: "50%", marginTop: "3rem" }}
          variant="contained"
          endIcon={<Send />}
          onClick={handleClick}
        >
          SEND LETTER
        </Button>
      </Box>
    </Container>
  );
};

export default InvitationLetter;
