import * as React from 'react';
import { useState } from "react";
import '../App.css';
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService"



const CandidatesApp: React.FunctionComponent = () => {

  const host = APP_URL

  window.onload = async function (){
    var response = await sendGETRequest(`${host}/job/candidates/`);
    await populateCandidates(response);
  }

  async function populateCandidates(response: Array<string>){
      var display = "";
      var candidate_list = document.getElementById("candidate-ul")!;

      candidate_list.innerText = display;

      if(response.length == 0){
          console.log(`Received Empty Data Set: ${response}`);
      }else{
          response.forEach(async (candidate: any) => {
              display += `<li>${candidate["first_name"]} ${candidate["last_name"]} - `;
              display += `${candidate["seniority"]["name"]} ${candidate["job_position"]["name"]}<li/>`        
          });

          candidate_list.innerHTML = display;
          //candidate_list.insertAdjacentHTML('afterend', display);
      }
  }

  async function sendGETRequest(url: string) {
    const response = await apiService.get(url);
    return await response;
  }

  async function filterCandidatesByJobPosition(){
      var response = await sendGETRequest(`${host}/job/candidates/filter/?job_position=${enteredJobPosition}`);
      await populateCandidates(response);
  };

  async function filterCandidatesBySeniorityLevel(){
      var response = await sendGETRequest(`${host}/job/candidates/filter/?seniority=${enteredSeniorityLevel}`);
      await populateCandidates(response);
  };


  // const [enteredText, setEnteredText] = useState("");
  const [enteredJobPosition, setEnteredJobPosition] = useState("");
  const [enteredSeniorityLevel, setEnteredSeniorityLevel] = useState("");

  // const keyDownHandler = (event: React.AbstractView<HTML>) => {
  //   if (event.code === "Enter") {
  //     var report = `You have typed job position: ${enteredJobPosition}\nYou have typed seniority level: ${enteredSeniorityLevel}`;
  //     alert(report);
  //   }
  // };

  return (
    <div className="container">
      <h1>Search all candidates</h1>
      <input
        placeholder="Job position name..."
        //onKeyDown={keyDownHandler}
        //onKeyUp={keyUpHandler}
        //onKeyPress={keyPressHandler}
        type="text"
        className="text-input"
        value={enteredJobPosition}
        onChange={(e) => setEnteredJobPosition(e.target.value)}
      />

      <button onClick={filterCandidatesByJobPosition}>Search by Job Position</button>

      <br />

      <input
        placeholder="Seniority Level..."
        //onKeyDown={keyDownHandler}
        //onKeyUp={keyUpHandler}
        //onKeyPress={keyPressHandler}
        type="text"
        className="text-input"
        value={enteredSeniorityLevel}
        onChange={(e) => setEnteredSeniorityLevel(e.target.value)}
      />

      <button onClick={filterCandidatesBySeniorityLevel}>Search by Seniority Level</button>

      <div id="candidate-div">
        <ul id="candidate-ul">

        </ul>
      </div>
      
    {/* <DropDown>
      
    </DropDown> */}

    </div>
  );
};

export default CandidatesApp;
