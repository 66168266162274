import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, Button, Input, TextField } from "@mui/material";
import { useState } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Swal from "sweetalert2";
import TextEditor from "../pages/Processes/components/TextEditor/TextEditor";

const PositionCreateModal = (props: any) => {
    const [dateFrom, setDateFrom] = useState<Date | null>(
       props.position?.start_date || new Date()
    );
    const [dateTo, setDateTo] = useState<Date | null>(
        props.position?.end_date || new Date()
    );
    const [title, setTitle] = useState(props.position?.name || "Position name");
    const [editorContent, setEditorContent] = useState(props.position?.job_description || "");

    const formData = {
        name: "",
        job_description: "",
        start_date: new Date(),
        end_date: new Date()
    }

    function close() {
        props.onClose()
    }
    const Toast = Swal.mixin({
        toast: true,
        color: "white",
        iconColor: "white",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

    const handleDateTo = (newValue: Date | null) => {
        setDateTo(newValue);
    };

    const handleDateFrom = (newValue: Date | null) => {
        setDateFrom(newValue);
    };

  const handleInactive = () => {
    props.deletePosition();
  };
  
  function add() {
    formData["name"] = title;
    formData["job_description"] = editorContent;
    formData["start_date"] = dateFrom;
    formData["end_date"] = dateTo;
    apiService.post(`${APP_URL}/job/alljobs/`, formData);
    props.onClose();
  }
  function edit() {
    formData["name"] = title;
    formData["job_description"] = editorContent;
    formData["start_date"] = dateFrom;
    formData["end_date"] = dateTo;
    const watchersId = props.position.watchers.map(
      (watcher: any) => watcher.id
    );

    apiService
      .patch(`${APP_URL}/job/alljobs/`, {
        id: props.position.id,
        watchers: watchersId,
        ...formData,
      })
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Succesfully edited!",
          background: "#a5dc86",
        });
        props.onClose();
      });
  }

    return (
        <div style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div style={{
                marginTop: "8vh",
                marginLeft: "15vw",
                height: "85vh",
                width: "50vw",
                backgroundColor: "white",
                overflow: "auto",
                justifyContent: "center"
            }}>
                <div style={{
                    padding: 0
                }}>
                {props.position ? <h4>Edit position</h4> : <h4>Create Position</h4>}
                </div>
                <div style={{
                    padding: "10px",
                    borderTop: "1px solid #eee",
                    borderBottom: "1px solid #eee",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <Box sx={{ marginBottom: 3 }}>
                        <TextField
                            required
                            label="Required"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            style={{ background: "white", margin: 10, minWidth: "50px", paddingBottom: 3 }}
                            sx={{
                                input: { color: "black", textAlign: "center", fontSize: "24px", alignItems: "center", lineHeight: "24px" }
                            }}
                        />
                    </Box>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around"
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Open from"
                                inputFormat="MM/dd/yyyy"
                                value={dateFrom}
                                onChange={handleDateFrom}
                                renderInput={(params: any) => <TextField {...params} />} />
                            <DesktopDatePicker
                                label="Open until"
                                inputFormat="MM/dd/yyyy"
                                value={dateTo}
                                onChange={handleDateTo}
                                renderInput={(params: any) => <TextField {...params} />} />
                        </LocalizationProvider>
                    </div>
                    <Box
                        sx={{
                            marginTop: 5,
                            alignItems: "center",
                            backgroundColor: "whitesmoke",
                            border: "2px solid #eee"
                        }}>
                        <TextEditor getEditorContent={setEditorContent} content={editorContent}/>
                    </Box>
                    {/* <Button>Add section</Button> */}
                </div>

                <div style={{ padding: "10px"}}>
                    <Button onClick={close} style={{ float: "left" }}>
                    Close
                </Button>
                {props.position ? (
                    <>
                <Button onClick={handleInactive} style={{ float: "left" }}>
                    Move to inactive
                </Button>
                    <Button onClick={edit} style={{ float: "right" }}>
                    Save
                    </Button>
                    </>
                ) : (
                    <Button
                    onClick={add}
                    style={{ float: "right" }}
                    disabled={title == ""}
                    >
                    Add
                    </Button>
                )}
                </div>
      </div>
    </div>
  );
};

export default PositionCreateModal;
