import React, { useEffect, useState, Fragment } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, ImageListItem} from '@mui/material';
import { APP_URL } from "../utils/constants";
import Avatar from '@mui/material/Avatar';
import { ImageList } from '@mui/material';
import apiService from "../services/apiService"
import { stringAvatar } from "../utils/constants";

interface User {
    id: number;
    user: UserData;
    position: Position;
    team: string;
    email: string;
    seniority: Seniority;
    company: string;
    cv: File;
}

interface Seniority {
    id: number;
    name: string;
}

interface Position {
    id: number;
    name: string;
}

interface UserData {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
}

type TUserList = User[];

type TUser = User;

interface UserProps {
    user_data: TUser;
}

const UserCard: React.FC<UserProps> = ({user_data}) => {
    
    return(
        <div>
            <div>
                <Card variant="outlined" sx={{ maxWidth: 345 }} key={user_data.id}>
                    <CardActionArea>
                        <CardMedia>
                            <Avatar {...stringAvatar(user_data.user.first_name + " " + user_data.user.last_name)} />
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {user_data.user.first_name} {user_data.user.last_name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Email: {user_data.user.email}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Team: {user_data.team}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Seniority: {user_data.seniority.name} 
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Position: {user_data.position.name} 
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    );
};


const Users: React.FC = (props) => {

    const [users, setUsersList] = useState<TUserList>([]);
    const url = `${APP_URL}/users/user/`;

    useEffect(() => {
        apiService.get(url)
            .then((response) => {
                setUsersList(response);
            });
    }, []);
    return (
        <ImageList cols={3}>
             {
                users.map((u) => (
                    <ImageListItem key={u.id}>
                        <UserCard user_data={u} />
                    </ImageListItem>
                ))
            }
        </ImageList>
    );
};



export default Users;