import { Box, Button } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ISection, ISubsection } from "../../PerformanceReviewQuestions/utils/prf.model";
import { classes } from "../../PerformanceReviewQuestions/utils/styles";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { IFunctionProps } from "../interfaces/functions.model";
import apiService from "../../../services/apiService";
import { APP_URL } from "../../../utils/constants";
import Subsection from "./Subsection";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface IProps {
  section: ISection;
  submitted: boolean;
}

const Section = ({ secIndex, section, submitted, prf, setPrf }: IFunctionProps & IProps) => {
  const changeSectionTitle = async (sectionId: number, newTitle: string) => {
    // console.log(new)
    await apiService
      .patch(`${APP_URL}/performance-review/section/`, {
        id: sectionId,
        title: newTitle,
      })
      .then((res: ISection) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: prevPrf.sections.map((sec, index) => {
            if (index === secIndex) {
              return res;
            }
            return sec;
          }),
        }));
      });
  };

  const changeSectionDescription = async (sectionId: number, newDescription: string) => {
    await apiService
      .patch(`${APP_URL}/performance-review/section/`, {
        id: sectionId,
        description: newDescription,
      })
      .then((res: ISection) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: prevPrf.sections.map((sec, index) => {
            if (index === secIndex) {
              return res;
            }
            return sec;
          }),
        }));
      });
  };

  const deleteSection = async () => {
    await apiService
      .deleteItem(`${APP_URL}/performance-review/section/`, {
        id: prf.sections[secIndex].id,
      })
      .then((res) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: [...prevPrf.sections.slice(0, secIndex), ...prevPrf.sections.slice(secIndex + 1)],
        }));
      });
  };

  const addNewSubsection = async () => {
    const targetSubsecs = prf.sections[secIndex].subsections;
    const newSubsection: ISubsection = {
      title: "",
      question_forms: [],
      ordering_index: (targetSubsecs[targetSubsecs.length - 1]?.ordering_index ?? 0) + 1,
    };
    await apiService
      .post(`${APP_URL}/performance-review/section/addsubsection/`, {
        sectionID: prf.sections[secIndex].id,
        subsection: newSubsection,
      })
      .then((res: ISubsection) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: [
            ...prevPrf.sections.slice(0, secIndex),
            {
              ...prevPrf.sections[secIndex],
              subsections: [...prevPrf.sections[secIndex].subsections, res],
            },
            ...prevPrf.sections.slice(secIndex + 1),
          ],
        }));
      });
  };

  return (
    <Draggable draggableId={`draggableSec_${secIndex}`} index={secIndex} key={secIndex}>
      {(providedSecDrag, snapshot) => (
        <Box key={section.id} sx={classes.section} ref={providedSecDrag.innerRef} {...providedSecDrag.draggableProps}>
          {/* SECTION TITLE */}
          <Box sx={{ backgroundColor: "purple", borderRadius: " 10px 10px 0px 0px", ml: -2, mr: -2 }}>
            <TextField
              required
              variant="standard"
              sx={{
                width: "30vw",
                marginBottom: 1,
                input: { fontSize: 32, color: "white", textAlign: "center" },
              }}
              placeholder="Section title..."
              // defaultValue={section.title}
              value={null}
              onBlur={(e) => changeSectionTitle(prf.sections[secIndex].id, e.target.value)}
              error={submitted && section.title.trim() === ""}
            // helperText={submitted && section.title.trim() === "" ? "This field is required" : ""}
            />
            <IconButton
              onClick={deleteSection}
              sx={{
                marginTop: 1,
                marginLeft: 3,
                width: 50,
                height: 50,
                color: "white",
                "&:hover": {
                  backgroundColor: "#ECECEC",
                },
              }}
            >
              <DeleteIcon fontSize="large" />
            </IconButton>
            <IconButton
              {...providedSecDrag.dragHandleProps}
              sx={{
                marginTop: 1,
                marginLeft: 3,
                width: 50,
                height: 50,
                color: "white",
                "&:hover": {
                  backgroundColor: "#ECECEC",
                },
              }}
            >
              <DragIndicatorIcon fontSize="large" />
            </IconButton>
          </Box>
          {/* SECTION TITLE */}

          {/* SECTION DESCRIPTION */}
          <Box sx={{ display: "flex", ml: 5, mt: 3, mb: 3, mr: 5 }}>
            <TextField
              multiline
              // minRows={2}
              variant="standard"
              sx={{ width: "30vw", marginBottom: 1 }}
              placeholder="Description..."
              defaultValue={section.description}
              onBlur={(e) => changeSectionDescription(prf.sections[secIndex].id, e.target.value)}
              error={submitted && section.description.trim() === ""}
              helperText={submitted && section.description.trim() === "" ? "This field is required" : ""}
            />
          </Box>
          {/* SECTION DESCRIPTION */}
          <Box>
            <Droppable droppableId={`droppableSec_${secIndex}`} type={`SUBSECTIONS_${secIndex}`} key={secIndex}>
              {(providedSubsecDrop, snapshot) => (
                <Box {...providedSubsecDrop.droppableProps} ref={providedSubsecDrop.innerRef}>
                  {section?.subsections?.length > 0 &&
                    section?.subsections?.map((subsection: ISubsection, subsecIndex) => (
                      <Subsection
                        prf={prf}
                        setPrf={setPrf}
                        secIndex={secIndex}
                        subsecIndex={subsecIndex}
                        subsection={subsection}
                        submitted={submitted}
                        key={subsecIndex}
                      />
                    ))}
                  {providedSubsecDrop.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            onClick={addNewSubsection}
            style={{ marginBottom: "15px" }}
            startIcon={<AddCircleIcon />}
          >
            Add Subsection
          </Button>
        </Box>
      )}
    </Draggable>
  );
};

export default Section;
