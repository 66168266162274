import React from "react";
import { DropResult } from "react-beautiful-dnd";
import apiService from "../../../services/apiService";
import { APP_URL } from "../../../utils/constants";
import { IFunctionProps } from "../interfaces/functions.model";

interface IProps {
  result: DropResult;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  prfId: number;
}

export const handleDragQuestions = async ({ result, prf, setPrf, prfId, setLoader }: IFunctionProps & IProps) => {
  const { source, destination } = result;
  const [_secIdx, _subsecIdx] = source.droppableId.split("-").map((el) => el.split("_")[1]);
  const [r_secIdx, r_subsecIdx] = [Number(_secIdx), Number(_subsecIdx)];

  setPrf((prevPrf) => {
    const newPrf = { ...prevPrf };
    newPrf.sections.map((sec, secIdx) => {
      if (secIdx === r_secIdx) {
        sec.subsections.map((subsec, subsecIdx) => {
          if (subsecIdx === r_subsecIdx) {
            const [removed] = subsec.question_forms.splice(source.index, 1);
            subsec.question_forms.splice(destination.index, 0, removed);
          }
          return subsec;
        });
      }
      return sec;
    });
    return newPrf;
  });

  await apiService.patch(`${APP_URL}/performance-review/question_formsv2/order/`, {
    question_forms: prf.sections[r_secIdx].subsections[r_subsecIdx].question_forms.map((qf, qfIdx) => ({
      id: qf.id,
      ordering_index: qfIdx,
    })),
  });
};

export const handleDragSubsections = async ({ result, prf, setPrf, prfId, setLoader }: IFunctionProps & IProps) => {
  const { source, destination } = result;
  const [_secIdx] = source.droppableId.split("-").map((el) => el.split("_")[1]);
  const r_secIdx = Number(_secIdx);

  setPrf((prevPrf) => {
    const newPrf = { ...prevPrf };
    newPrf.sections.map((sec, secIdx) => {
      if (secIdx === r_secIdx) {
        const [removed] = sec.subsections.splice(source.index, 1);
        sec.subsections.splice(destination.index, 0, removed);
      }
      return sec;
    });
    return newPrf;
  });

  await apiService.patch(`${APP_URL}/performance-review/subsection/order/`, {
    subsections: prf.sections[r_secIdx].subsections.map((subsec, subsecIdx) => ({
      id: subsec.id,
      ordering_index: subsecIdx,
    })),
  });
};

export const handleDragSections = async ({ result, prf, setPrf, prfId, setLoader }: IFunctionProps & IProps) => {
  const { source, destination } = result;
  setPrf((prevPrf) => {
    const newPrf = { ...prevPrf };
    const [removed] = newPrf.sections.splice(source.index, 1);
    newPrf.sections.splice(destination.index, 0, removed);
    return newPrf;
  });

  await apiService.patch(`${APP_URL}/performance-review/section/order/`, {
    sections: prf.sections.map((sec, secIdx) => ({
      id: sec.id,
      ordering_index: secIdx,
    })),
  });
};
