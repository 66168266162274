import { Cookie } from '@mui/icons-material';
import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate, Outlet,Navigate } from 'react-router-dom';
import { Authenticator } from './Authenticator'; 


const PrivateRoute = () => {

    let cookie = Cookies.get('myCookie')
    
    return cookie ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute