import { Box, Typography, TextField, Button } from "@mui/material";
import { useCallback, useState } from "react";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import TextEditor from "./components/TextEditor/TextEditor";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const CreateDocument = () => {
  const [documentName, setDocumentName] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [publish, setPublish] = useState(false);

  let currentUser = useSelector((state: any) => state.reducer.user);
  const navigate = useNavigate();

  if (!(currentUser.is_hr || currentUser.is_super_hr)) {
    window.location.href = window.location.origin + "/processes";
  }

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const handleDraft = useCallback(async () => {
    setPublish(true);
    if (documentName.trim() === "") {
      return;
    }
    let postData = {
      is_draft: true,
      name: documentName,
      body: editorContent,
    };
    await apiService
      .post(`${APP_URL}/processes/documents/`, postData)
      .catch((message) => {})
      .then(() => {
        navigate("/processes/");
      });
  }, [documentName, editorContent, navigate]);

  const handlePublish = useCallback(async () => {
    setPublish(true);
    if (documentName.trim() === "") {
      return;
    }
    let postData = {
      is_draft: false,
      name: documentName,
      body: editorContent,
    };

    await apiService
      .post(`${APP_URL}/processes/documents/`, postData)
      .catch((message) => {})
      .then(() => {
        Swal.fire("Document published!", "", "success");
        navigate("/processes");
      });
  }, [documentName, editorContent, navigate]);

  return (
    <Box mt={10} sx={{ backgroundColor: "white" }}>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          marginLeft: "15px",
        }}
      >
        <Box>
          <TextField
            variant="outlined"
            placeholder="Document name"
            autoComplete="off"
            value={documentName}
            error={publish && documentName.trim() === ""}
            helperText={
              publish && documentName.trim() === ""
                ? "This field is required"
                : ""
            }
            onChange={(e) => {
              setDocumentName(e.target.value);
            }}
            sx={{ width: "300px", marginTop: "15px" }}
          />
          <Typography
            align="left"
            fontSize={12}
            marginTop="2px"
            marginLeft="5px"
            color="#8A8A8A"
          >
            Created at {date}
          </Typography>
        </Box>
        <Box>
          <Button
            style={{
              marginRight: "25px",
              marginTop: "15px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
              height: "50%",
            }}
            onClick={handleDraft}
          >
            Save as Draft
          </Button>

          <Button
            style={{
              marginRight: "25px",
              marginTop: "15px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
              height: "50%",
            }}
            onClick={handlePublish}
          >
            Publish
          </Button>
        </Box>
      </Box>

      <Box marginTop={"30px"}>
        <TextEditor
          getEditorContent={setEditorContent}
          content={editorContent}
        />
      </Box>
    </Box>
  );
};
export default CreateDocument;
