import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";

export class Position {
   id: number;
   created_at: Date;
   modified_at: Date;
   name: string;
   job_description: string;
   constructor(
      id: number = 0,
      created_at: Date,
      modified_at: Date,
      name: string = "",
      job_description: string = ""
   ) {
      this.id = id;
      this.created_at = created_at;
      this.modified_at = modified_at;
      this.name = name;
      this.job_description = job_description;
   }
}
export const getPositionList = async (): Promise<any[]> => {
   let listPosition: any[] = undefined;
   await apiService.get(`${APP_URL}/job/alljobs/`).then(response => listPosition=response);
   return listPosition;
};
