import React, { useRef, useState, useEffect } from "react";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import Comment from "./Comment";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import SendIcon from "../../assets/img/SendIcon.svg";
import { Box, Button, Paper, TextField, Tooltip, Typography } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { decodeToken } from "react-jwt";

var jwt = require("react-jwt");

export default function CandidateComments(data: any) {
  let cookie = Cookies.get("myCookie");
  // let userId = jwt.decode(cookie).user_id;
  let userId = (decodeToken(cookie) as any).user_id;
  const [beComments, setBeComments] = useState([]);
  const [newComment, setNewComment] = useState([]);
  const [newState, setNewState] = useState([]);
  const [confidential, setConfidential] = useState(false);
  const [user, setUser] = useState<any>();
  const bottomRef = useRef(null);
  
  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: '#294165'
    }
  });

  useEffect(() => {
      apiService.get(`${APP_URL}/users/profile/${userId}/`).then((resp) => setUser(resp))
  },[])

  useEffect(() => {
    data.canId &&
      apiService
        .get(`${APP_URL}/job/candidates/${data.canId}/comments/`)
        .then((resp) => {
          setBeComments(resp);
        });
  }, [newState, data.canId]);

  useEffect(() => {
    if (beComments.length > 0)
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [beComments]);

  const handleComment = (e: any) => {
    setNewComment(e.target.value);
  };

  const sendComment = () => {
    apiService.post(`${APP_URL}/job/candidates/comments/`, {
      body: newComment,
      user: userId,
      candidate: data.canId,
      is_confidential: confidential
    });
    setNewState(newComment)
    setNewComment([]);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean | null,
  ) => {
    setConfidential(!confidential);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
        <Box sx={{borderBottom:1, borderColor: "#ebebea", textAlign: "left"}}>
          <Typography variant="h5" sx={{paddingLeft: 4, paddingRight:4, paddingTop:2, paddingBottom: 2}}>Comments</Typography>
        </Box>
      <Paper style={{ overflow: "auto", height: "50vh"}}>
        {beComments.map((item) => (
          <Comment comment={item} userId={userId} />
        ))}
        <div ref={bottomRef} />
        </Paper>
        <Paper style={{ padding: 2, marginTop: 15}}>
        <Tooltip title="Confidential comment" placement="top">
        <ToggleButton 
        value={confidential}
        selected={confidential}
        onChange={handleAlignment}
        style={{border: 0}}
        sx={{display: user?.is_hr || user?.is_super_hr ? "inline" : "none", marginRight: 2}}
        >
          <VisibilityOffIcon style={{ marginTop: "5px" }}/>
        </ToggleButton>
        </Tooltip>
        <TextField
          value={newComment}
          sx={{ mt: 1 }}
          size="small"
          onChange={(e) => handleComment(e)}
          style={{ width: "75%" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendComment();
            }
          }}
          helperText= {confidential ? "Writing a confidential comment" : "\u00A0"}
          inputProps={{style: {backgroundColor: confidential ? "#29416580" : "white", color: confidential ? "white" : "black" , borderColor: "white"}}}
        />

        <Button onClick={sendComment}>
          <img src={SendIcon} />
        </Button>
        </Paper>
    </div>
  );
}
