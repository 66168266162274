import { Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService";
import { useParams } from "react-router-dom";
import CandidateComments from "../components/Comments/CandidateComments";
import ViewerPdf from "../components/PDFViewer/ViewerPdf";


const CandidateDetails = (props: any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [candidate, setCandidate] = useState(null);
  const params = useParams<{id:string}>()
  const [loader, setLoader] = useState(true);
  const [cv, setCv] = useState();




  useEffect(() => {
    apiService.get(`${APP_URL}/job/candidates/${params.id}/`).then((data) => {
      setCandidate(data)
    }
    );
    let temp_candidate = {"id": params.id, "is_viewed": true}
    apiService.patch(`${APP_URL}/job/candidates/`, temp_candidate)
    apiService.get(`${APP_URL}/job/get-cv/${params.id}`).then((data) => setCv(data))
  }, []);

  const helper_dict = [
    {"Name": candidate?.first_name + " " + candidate?.last_name},
    {"Email": candidate?.email},
    {"Linkedin": candidate?.linkedin},
    {"Position": candidate?.job_position?.name},
    {"Seniority": candidate?.seniority},
    {"Application phase": candidate?.job_application_progress?.name}
  ]

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
      setLoader(false);
    }, 1000);
    }
  }, [loader]);


  return loader ? (
    <Box sx={{height: "100%", width: "100%", alignItems:"center", justifyContent: "center"}}>
      <CircularProgress size="100px" style={{marginTop: "35vh"}}/>
    </Box>

  ) : (
  <Grid container>
    <Grid item md={12} xs={12} sx={{border:1, borderRadius:"5px", borderColor: "#ebebea", backgroundColor: "white", marginBottom: 5}}>
        <Box sx={{borderBottom:1, borderColor: "#ebebea", textAlign: "left"}}>
          <Typography variant="h5" sx={{paddingLeft: 4, paddingRight:4, paddingTop:2, paddingBottom: 2}}>Basic Information</Typography>
        </Box>
        <Grid container>
        {helper_dict.map((field: any) => {
          return(
              <Grid item md={4} xs={12} sx={{textAlign: "left", padding: 3}}>
                  <Typography paragraph>{Object.keys(field)[0]}:</Typography>
                  <Typography paragraph sx={{fontWeight: "bold"}}>{field[Object.keys(field)[0]]}</Typography>
              </Grid>
              )
        })}
        </Grid>
    </Grid>
    <Grid item md={12} xs={12} sx={{border:1, borderRadius:"5px", borderColor: "#ebebea", backgroundColor: "white", marginBottom: 5}}>
    <Box sx={{borderBottom:1, borderColor: "#ebebea", textAlign: "left"}}>
          <Typography variant="h5" sx={{paddingLeft: 4, paddingRight:4, paddingTop:2, paddingBottom: 2}}>CV</Typography>
        </Box>
      <Box sx={{height: "100vh", marginBottom: 5, border: 1, borderRadius:"5px", borderColor: "#ebebea"}}>
        <ViewerPdf file = {"data:image/png;base64,"+cv}/>
      </Box>
    </Grid>
    <Grid item md={12} xs={12} sx={{border:1, borderRadius:"5px", borderColor: "#ebebea", backgroundColor: "white"}}>
      <CandidateComments canId={candidate?.id}/>
    </Grid>
  </Grid>
  )
};

export default CandidateDetails;