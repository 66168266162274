import { Box, Button, CircularProgress, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IPerformanceReviewForm, ISection } from "../PerformanceReviewQuestions/utils/prf.model";
import { classes } from "../PerformanceReviewQuestions/utils/styles";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import Section from "./components/Section";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { handleDragQuestions, handleDragSections, handleDragSubsections } from "./utils/DragAndDropHandlers";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const PerformanceReviewTemplate = () => {
  let currentUser = useSelector((state: any) => state.reducer.user);
  const prfId = Number(useParams<{ prfId: string }>().prfId);
  const navigate = useNavigate();
  const [prf, setPrf] = useState<IPerformanceReviewForm>({
    id: Number(prfId),
    name: "",
    is_template: false,
    sections: [],
  });

  const [loader, setLoader] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchPrf = async () => {
      await apiService.get(`${APP_URL}/performance-review/pr_form/${prfId}/`).then((res) => {
        setPrf(res);
        setLoader(false);
      });
    };
    fetchPrf();
  }, []);

  const changePrfName = async (newName: string) => {
    if (newName.length === 0) { }
    await apiService
      .patch(`${APP_URL}/performance-review/pr_form/`, {
        id: prfId,
        name: newName,
      })
      .then((res) => {
        setPrf(res);
      });
  };

  const addNewSection = async () => {
    const targetSecs = prf.sections;
    const newSection: ISection = {
      title: "",
      description: "",
      subsections: [],
      ordering_index: (targetSecs[targetSecs.length - 1]?.ordering_index ?? 0) + 1,
    };

    await apiService
      .post(`${APP_URL}/performance-review/pr_form/addsection/`, {
        formID: prf.id,
        section: newSection,
      })
      .then((res: ISection) => {
        setPrf((prevPrf) => ({ ...prevPrf, sections: [...prevPrf.sections, res] }));
      });
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) return;
    if (result.type.includes("QUESTIONS")) handleDragQuestions({ result, prf, setPrf, prfId, setLoader });
    else if (result.type.includes("SUBSECTIONS")) handleDragSubsections({ result, prf, setPrf, prfId, setLoader });
    else if (result.type.includes("SECTIONS")) handleDragSections({ result, prf, setPrf, prfId, setLoader });
  };

  return loader ? (
    <Box style={classes.loading}>
      <CircularProgress size="6rem" />
    </Box>
  ) : (
    <Paper style={classes.paper}>
      <Box style={classes.header}>
        <Box style={classes.perfRewQuest}>
          {/* TITLE */}
          <TextField
            required
            variant="standard"
            sx={{ width: "20vw", display: "flex", justifyContent: "flex-start", input: { fontSize: 40 } }}
            placeholder="Form title..."
            defaultValue={prf.name}
            onBlur={(e) => changePrfName(e.target.value)}
            error={submitted && prf.name.trim() === ""}
            helperText={submitted && prf.name.trim() === "" ? "This field is required" : ""}
          />
          {/* TITLE */}
        </Box>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`droppablePrf`} type="SECTIONS">
          {(providedSecDrop, snapshot) => (
            <Box {...providedSecDrop.droppableProps} ref={providedSecDrop.innerRef}>
              {prf?.sections?.length > 0 &&
                prf?.sections.map((section: ISection, secIndex: number) => (
                  <Section
                    key={secIndex}
                    section={section}
                    secIndex={secIndex}
                    submitted={submitted}
                    prf={prf}
                    setPrf={setPrf}
                  />
                ))}
              {providedSecDrop.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={addNewSection}
        style={{ marginBottom: "15px", marginRight: "150px" }}
        startIcon={<AddCircleIcon />}
      >
        Add Section
      </Button>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={() => navigate("/main/performance-review-list")}
        style={{ marginBottom: "15px", marginRight: "150px" }}
      >
        Cancel
      </Button>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={() => {
          // console.log(prf)
          if (prf.name !== "") {
            if (prf.sections.length > 0) {
              if (prf.sections[0].title !== "") {

                Swal.fire("Template Saved", "", "success").then(() => navigate("/main/performance-review-list"));

              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Section title is required!',
                })

              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'At least one section is required!',
              })
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Form title is required!',
            })
          }
        }}

        style={{ marginBottom: "15px" }}
      >
        Finish
      </Button>
    </Paper>
  );
};

export default PerformanceReviewTemplate;
