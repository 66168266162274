import { createRoot } from 'react-dom/client';


import App from './App';
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { PersistGate } from "redux-persist/integration/react";
import mainReducer from "./store/index";
import logger from "redux-logger";
import { get } from "lodash";
import { Provider as ReduxProvider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import SideNavbar from "./components/Sidebar";

// const cors = require('cors');

// const corsOptions ={
//     origin:'http://localhost:3000', 
//     credentials:true,            //access-control-allow-credentials:true
//     optionSuccessStatus:200
// }

// app.use(cors(corsOptions));
// var app = express();

// app.use(function(req:any, res:any, next:any) {
//   res.header("Access-Control-Allow-Origin", "*");
//   res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
//   next();
// });.
const persistConfig = {
  key: "reducer",
  storage: storageSession,
};

const composeEnhancers =
  get(window, "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__") || compose;



  const container = document.getElementById('root');
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  root.render(<App/>);


