import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import NextWeekOutlinedIcon from "@mui/icons-material/NextWeekOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import GroupRemoveOutlinedIcon from "@mui/icons-material/GroupRemoveOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import homeIcon from "../assets/img/HomeIcon.svg";
import homeIconBlue from "../assets/img/HomeIconBlue.svg";
import generalInfoIcon from "../assets/img/GeneralInfoIcon.svg";
import generalInfoIconBlue from "../assets/img/GeneralInfoIconBlue.svg";
import employeeListIcon from "../assets/img/EmployeeListIcon.svg";
import employeeListIconBlue from "../assets/img/EmployeeListIconBlue.svg";
import performanceReviewIcon from "../assets/img/PerformanceReviewIcon.png";
import performanceReviewIconBlue from "../assets/img/PerformanceReviewIconBlue.svg";
import travelRequestIcon from "../assets/img/TravelRequestIcon.png";
import LastPerformanseReview from "../components/LastPerformanseReview";
import circleRed from "../assets/img/CircleRed.svg";
import circleBlue from "../assets/img/CircleBlue.svg";
import circlePurple from "../assets/img/CirclePurple.svg";
import GeneralInfoTabs from "../components/GeneralInfoTabs";
import CircularProgress from "@mui/material/CircularProgress";
import PerformanceReviewQuestion from "../pages/PerformanceReviewQuestions/PerformanceReviewQuestions";
import PerformanceReviewRequest from "../components/PerformanceReviewRequest";
import PerformanceReviewRequestDetail from "../components/PerformanceReviewRequestDetail";
import PerformanceReviewList from "../components/PerformanceReviewList";
import AllEmployeesList from "../components/HRDashboard/ListEmployees";
import { truncateSync } from "fs";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { ChangeDisplay } from "../components/CompanyOverview";
import { ChangeDisplayClient } from "../components/ClientHomePage";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import TripOriginOutlinedIcon from "@mui/icons-material/TripOriginOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

type MyComponentProps = React.PropsWithChildren<{}>;

const drawerWidthOpen = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidthOpen}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

let toggle: any;
let is_opened: boolean;

export function ToggleDrawer() {
  if (window.location.href.includes("/main/overview")) {
    ChangeDisplay(!is_opened);
  }
  if (window.location.href.includes("/main/client/overview")) {
    ChangeDisplayClient(!is_opened);
  }
  toggle(!is_opened);
}

export default function SideNavbar({ children }: MyComponentProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  toggle = setOpen;
  is_opened = open;
  const [isRegularUser, setIsRegularUser] = useState<any>(false);

  let currentUser = useSelector((state: any) => state.reducer.user);
  let should_view_travel: boolean = false;

  if (currentUser !== undefined)
    if (
      currentUser?.is_regular_user ||
      currentUser?.is_team_lead ||
      currentUser?.is_supervisor
    )
      should_view_travel = true;
    else should_view_travel = false;
  const classes = {
    user: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: "150px",
    },
    userInfo: {
      fontSize: "20px",
    },
    drawerPaper: {
      background: "#ffffff",
      color: "#334D6E",
    },
    active: {
      color: "#109CF1",
    },
    page: {
      background: "#f9f9f9",
      width: "100%",
    },
  };

  const [openTravel, setOpenTravel] = useState(false);
  const [openPerformanceReview, setOpenPerformanceReview] = useState(false);
  const [openRecruitment, setOpenRecruitment] = useState(false);
  const [tab, setTab] = useState("overview");
  const location = useLocation();

  let cookie = Cookies.get("myCookie");
  return (
    <Box sx={{ display: "flex" }}>
      {cookie ? (
        <Drawer
          variant="persistent"
          anchor="left"
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
          open={open}
          sx={{
            width: drawerWidthOpen,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidthOpen,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ mt: 10 }}>
            <Typography
              sx={{
                color: "#959595",
                fontSize: 17,
                mb: 1,
                mt: 1,
                fontWeight: "bold",
              }}
            >
              OVERVIEW
            </Typography>
            <ListItem
              button
              style={{ boxShadow: "none" }}
              onClick={(e: any) =>
                navigate(
                  currentUser?.is_client
                    ? "/main/client/overview"
                    : "/main/overview"
                )
              }
            >
              <DashboardOutlinedIcon sx={{ mr: 3 }} />
              <Typography>Dashboard</Typography>
            </ListItem>
            {currentUser?.is_hr || currentUser?.is_super_hr ? (
              <Accordion
                style={{ boxShadow: "none" }}
                disableGutters
                elevation={0}
                sx={{
                  "&.MuiAccordion-root:before": {
                    backgroundColor: "white",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <GroupOutlinedIcon sx={{ mr: 3 }} />
                  <Typography>Employee List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ListItem
                      button
                      onClick={(e:any) => navigate("/create-user")}
                    >
                      <PersonAddAltIcon sx={{ mr: 1 }} />
                      Create New User
                    </ListItem>
                    <ListItem
                    button
                    style={{ boxShadow: "none" }}
                    onClick={(e: any) => navigate("/our-team")}
                  >
                    <GroupsOutlinedIcon sx={{ mr: 1 }} />
                    Our Team
                  </ListItem>
                </AccordionDetails>
              </Accordion>
            ) : null    
              }         

            {currentUser?.is_client || currentUser?.is_super_hr ? (
              <Accordion
                style={{ boxShadow: "none" }}
                disableGutters
                elevation={0}
                sx={{
                  "&.MuiAccordion-root:before": {
                    backgroundColor: "white",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AddLocationAltOutlinedIcon sx={{ mr: 3 }} />
                  <Typography>Travel Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ListItem
                    button
                    onClick={(e: any) => navigate("/main/travel-request")}
                  >
                    <EditLocationOutlinedIcon sx={{ mr: 1 }} />
                    Travel Request Form
                  </ListItem>
                  <ListItem
                    button
                    onClick={(e: any) =>
                      navigate("/main/travel-request-history")
                    }
                  >
                    {" "}
                    <PlaceOutlinedIcon sx={{ mr: 1 }} />
                    Travel Request List
                  </ListItem>
                </AccordionDetails>
              </Accordion>
            ) : (
              <ListItem
                button
                style={{ boxShadow: "none" }}
                onClick={(e: any) => navigate("/main/travel-request-history")}
              >
                <AddLocationAltOutlinedIcon sx={{ mr: 3 }} />
                <Typography>Travel Request List</Typography>
              </ListItem>
            )}
            <Accordion
              style={{ boxShadow: "none" }}
              disableGutters
              elevation={0}
              sx={{
                "&.MuiAccordion-root:before": {
                  backgroundColor: "white",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <InsertChartOutlinedIcon sx={{ mr: 3 }} />
                <Typography>Performance Review</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItem
                  button
                  onClick={(e: any) =>
                    navigate("/main/performance-review-request")
                  }
                >
                  <PostAddOutlinedIcon sx={{ mr: 1 }} />
                  Performance Review Request
                </ListItem>
                <ListItem
                  button
                  onClick={(e: any) => navigate("/main/performance-review-list")}
                >
                  {" "}
                  <DifferenceOutlinedIcon sx={{ mr: 1 }} />
                  Performance Review List
                </ListItem>
                <ListItem
                  button
                  onClick={(e: any) =>
                    navigate("/main/performance-review-history")
                  }
                >
                  {" "}
                  <HistoryOutlinedIcon sx={{ mr: 1 }} />
                  Performance Review History
                </ListItem>
                {currentUser?.is_super_hr ?(
                <ListItem button onClick={(e: any) => navigate("/prf-template")}>
                  <PlaylistAddIcon sx={{ mr: 1 }} />
                  New Form Template
                </ListItem>):("")}
              </AccordionDetails>
            </Accordion>
            {currentUser?.is_hr || currentUser?.is_super_hr || currentUser?.is_watcher ? (
              <Accordion
                style={{ boxShadow: "none" }}
                disableGutters
                elevation={0}
                sx={{
                  "&.MuiAccordion-root:before": {
                    backgroundColor: "white",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <BusinessCenterOutlinedIcon sx={{ mr: 3 }} />
                  <Typography>Recruitment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ListItem
                    button
                    onClick={(e: any) => navigate("/main/recruitment-landing")}
                  >
                    <NextWeekOutlinedIcon sx={{ mr: 1 }} />
                    Recruitment Landing
                  </ListItem>
                  <ListItem
                    button
                    onClick={(e: any) => navigate("/main/candidates")}
                  >
                    {" "}
                    <CasesOutlinedIcon sx={{ mr: 1 }} />
                    Candidate List
                  </ListItem>
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
            {!currentUser?.is_client ? (
              <>
                <Typography
                  sx={{
                    color: "#959595",
                    fontSize: 17,
                    mb: 1,
                    mt: 5,
                    fontWeight: "bold",
                  }}
                >
                  COMPANY
                </Typography>
                <ListItem
                  button
                  style={{ boxShadow: "none" }}
                  onClick={(e: any) => navigate("/processes")}
                >
                  <AssignmentOutlinedIcon sx={{ mr: 3 }} />
                  <Typography>Processes and Procedures</Typography>
                </ListItem>
                <ListItem
                  button
                  style={{ boxShadow: "none" }}
                  onClick={(e: any) => navigate("/structure")}
                >
                  <SchemaOutlinedIcon
                    style={{ transform: "rotate(270deg)" }}
                    sx={{ mr: 3 }}
                  />
                  <Typography>Structure</Typography>
                </ListItem>
                <ListItem
                  button
                  style={{ boxShadow: "none" }}
                  onClick={(e:any) => navigate("/path-to-success")}
                >
                  <InsightsOutlinedIcon sx={{ mr: 3 }} />
                  <Typography>Path to Success</Typography>
                </ListItem>
              </>
            ) : (
              ""
            )}
          </Box>
        </Drawer>
      ) : (
        " "
      )}
      <Main open={open}>
        <div style={classes.page}>{children}</div>
      </Main>
    </Box>
  );
}
