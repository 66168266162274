import {
    Box,
    Button,
    CardMedia,
    Container,
    createTheme,
    CssBaseline,
    ThemeProvider,
    Typography,
  } from "@mui/material";
  import { useState } from "react";
  import SendIcon from "@mui/icons-material/Send";
  import axios from "axios";
  import { APP_URL } from "../utils/constants";
  import { useParams } from "react-router-dom";
  import redx from "../assets/img/red-x.svg";
  import Swal from "sweetalert2";
import { decodeToken } from "react-jwt";
  var jwt = require("react-jwt");
  
  const theme = createTheme();
  
  export default function SetPasswordExpired() {
    const [isEqual, setIsEqual] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const params = useParams<{ token: string }>();
  
    let tokenValue = params.token;

   
    const SendNewPassword = () => {

    };

    const handlePasswordReset = async () => {
      // let userEmail = jwt.decode(tokenValue).user_email;
      let userEmail = (decodeToken(tokenValue) as any).user_email
          axios
            .post(`${APP_URL}/auth/reset-password/`, {
              email: userEmail,
            })
            .then((resp) => {
              if (resp.data.message === `failed`)
                Swal.fire(resp.data.reason, "", "error");
              else Swal.fire("Email sent!", "", "success");
            });      
    };
  
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box mb={-6}>
              <CardMedia
                component="img"
                height="200"
                image="/static/images/QCERRIS.svg"
                
              />
            </Box>
            <img src={redx} />
            <Typography variant="h6" mt={2}>
              Password Change Link Expired!
            </Typography>
            
            <Typography variant="subtitle1" mb={5}>
              Password change link access expires after 1 hour and can be used only once.
            </Typography>
            <Button
            sx={{ mt: 3, mb: 5, p: 1.5 }}
            fullWidth
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            onClick={handlePasswordReset}
          >
            Send me a new link
          </Button>
            
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
  