import { any, string } from "prop-types";
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService"

function CandidatePath(props: any) {
  const columnsFromBackend: any = {};
  const url = `${APP_URL}/candidate_details`;

  for (let phase of props.phases) {
    const phaseCandidates = props.candidateFromBackend.filter(
      (c: any) => c.phase_id === phase.id
    );
    //console.log(phaseCandidates);
    columnsFromBackend[phase.id] = {
      name: phase.name,
      items: phaseCandidates,
    };
  }

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      for (let i = 0; i < destItems.length; i++) {
        const patchID = destItems[i].id;
        apiService.patch(`${APP_URL}/job/candidates/${patchID}/`, {
          job_application_progress: destination.droppableId,
        });
      }
    } else {
      const column = columns[source];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);

      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  const [columns, setColumns] = useState(columnsFromBackend);
  useEffect(() => {
    setColumns(columnsFromBackend);
  }, [props.candidateFromBackend, props.phases]);
  return (
    <div>
      <h1 style={{ textAlign: "center" }}> QCerris Job Panel</h1>
      <div
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([id, column]) => {
            return (
              <div>
                {/* @ts-ignore */}
                <h2>{column.name}</h2>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={id} key={id}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "lightgrey",
                            padding: 4,
                            width: 120,
                            minHeight: 500,
                          }}
                        >
                          {/* @ts-ignore */}
                          {column.items.map((item: any, index: any) => {
                            return (
                              <Draggable
                                key={`${item.id}`}
                                draggableId={`${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        padding: 16,
                                        margin: "0 0 8px 0",
                                        minHeight: "50px",
                                        backgroundColor: snapshot.isDragging
                                          ? "#263B4A"
                                          : "#456C86",
                                        color: "white",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <a href={url}>{item.content}</a>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
}
export default function GetCandidates() {
  interface Phase {
    id: number;
    name: string;
    application_phase: boolean;
  }
  type TPhaseList = Phase[];
  interface PhaseListProps {
    items: TPhaseList;
  }
  const urlphase = `${APP_URL}/job/phases/`;
  const [phases, setPhases] = useState<TPhaseList>([]);
  useEffect(() => {
    apiService.get(urlphase).then((response) => {
      setPhases(response);
    });
  }, []);
  interface Candidat {
    id: number;
    first_name: string;
    last_name: string;
    job_position: JobPosition;
    job_application_progress: JobAplicationProgress;
    email: string;
    cv: File;
  }
  interface JobAplicationProgress {
    id: number;
    name: string;
    aplication_phase: boolean;
  }
  interface JobPosition {
    id: number;
    name: string;
  }
  type TCandidatList = Candidat[];
  const url = `${APP_URL}/job/candidates/`;
  const [candidates, setCandidates] = useState<TCandidatList>([]);
  useEffect(() => {
    apiService.get(url).then((response) => {
      setCandidates(response);
    });
  }, []);
  //console.log(candidates);
  // const FilterByPosition = () => {
  //   return candidates.filter((ca) => (ca.job_position.name === postion_name))
  // }
  // console.log(FilterByPosition());
  return (
    <div>
      <CandidatePath
        candidateFromBackend={candidates.map((can) => ({
          id: can.id,
          phase_id: can.job_application_progress,
          content: can.first_name + " " + can.last_name,
        }))}
        phases={phases}
      />
    </div>
  );
}
