import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import { IPerformanceReviewForm, ISection } from "./utils/prf.model";
import { classes } from "./utils/styles";
import QuestionForm from "./components/QuestionForm";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import error401 from "../../assets/errors/401(1).png";
import error403 from "../../assets/errors/403(1).png";
import error404 from "../../assets/errors/404(1).png";

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
});

export const checkIfAnyAnswerIsUnfilled = (prf: IPerformanceReviewForm) => {
  return prf.sections.some((section) =>
    section.subsections.some((subsection) =>
      subsection.question_forms.some(
        (question) => question.answer.toString().trim() === ""
      )
    )
  );
};

const PerformanceReviewQuestions = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [employee, setEmployee] = useState<any>();
  const [prf, setPrf] = useState<IPerformanceReviewForm>();
  const params = useParams<{ employeeId: string }>();
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      //TODO: GET ID FROM URL PARAMS
      console.log(params);
      await apiService
        .get(`${APP_URL}/performance-review/pr_form/${params.employeeId}/`)
        .then((res) => {
          setPrf(res);
          setEmployee(res.user);
          setLoader(false);
        })
        .catch((res) => {
          setLoader(false);
          setError(res.response.status);
        });
    };
    fetchData();
  }, [params.employeeId]);

  const handleSubmitPRF = async () => {
    setSubmitted(true);
    if (checkIfAnyAnswerIsUnfilled(prf)) {
      Toast.fire({
        icon: "error",
        title: "Please fill out all the answers before submitting the form!",
        background: "#f27474",
      });
    } else {
      await apiService
        .patch(`${APP_URL}/performance-review/pr_form/`, {
          id: prf.id,
          in_progress: false,
        })
        .then(async (res) => {
          Toast.fire({
            icon: "success",
            title: "Successfully submitted",
            background: "#4caf50",
          }).then(() => navigate("/main/performance-review-list"));
        })
        .catch(() => {
          Toast.fire({
            icon: "error",
            title:
              "There was a problem submitting your performance review form!",
            background: "#f27474",
          });
        });
    }
  };

  return loader ? (
    <Box style={classes.loading}>
      <CircularProgress size="6rem" />
    </Box>
  ) : error ? (
    error == "401" ? (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={error401}
          alt="error 401"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            height: "256",
            width: "570",
          }}
        />
      </Box>
    ) : error == "403" ? (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={error403}
          alt="error 403"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            height: "256",
            width: "570",
          }}
        />
      </Box>
    ) : error == "404" ? (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={error404}
          alt="error 404"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            height: "256",
            width: "570",
          }}
        />
      </Box>
    ) : (
      <></>
    )
  ) : (
    <Paper style={classes.paper}>
      <Box style={classes.header}>
        <Box style={classes.perfRewQuest}>
          <Typography variant="h5">
            Performance Review Questions for {employee?.user.first_name}{" "}
            {employee?.user.last_name}
          </Typography>
        </Box>
      </Box>
      {prf?.sections?.length > 0 &&
        prf?.sections.map((section: ISection, index: number) => (
          <Box key={index} sx={classes.section}>
            <Box
              sx={{
                backgroundColor: "purple",
                borderRadius: " 10px 10px 0px 0px",
                ml: -2,
                mr: -2,
              }}
            >
              <Typography sx={{ color: "white", fontSize: 32 }}>
                {section.title}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", ml: 5, mt: 3, mb: 3, mr: 5 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {section.description}
              </Typography>
            </Box>

            {section?.subsections?.length > 0 &&
              section?.subsections?.map((subsection, index) => (
                <Box key={index} sx={classes.subsection}>
                  <Typography
                    component={"span"}
                    sx={{
                      ml: 5,
                      fontSize: 20,
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    <pre style={{ fontFamily: "inherit" }}>
                      {subsection.title}
                    </pre>
                  </Typography>

                  {subsection?.question_forms?.map(
                    (question_form, index: number) => (
                      <QuestionForm
                        key={index}
                        question_form={question_form}
                        index={index}
                        prf={prf}
                        setPrf={setPrf}
                        submitted={submitted}
                        employee={employee}
                      />
                    )
                  )}
                </Box>
              ))}
          </Box>
        ))}
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={() => navigate("/main/performance-review-list")}
        style={{ marginBottom: "15px", marginRight: "150px" }}
      >
        Continue Later
      </Button>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={handleSubmitPRF}
        style={{ marginBottom: "15px" }}
      >
        Submit Performance Review
      </Button>
    </Paper>
  );
};

export default PerformanceReviewQuestions;
