import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DownloadIcon from "../assets/img/DownloadIcon.svg";
import VerticalLine from "../assets/img/VerticalLine.svg";
import RemoveIcon from "../assets/img/RemoveIcon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Add from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import { saveAs } from "file-saver";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { AvatarIcon } from "./Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import ViewerPdf from "./PDFViewer/ViewerPdf";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import CustomTextEditor from "../components/TextEditor/CustomTextEditor";

const classes = {
  approved: {
    backgroundColor: "#64dd17",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  declined: {
    backgroundColor: "#d50000",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  reviewing: {
    backgroundColor: "#007FFF",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  inProgress: {
    backgroundColor: "#26a69a",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  notInitiated: {
    backgroundColor: "#FAA49D",
    color: "white",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  cell: {
    width: 200,
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  add: {
    display: "flex",
    marginLeft: "3rem",
  },
  addForm: {
    backgroundColor: "teal",
    display: "flex",
    marginTop: "0.9rem",
  },
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const typeOfForm = ["Hr", "Client", "Self-Assessment", "Manager/Team Lead"];

const CurrentPerformanceReview: React.FC<any> = ({
  data,
  setForms,
  setNewState,
}: {
  data: any;
  setForms: any;
  setNewState: any;
}) => {
  const [users, setUsers] = useState<any>();
  const [templates, setTemplates] = useState<any>();
  const [loader, setLoader] = useState<any>(false);
  const [filteredUsers, setFilteredUsers] = useState<any>();
  const [isOpenNewRow, setIsOpenNewRow] = useState<boolean>(true);
  //autocompleteType
  const [type, setType] = useState<any>(null);
  const [inputValueType, setInputValueType] = useState("");
  const [inputFormID, setInputFormID] = useState<number>();
  //autocompleteEmpl
  const [employee, setEmployee] = useState<any>(null);
  const [inputValueEmpl, setInputValueEmpl] = useState("");
  const [openDialogRemove, setOpenDialogRemove] = useState<boolean>(false);
  //preview
  const [state, setState] = useState<any>(null);
  const [openedDocument, setOpenedDocument] = useState<any>();
  const [stateHelper, setStateHelper] = useState<any>();
  const [disabled, setDisabled] = useState(true);
  const [feedback, setFeedback] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [deleteUser, setDeleteUser] = useState<any>();
  const [deleteItem, setDeleteItem] = useState<any>();
  const [editorContent, setEditorContent] = useState("");
  const [openActionPlan, setOpenActionPlan] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    setLoader(true);

    apiService.get(`${APP_URL}/users/names/`).then((resp) => {
      setUsers(resp);

      if (resp === undefined) console.error("Error users");
    });

    apiService.get(`${APP_URL}/performance-review/templates/`).then((resp) => {
      setTemplates(resp);
      if (resp === undefined) console.error("Error templates");
    });
  }, []);

  useEffect(() => {
    if (users !== undefined && templates !== undefined) setLoader(false);
  }, [users, templates]);

  const handleClassName = (item: any) => {
    switch (item.in_progress) {
      case true:
        return classes.inProgress;
      case false:
        return classes.reviewing;
      default:
        return classes.approved;
    }
  };

  const handleStatusView = (item: any) => {
    if (item.in_progress === null) {
      return "New";
    } else if (item.in_progress === true) {
      return "In progress";
    } else if (item.in_progress === false) {
      return "Completed";
    }
  };

  const handleNamePosition = (data: any) => {
    if (data?.is_client) return "Client";
    else if (data?.is_hr || data?.is_super_hr) return "Hr";
    else if (data?.is_regular_user) return "Employee";
    else if (data?.is_team_lead) return "Team lead";
  };

  const handleChangeTypeOfForm = (newValue: any) => {
    setInputFormID(
      templates?.filter((template: any) => template.name.includes(newValue))[0]
        .id
    );
    if (newValue === "Hr") {
      setFilteredUsers(
        users?.filter((item: any) => {
          return item.is_hr === true || item.is_super_hr === true;
        })
      );
    } else if (newValue === "Client") {
      setFilteredUsers(
        users?.filter((item: any) => {
          return (
            item.is_client === true &&
            item?.client?.id === data?.employee?.client?.id
          );
        })
      );
    } else if (newValue === "Self-Assessment") {
      setFilteredUsers(
        users?.filter((item: any) => {
          return item.is_regular_user === true && item.id === data?.employee.id;
        })
      );
    } else if (newValue === "Manager/Team Lead") {
      setFilteredUsers(
        users?.filter((item: any) => {
          return (
            item.is_regular_user === true || item.is_hr || item.is_super_hr
          );
        })
      );
    }
  };

  const addFormToPerfReviewForm = () => {
    const postData = {
      user: employee.id,
      request_id: data.id,
      template_id: inputFormID,
    };
    setLoader(true);
    apiService
      .post(`${APP_URL}/performance-review/pr_form/`, postData)
      .then(() => {
        setForms();
        setIsOpenNewRow(true);
        setLoader(false);
        Toast.fire({
          icon: "success",
          title: `Form for ${employee?.first_name} ${employee?.last_name} added successfully!`,
          background: "#a5dc86",
        });
      })
      .catch(() => {
        setIsOpenNewRow(true);
        setLoader(false);
        Toast.fire({
          icon: "error",
          title: `Problem with adding a form for ${employee?.user?.first_name} ${employee?.user?.last_name}`,
          background: "#f27474",
        });
      });
    setType(null);
  };
  const newForm = () => {
    navigate("/prf-template");
  };
  const onClickSend = (id: any) => {
    let patchData = {
      id: id,
      in_progress: true,
    };
    apiService
      .patch(`${APP_URL}/performance-review/pr_form/`, patchData)
      .then(() => {
        setForms();
      });
  };
  const onClickEdit = (id: any) => {
    navigate(`/prf-template/${id}`);
  };
  // const onClickEdit = (id: any) => {
  //   let patchData = {
  //     id: id,
  //     in_progress: true,
  //   };
  //   apiService
  //     .patch(`${APP_URL}/performance-review/pr_form/`, patchData)
  //     .then(() => {
  //       setForms();
  //     });
  // };
  const openModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/form/pdf2/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

    setState(id);
  };

  const openActionPlanModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/action-plan-new/pdf/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

      //http://127.0.0.1:8000/performance-review/action-plan-new/pdf/10/

    setState(id);
  };
  // function disableFeedbackButtonFunction(data: any) {
  //   for (let i = 0; i < data?.length; i++) {
  //     if (data[i].in_progress === true ) {
  //       return false;
  //       break;
  //     }
  //   }
  //   return true;
  // }

  const getNameOfDocument = (path: string) => {
    let splitted = path.split("/");
    let return_string: string = splitted[splitted.length - 1];
    return return_string;
  };

  const downloadFile = (id: any) => {
    const docName = getNameOfDocument(`${id}`);
    apiService
      .getFile(`${APP_URL}/performance-review/form/pdf2/${id}/`)
      .then((resp) => {
        saveAs(resp, `Form ${docName}`);
      });
  };

  const downloadActionPlan = (id: any) => {
    const docName = getNameOfDocument(`${id}`);
    apiService
      .getFile(`${APP_URL}/performance-review/action-plan-new/pdf/${id}/`)
      .then((resp) => {
        saveAs(resp, `Form ${docName}`);
      });
  };

  //http://127.0.0.1:8000/performance-review/action-plan-new/pdf/10/

  

  const handleDeleteForm = (id: any) => {
    apiService
      .deleteItem(`${APP_URL}/performance-review/pr_form/`, { id })
      .then(() => {
        Toast.fire({
          icon: "success",
          title: `Form for ${deleteUser?.first_name} ${deleteUser?.last_name} removed successfully!`,
          background: "#a5dc86",
        });
        setNewState(id);
      })
      .catch(() =>
        Toast.fire({
          icon: "error",
          title: `Problem with delete form for ${deleteUser?.first_name} ${deleteUser?.last_name}`,
          background: "#f27474",
        })
      );
  };

  const onClickSendFeedback = (id: any) => {
    let patchData = {
      id: id,
      feedback: feedback,
      is_active: false,
    };
    apiService
      .patch(`${APP_URL}/performance-review/pr_request/`, patchData)
      .then(() => {
        handleClose();
        setForms();
        navigate("/main/performance-review-history");
      });
  };
  const completeFeedback = (id: any) => {
    let patchData = {
      id: id,
      feedback: feedback,
      is_active: false,
    };
    apiService
      .patch(`${APP_URL}/performance-review/pr_request/`, patchData)
      .then(() => {
        setForms();
        navigate("/main/performance-review-history");
      });
  };

  const addActionPlan = () => {
    let actionPlanData = {
      user: data.employee.id,
      plan: editorContent,
    };
    if (editorContent == "") return;
    apiService
      .post(`${APP_URL}/performance-review/action-plan/`, actionPlanData)
      .then(() => {
        setOpenActionPlan(false);
      });
      navigate(`/employee/${data.employee.id}`)
  };

  const handleFile = (e: any) => {
    const files = e.target.files;
    const formData = new FormData();
    formData.append('performance_review_request', data.id);
    formData.append('document', files[0]);
    formData.append('user_profile', data.employee.id);

    apiService
      .post(
        `${APP_URL}/performance-review/action-plan-new/create/`,
        formData,
        'multipart/form-data'
      ).catch(function (res) {

        if (res === 'Bad Request') {
          Toast.fire({
            icon: "error",
            title: `Action plan already exists for this PR`,
            background: "#f27474",
          });
        } else if (res === 'Created') {
          Toast.fire({
            icon: "success",
            title: `Actions plan successfully added`,
            background: "#a5dc86",
          });
        }
      });

      
  };



  return (
    <Box sx={{ minWidth: 1050 }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }} align="center">
                Filling the form
              </TableCell>
              <TableCell style={{ width: 160 }} align="center">
                Type of form
              </TableCell>
              <TableCell style={{ width: 120 }} align="center">
                Date of new request
              </TableCell>
              <TableCell style={{ width: 120 }} align="center">
                Status
              </TableCell>
              <TableCell style={{ width: 140 }} align="center">
                Action
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                Preview
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                Download
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                Remove
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.is_active &&
              data?.review_forms?.map((item: any) => (
                <>
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" style={classes.cell}>
                      <Box style={classes.field}>
                        <AvatarIcon
                          src={
                            "data:image/jpeg;base64," + item?.user?.avatar_64
                          }
                          firstName={item?.user?.user?.first_name}
                          lastName={item?.user?.user?.last_name}
                        />
                        <Box ml={2}>
                          {item?.user?.user?.first_name}{" "}
                          {item?.user?.user?.last_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="center">
                      {item?.name}
                    </TableCell>
                    <TableCell style={{ width: 120 }} align="center">
                      {moment(item?.created_at).format("MMM Do YY")}
                    </TableCell>
                    <TableCell style={{ width: 120 }} align="center">
                      <Box style={handleClassName(item)}>
                        {handleStatusView(item)}
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        width: 140,
                      }}
                      align="center"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          disabled={item.in_progress !== null}
                          onClick={() => onClickSend(item.id)}
                        >
                          Send
                        </Button>
                        <img src={VerticalLine} />
                        <Button
                          disabled={item.in_progress !== null}
                          onClick={() => onClickEdit(item.id)}
                        >
                          Edit
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: 100 }} align="center">
                      <Button onClick={() => openModal(item?.id)}>
                        <VisibilityIcon style={{ color: "black" }} />
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: 100 }} align="center">
                      <Button onClick={() => downloadFile(item?.id)}>
                        <img src={DownloadIcon} />
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: 100 }} align="center">
                      <Button
                        onClick={() => {
                          setOpenDialogRemove(true);
                          setDeleteUser(
                            users.filter(
                              (user: any) => user.id === item?.user.id
                            )[0]
                          );
                          setDeleteItem(item?.id);
                        }}
                      >
                        <img src={RemoveIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            {openedDocument && (
              <Modal
                open={state}
                onClose={() => setState(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  marginTop: 5,
                  height: 680,
                  width: 680,
                  left: "30%",
                }}
              >
                <ViewerPdf file={`${openedDocument}`} />
              </Modal>
            )}
            {/* DIALOG */}
            <Dialog
              open={openDialogRemove}
              onClose={() => setOpenDialogRemove(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete this form for "}
                {deleteUser?.first_name} {deleteUser?.last_name}
                {"?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => setOpenDialogRemove(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDeleteForm(deleteItem);
                    setOpenDialogRemove(false);
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            {data.action_plan != null && (
              <>
                <TableRow
                  key={data.action_plan.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {/* <TableCell align="center">
                    {data?.employee?.user?.first_name}{' '}
                    {data?.employee?.user?.last_name}
                  </TableCell> */}

                  <TableCell align="center" style={classes.cell}>
                      <Box style={classes.field}>
                        <AvatarIcon
                          src={
                            "data:image/jpeg;base64," + data?.user?.avatar_64
                          }
                          firstName={data?.employee?.user?.first_name}
                          lastName={data?.employee?.user?.last_name}
                        />
                        <Box ml={2}>
                          {data?.employee?.user?.first_name}{" "}
                          {data?.employee?.user?.last_name}
                        </Box>
                      </Box>
                    </TableCell>

                  <TableCell align="center">Action plan</TableCell>
                  <TableCell align="center">
                    {moment(data?.action_plan?.created_at).format('MMM Do YY')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center">
                  <Button onClick={() => openActionPlanModal(data?.action_plan?.id)}>
                      <VisibilityIcon style={{ color: 'black' }} />
                    </Button>
                    {openedDocument && (
                      <Modal
                        open={state}
                        onClose={() => setState(null)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                          marginTop: 5,
                          height: 680,
                          width: 680,
                          left: '30%',
                        }}
                      >
                        <ViewerPdf file={`${openedDocument}`} />
                      </Modal>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => downloadActionPlan(data?.action_plan?.id)}>
                      <img src={DownloadIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isOpenNewRow && !loader && (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 1050, backgroundColor: "rgb(174 202 219)" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow></TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: 200 }} align="center">
                    <Autocomplete
                      getOptionLabel={(option) =>
                        `${option?.first_name} ${option?.last_name}`
                      }
                      inputValue={inputValueEmpl}
                      onInputChange={(event, newInputValue) => {
                        setInputValueEmpl(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={users}
                      fullWidth
                      sx={{ backgroundColor: "white" }}
                      renderInput={(params) => <TextField {...params} />}
                      value={employee}
                      onChange={(event: any, newValue: any) => {
                        setEmployee(newValue);
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Autocomplete
                      inputValue={inputValueType}
                      onInputChange={(event, newInputValue) => {
                        setInputValueType(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={templates.map((o: any) => o.name)}
                      fullWidth
                      sx={{ backgroundColor: "white" }}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(event: any, newValue: any) => {
                        handleChangeTypeOfForm(newValue);
                      }}
                    />
                  </TableCell>

                  <TableCell style={{ width: 120 }} align="center">
                    {moment(new Date()).format("MMM Do YY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }} align="center">
                    <Box style={classes.notInitiated}>Not initiated</Box>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center"></TableCell>
                  <TableCell style={{ width: 100 }} align="center"></TableCell>
                  <TableCell style={{ width: 100 }} align="center"></TableCell>
                  <TableCell style={{ width: 100 }} align="center"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={() => setIsOpenNewRow(true)}
            sx={{
              margin: "0.5rem 0",
              border: "0.15rem solid rgb(174 202 219)",
            }}
          >
            Close form
          </Button>
          <Button
            disabled={employee === null || inputValueType === "" ? true : false}
            sx={{
              margin: "0.5rem 0",
              border: "0.15rem solid rgb(174 202 219)",
            }}
            onClick={addFormToPerfReviewForm}
          >
            Add form
          </Button>
          <Button
            sx={{
              margin: "0.5rem 0",
              border: "0.15rem solid rgb(174 202 219)",
            }}
            onClick={newForm}
          >
            New form
          </Button>
        </>
      )}
      {isOpenNewRow && (
        <Box style={classes.add}>
          <Button onClick={() => setIsOpenNewRow(false)}>
            <Add />
          </Button>
        </Box>
      )}
      {loader && <CircularProgress style={{ marginTop: "10px" }} />}
      <div style={{ padding: "20px" }}>
        {data.feedback ? (
          ""
        ) : (
          <Button variant="outlined" onClick={handleOpen}>
            + Add Feedback
          </Button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add feedback and finish Performance Review
            </Typography>
            <TextField
              variant="filled"
              onChange={(e: any) => setFeedback(e.target.value)}
              sx={{ height: 400 }}
              fullWidth
            ></TextField>
            <Button
              variant="outlined"
              onClick={() => onClickSendFeedback(data.id)}
            >
              Send Feedback
            </Button>
          </Box>
        </Modal>
        
          <>
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => completeFeedback(data.id)}
            >
              Complete Performance Review
            </Button>



            {/* <Button
              variant="outlined"
              style={{ marginLeft: "15px" }}
              onClick={() => setOpenActionPlan(true)}
            >
              + Add Action Plan
            </Button> */}

            <Button
              variant="outlined"
              style={{ marginLeft: "15px" }}
              component="label"
              onChange={(e) => {
                handleFile(e);
              }}
              
          >
            ADD ACTION PLAN
            <input hidden accept="/*" type="file" />
          </Button>


            <Modal
              open={openActionPlan}
              onClose={() => setOpenActionPlan(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ my: 2 }}
                >
                  Add action plan and finish Performance Review
                </Typography>
                <CustomTextEditor
                  getEditorContent={setEditorContent}
                  content={editorContent}
                />
                <Button variant="outlined" onClick={addActionPlan}>
                  Add
                </Button>
              </Box>
            </Modal>
          </>
        
      </div>
    </Box>
  );
};

export default CurrentPerformanceReview;
