import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  width: 450,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const AddClient = ({ newClient, setClientUser }: { newClient: any, setClientUser: any }) => {
  const [open, setOpen] = useState<any>(false);
  const [client, setClient] = useState<string>("");
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);

  const handleCloseSnack = (
    err: string,
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    {
      err === "success" ? setOpenSnackSuccess(false) : setOpenSnackError(false);
    }
  };

  let data = {
    name: client,
  };
  const handleClick = () => {
    apiService
      .post(`${APP_URL}/users/client/`, data)
      .then(() => {
        newClient(data.name);
        setClientUser(data.name);
        Toast.fire({
          icon: "success",
          title: "Client successfully added!",
          width: 480,
          background: "#a5dc86",
        });
        setOpen(false);
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: "Error Adding Client!",
          background: "#f27474",
        });
      });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Add New Client</Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Box sx={{ marginTop: "15px" }}>
              <div>
                <Typography variant="subtitle1">Add New Client:</Typography>
              </div>
              <div>
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  name="client"
                  placeholder="Client name"
                  onChange={(e) => setClient(e.target.value)}
                />
              </div>
              <div>
                <Button
                  sx={{ marginTop: "1.5rem" }}
                  variant="contained"
                  startIcon={<AddCircleOutline />}
                  onClick={handleClick}
                >
                  ADD
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddClient;
