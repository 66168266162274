import { Draggable, Droppable } from "react-beautiful-dnd";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import moment from "moment";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const heardFromDict = {
  Recommendation: "#109CF13D",
  Sales: "#8157ED",
  Regruter: "#FAEEBE",
  Linkedin: "#2ED47A8F",
  Infostud: "#ff0000",
  "Career Development Center": "#5b1be4",
};

export default function CandidateColumn(props: any) {
  function darkenCard(e: any) {
    e.currentTarget.style.background = "rgba(0, 0, 145, 0.1)";
  }
  function lightenCard(e: any) {
    e.currentTarget.style.background = "white";
  }

  function formatDate(date: Date) {
    const dateStr =
      moment(date).fromNow().split(" ")[0] +
      moment(date).fromNow().split(" ")[1][0];

    if (dateStr === "am" || dateStr === "af") {
      return "now";
    }
    if (dateStr === "anh") {
      return "1h";
    }

    if (dateStr === "ad") {
      return "1d";
    }
    return dateStr;
  }

  return (
    <Draggable
      key={props.column.displayIndex}
      draggableId={props.column.displayIndex.toString()}
      index={props.column.displayIndex}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            ...provided.draggableProps.style,
            marginRight: 8,
            background: "rgba(0,0,0,0.1)",
            borderRadius: "5px",
          }}
        >
          <div {...provided.dragHandleProps}>
            <p
              style={{ userSelect: "none", float: "left", fontWeight: "bold" }}
            >
              <span>
                {" "}
                <DragIndicatorIcon
                  style={{ float: "left", fontSize: 22, marginLeft: 10 }}
                />
              </span>
              {props.column.job_application_phase.name}
            </p>
          </div>
          <Droppable
            droppableId={props.column.displayIndex.toString()}
            key={props.column.displayIndex}
            type="droppableCandidate"
          >
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver
                      ? "rgba(0,0,0,0.2)"
                      : "rgba(0,0,0,0.1)",
                    padding: 10,
                    width: 250,
                    minHeight: 500,
                    paddingTop: 10,
                  }}
                >
                  {props.column.candidates?.map(
                    (candidate: any, index: any) => {
                      return (
                        <Link
                          to={{
                            pathname: `/main/candidate-details/${candidate.id}`,
                          }}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <Draggable
                            key={candidate.id}
                            draggableId={
                              props.column.name +
                              "-" +
                              candidate.first_name +
                              "-" +
                              candidate.last_name
                            }
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  onMouseEnter={darkenCard}
                                  onMouseLeave={lightenCard}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    padding: 0,
                                    margin: "0 0 8px 0",
                                    minHeight: "50px",
                                    width: "100%",
                                    display: "block",
                                    borderRadius: "5px",
                                    background: "#FFFFFF",
                                    boxShadow:
                                      "0px 14px 14px rgba(0, 0, 0, 0.25)",
                                    marginBottom: 5,
                                    backgroundColor: "white",
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <Tooltip
                                    title={candidate.heard_for_position_by}
                                  >
                                    <div
                                      style={{
                                        margin: 5,
                                        height: "10px",
                                        maxHeight: "10px",
                                        borderRadius: "10px",
                                        float: "left",
                                        backgroundColor:
                                          candidate.heard_for_position_by ===
                                          "Recommendation"
                                            ? heardFromDict["Recommendation"]
                                            : candidate.heard_for_position_by ===
                                              "Sales"
                                            ? heardFromDict["Sales"]
                                            : candidate.heard_for_position_by ===
                                              "Regruter"
                                            ? heardFromDict["Regruter"]
                                            : candidate.heard_for_position_by ===
                                              "Linkedin"
                                            ? heardFromDict["Linkedin"]
                                            : candidate.heard_for_position_by ===
                                              "Infostud"
                                            ? heardFromDict["Infostud"]
                                            : candidate.heard_for_position_by ===
                                              "Career Development Center"
                                            ? heardFromDict[
                                                "Career Development Center"
                                              ]
                                            : "black",
                                        minWidth: "35px",
                                      }}
                                    ></div>
                                  </Tooltip>
                                  <AccessTimeIcon
                                    style={{
                                      float: "right",
                                      fontSize: "14px",
                                      margin: "6 5 5 0",
                                      color: "gray",
                                    }}
                                  />
                                  <p
                                    style={{
                                      float: "right",
                                      fontSize: "12px",
                                      margin: 5,
                                      color: "gray",
                                    }}
                                  >
                                    {formatDate(candidate.created_at)}
                                  </p>
                                  <Tooltip title="Never viewed">
                                    <VisibilityOffIcon
                                      style={{
                                        float: "right",
                                        fontSize: "26px",
                                        padding: 4,
                                        color: "blue",
                                        display:
                                          candidate.is_viewed === true
                                            ? "none"
                                            : "block",
                                      }}
                                    />
                                  </Tooltip>
                                  <br />
                                  <p>
                                    {candidate.first_name +
                                      " " +
                                      candidate.last_name}
                                  </p>
                                  <br />
                                </div>
                              );
                            }}
                          </Draggable>
                        </Link>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
}
