import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";


import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from "axios";
import { APP_URL } from "../utils/constants";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SetPasswordExpired from "./SetPasswordExpired";
import Swal from "sweetalert2";
const theme = createTheme();

export default function SetPasswordPage() {
  const [isEqual, setIsEqual] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidJWT, setIsValidJWT] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const params = useParams<{ token: string }>();
  const navigate = useNavigate();

  let tokenValue = params.token;

  const setPasswordFunction = () => {
    if (password !== confirmPassword) {
      setIsEqual(false);
    } else {
      axios
        .patch(`${APP_URL}/users/setpassword/${params.token}/`, {
          password: password,
        })
        .then((resp) => {
          if (resp.data.message === `failed`)
                Swal.fire(resp.data.reason, "", "error");
              else Swal.fire("Password Changed", "", "success").then(() => {navigate("/login");});
          
        });
    }
  };

  const togglePasswordVisibility = () => {
    setIsVisible(!isVisible);
    console.log(isVisible);
  };

  useEffect(() => {
    axios
    .get(`${APP_URL}/users/checktoken/${params.token}/`)
    .then((resp) => {
      console.log(resp.status)
      if(resp.status === 200){
        setIsValidJWT(true);
      }
    }).catch(function (error) {
      if (error.response) {
        if(error.response.status === 403)
        {
          console.log(error.response.status);
          console.log(error.response.data);
          setIsValidJWT(false);
        }
      }
    });
    
  }, []);

  return (
    <>
    { isValidJWT?(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <CardMedia
              component="img"
              height="200"
              image="/static/images/QCERRIS.svg"
            />
          </Box>
          <Typography variant="h6" mt={-6}>
            Welcome to QCAAS platform!
          </Typography>
          <Typography variant="subtitle1" mb={5}>
            Please set your password and get started.
          </Typography>

          <Avatar
            alt="Remy Sharp"
            src=""
            sx={{
              width: 150,
              height: 150,
              marginTop: -3,
              marginBottom: 2,
            }}
          />

          <Typography color="primary" variant="h6" sx={{ mt: 2, mb: -2 }}>
            Change password
          </Typography>

          <TextField
            sx={{ mt: 3 }}
            fullWidth
            size="medium"
            id="demo-helper-text-aligned"
            label="Set password"
            name="set_password"
            type={isVisible?("text"):("password")}
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPasswordFunction();
              }
            }}
            InputProps={{
              endAdornment: (
                <Button  onClick={togglePasswordVisibility}>
                  {isVisible?(<VisibilityIcon/>):(<VisibilityOffIcon/>)}
                  
                </Button>
              ),
            }}
          />
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            size="medium"
            id="demo-helper-text-aligned"
            label="Confirm password"
            name="confirm_password"
            type={isVisible?("text"):("password")}
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPasswordFunction();
              }
            }}
            InputProps={{
              endAdornment: (
                <Button  onClick={togglePasswordVisibility}>
                  {isVisible?(<VisibilityIcon/>):(<VisibilityOffIcon/>)}
                  
                </Button>
              ),
            }}
          />
          <Typography variant="subtitle1" color="red">
            {isEqual ? " " : "*Passwords don't match"}
          </Typography>
          <Button
            sx={{ mt: 3, mb: 5, p: 1.5 }}
            fullWidth
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            onClick={setPasswordFunction}
          >
            Confirm
          </Button>
        </Box>
      </Container>
    </ThemeProvider>):(<SetPasswordExpired/>)}
    </>
  );
}
