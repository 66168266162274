import { Box, Button } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import apiService from "../../../services/apiService";
import { APP_URL } from "../../../utils/constants";
import { IQuestionForm, ISubsection } from "../../PerformanceReviewQuestions/utils/prf.model";
import { classes } from "../../PerformanceReviewQuestions/utils/styles";
import { IFunctionProps } from "../interfaces/functions.model";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import QuestionForm from "./QuestionForm";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface IProps {
  subsection: ISubsection;
  submitted: boolean;
}

const Subsection = ({ secIndex, subsecIndex, subsection, submitted, prf, setPrf }: IFunctionProps & IProps) => {
  const changeSubsectionTitle = async (subsectionId: number, newTitle: string) => {
    await apiService
      .patch(`${APP_URL}/performance-review/subsection/`, {
        id: subsectionId,
        title: newTitle,
      })
      .then((res: ISubsection) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: prevPrf.sections.map((sec, index) => {
            if (index === secIndex) {
              return {
                ...sec,
                subsections: sec.subsections.map((sub, index) => {
                  if (index === subsecIndex) {
                    return res;
                  }
                  return sub;
                }),
              };
            }
            return sec;
          }),
        }));
      });
  };

  const deleteSubsection = async () => {
    await apiService
      .deleteItem(`${APP_URL}/performance-review/subsection/`, {
        id: prf.sections[secIndex].subsections[subsecIndex].id,
      })
      .then((res) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: [
            ...prevPrf.sections.slice(0, secIndex),
            {
              ...prevPrf.sections[secIndex],
              subsections: [
                ...prevPrf.sections[secIndex].subsections.slice(0, subsecIndex),
                ...prevPrf.sections[secIndex].subsections.slice(subsecIndex + 1),
              ],
            },
            ...prevPrf.sections.slice(secIndex + 1),
          ],
        }));
      });
  };

  const addNewQuestion = async () => {
    const targetQfs = prf.sections[secIndex].subsections[subsecIndex].question_forms;
    const newQuestionForm: IQuestionForm = {
      answer: "",
      question: "",
      is_text: true,
      title: "",
      ordering_index: (targetQfs[targetQfs.length - 1]?.ordering_index ?? 0) + 1,
    };
    await apiService
      .post(`${APP_URL}/performance-review/subsection/addquestion/`, {
        subsectionID: prf.sections[secIndex].subsections[subsecIndex].id,
        question_form: newQuestionForm,
      })
      .then((res: IQuestionForm) => {
        setPrf((prevPrf) => ({
          ...prevPrf,
          sections: [
            ...prevPrf.sections.slice(0, secIndex),
            {
              ...prevPrf.sections[secIndex],
              subsections: [
                ...prevPrf.sections[secIndex].subsections.slice(0, subsecIndex),
                {
                  ...prevPrf.sections[secIndex].subsections[subsecIndex],
                  question_forms: [...prevPrf.sections[secIndex].subsections[subsecIndex].question_forms, res],
                },
                ...prevPrf.sections[secIndex].subsections.slice(subsecIndex + 1),
              ],
            },
            ...prevPrf.sections.slice(secIndex + 1),
          ],
        }));
      });
  };

  return (
    <Draggable draggableId={`sec_${secIndex}_draggableSubsec_${subsecIndex}`} index={subsecIndex} key={subsecIndex}>
      {(providedSubsecDrag, snapshot) => (
        <Box sx={classes.subsection} ref={providedSubsecDrag.innerRef} {...providedSubsecDrag.draggableProps}>
          {/* SUBSECTION TITLE */}
          <Box key={subsection.id}>
            <TextField
              variant="standard"
              sx={{
                width: "20vw",
                ml: 5,
                mt: 2,
                fontSize: 20,
                justifyContent: "flex-start",
                textAlign: "left",
              }}
              placeholder="Subsection title..."
              defaultValue={subsection.title}
              onBlur={(e) => changeSubsectionTitle(prf.sections[secIndex].subsections[subsecIndex].id, e.target.value)}
              error={submitted && subsection.title.trim() === ""}
              helperText={submitted && subsection.title.trim() === "" ? "This field is required" : ""}
            />
            <IconButton onClick={deleteSubsection} sx={{ marginTop: 2.5, marginLeft: 3, width: 40, height: 40 }}>
              <DeleteIcon />
            </IconButton>
            <IconButton
              {...providedSubsecDrag.dragHandleProps}
              sx={{ marginTop: 2.5, marginLeft: 3, width: 40, height: 40 }}
            >
              <DragIndicatorIcon />
            </IconButton>
          </Box>
          {/* SUBSECTION TITLE */}
          <Droppable
            droppableId={`sec_${secIndex}-droppableSubsec_${subsecIndex}`}
            type={`QUESTIONS_${subsecIndex}`}
            key={subsecIndex}
          >
            {(providedQfDrop, snapshot) => (
              <Box {...providedQfDrop.droppableProps} ref={providedQfDrop.innerRef}>
                {subsection?.question_forms?.map((question_form, qfIndex: number) => (
                  <QuestionForm
                    prf={prf}
                    setPrf={setPrf}
                    secIndex={secIndex}
                    subsecIndex={subsecIndex}
                    qfIndex={qfIndex}
                    question_form={question_form}
                    submitted={submitted}
                    key={qfIndex}
                  />
                ))}
                {providedQfDrop.placeholder}
              </Box>
            )}
          </Droppable>

          <Button
            sx={{ mt: 3, alignSelf: "center" }}
            variant="contained"
            onClick={addNewQuestion}
            style={{ marginBottom: "15px" }}
            startIcon={<AddCircleIcon />}
          >
            Add Question
          </Button>
        </Box>
      )}
    </Draggable>
  );
};

export default Subsection;
