import React, {useState} from 'react';
import { ChromePicker} from 'react-color';
import { colorPicker } from '../services/service';




export default function ColorChoise() {

    const [colortheme, setColortheme] = useState<string>();
    const [showColorPicker, setShowColorPicker] = useState(false);
    
   
  

    return(
        <div className="ColorChoise" >
            <button onClick={() => setShowColorPicker(showColorPicker => !showColorPicker)}>
                {showColorPicker ? ' Close color picker' : 'Pick a color'}
            </button>
            {
                showColorPicker && (
                    <ChromePicker
                    color={colortheme}
                    onChange={upcolor => setColortheme(upcolor.hex)}
                    />
                )}
              <button 
            type="submit"
            onClick={() => {
                console.log(colortheme)
                colorPicker(colortheme)
            }}
        >
            Confrim Color theme
        </button>
        </div>
    );
     
    
}
