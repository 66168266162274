import * as React from "react";
import { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import { Avatar, Button, ListItemIcon, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../store/actions";
import { AvatarIcon } from "./Avatar";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";

let AvatarChange: any;

export function ChangeAvatar(newAvatar: any) {
  AvatarChange(newAvatar);
}

function AvatarFunc() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [iconView, setIconView] = useState<any>();
  AvatarChange = setIconView;
  let navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  let currentUser = useSelector((state: any) => state.reducer.user);
  var urlProfile = `/employee/${currentUser?.id}`;
  const dispatch = useDispatch();

  const logout = () => {
    apiService.post(`${APP_URL}/auth/logout/`, {});
    dispatch(userLogout(undefined));
    Cookies.remove("myCookie", { path: "/" });
    window.location.href = window.location.origin + "/login";
  };

  const redirectToProfile = () => {
    navigate(urlProfile);
  };

  useEffect(() => {
    var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (currentUser?.avatar_64) {
      if (base64regex.test(currentUser.avatar_64)) {
        setIconView("data:image/jpeg;base64," + currentUser?.avatar_64);
      } else {
        setIconView(currentUser?.avatar_64);
      }
    }
  }, [currentUser?.avatar_64]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings" arrow>
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AvatarIcon
              src={iconView}
              firstName={currentUser?.user?.first_name}
              lastName={currentUser?.user?.last_name}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            // width: 150,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => redirectToProfile()}>
          <Avatar /> Profile
        </MenuItem>

        <Divider />
        {/* <Button onClick={() => redirectToUpdateInfo()}>
          <EditIcon />
          Edit Profile
        </Button>
        <Divider /> */}
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default AvatarFunc;
