import Paper from "@mui/material/Paper";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import moment from "moment";
import {
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  IconButton,
} from "@mui/material";
import Cookies from "js-cookie";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PerformanceReviewRequestDetail from "../components/PerformanceReviewRequestDetail";
import { useSelector } from "react-redux";
import { AvatarIcon } from "./Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { cutFullName } from "../utils/functions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "@mui/material/Modal";
import ViewerPdf from "./PDFViewer/ViewerPdf";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TablePagination from "@mui/material/TablePagination";
import { createLogger } from "redux-logger";
import { decodeToken } from "react-jwt";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DeleteOutlined } from "@mui/icons-material";

const classes = {
  modal: {
    width: "550px",
    display: "flex",
    justifyContent: "space-between",
  },
  completed: {
    backgroundColor: "#2ED47A99",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  new: {
    backgroundColor: "#109CF1",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  declined: {
    backgroundColor: "#F7685B99",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  reviewing: {
    backgroundColor: "#B89CFB",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  inProgress: {
    backgroundColor: "#FFD590",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  cell: {
    color: "white",
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  deleted: {
    backgroundColor: "#F7685B99",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  }
};

const PerformanceReviewList: React.FC<any> = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [pagination, setPagination] = useState({
    count: null,
    page: 1,
    page_size: 10,
    next: null,
    previous: null,
    search: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newState, setNewState] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [user, setUser] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [denyDescription, setDenyDescription] = useState("");
  const [openModalReason, setOpenModalReason] = useState(false);
  const [loader, setLoader] = useState(true);
  const [statusLoader, setStatusLoader] = useState<any>();
  const [modalData, setModalData] = useState<any>();
  const [searched, setSearched] = useState<string>("");
  const [formDataErrors, setFormDataErrors] = useState<any>();
  const [state, setState] = useState<any>(null);
  const [openedDocument, setOpenedDocument] = useState<any>();
  const [denyReasonModal, setDenyReasonModal] = useState<any>(false);
  const [allUsers, setAllUsers] = useState([]);
  const [reset, setReset] = useState<boolean>(true)
  const [show, setShow] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [prId, setPrId] = useState<number>()
  const [formError, setFormError] = useState<boolean>(false)

  const handleCloseDelete = () => {
    setShow(false)
    setFormError(false)}

  const handleShowDelete = (id: number) => {
    setShow(true)
    setPrId(id)
  };

  let navigate = useNavigate();

  let jwt = require("react-jwt");
  let cookie = Cookies.get("myCookie");
  let userId: number;
  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }

  let currentUser = useSelector((state: any) => state.reducer.user);
  let loadItems = (page: number, pageSize: number, search: String) => {
    apiService
      .get(
        `${APP_URL}/performance-review/pr_list_pagination/?page=${page}&page_size=${pageSize}&search=${search}`
      )
      .then((resp) => {
        setData(resp.data);
        setPagination(resp.meta);
        setRowsPerPage(pageSize);
        let statusRows: any = [];
        for (let i = 0; i < resp.data.length; i++) {
          statusRows = [...statusRows, false];
        }
        setStatusLoader(statusRows);
        setOriginalData(resp.data);
        setLoader(false);
      });
  };
  useEffect(() => {
    const previousPagination = JSON.parse(localStorage.getItem('info'))
    loadAllUsersExceptClient();
    loadItems(previousPagination.page, previousPagination.page_size, previousPagination.search);
    setUser(currentUser);
  }, [newState, reset]);

  const handleClassName = (item: any) => {
    if (item.approved == null) {
      return classes.new;
    } else if (item.approved === true && item.is_active === false) {
      return classes.completed;
    } else if (item.approved === false) {
      return classes.declined;
    } else if (
      item.is_active === true &&
      (item.client_review_form?.in_progress === false ||
        item.employee_review_form?.in_progress === false ||
        item.team_lead_review_form?.in_progress === false)
    ) {
      return classes.reviewing;
    } else if (item.approved === true) {
      return classes.inProgress;
    } else if (item.deleted_by != null){
      return classes.deleted
    }
  };

  const openViewModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/form/pdf2/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

    setState(id);
  };

  const deleteTravel = (item: any) => {
    let id = item.id;
    apiService
      .deleteItem(`${APP_URL}/performance-review/request/`, { id })
      .then(() => {
        setNewState(!newState);
      });
  };

  const handlePRApprove = (item: any, progress: any, index: any) => {
    statusLoader[index] = !statusLoader[index];
    setStatusLoader({ ...statusLoader });
    apiService
      .patch(`${APP_URL}/performance-review/pr_request/`, {
        id: item.id,
        approved: progress,
        // is_active: progress,
        deny_reason: denyDescription,
      })
      .then((resp) => {
        setNewState(!newState);
        setOpenModal(false);
        setFormDataErrors(false);
        setOpenModalReason(false);
      });
  };
  const handlePR = (item: any, progress: any) => {
    if (denyDescription === undefined || denyDescription === "") {
      setFormDataErrors(true);
      return;
    }
    apiService
      .patch(`${APP_URL}/performance-review/pr_request/`, {
        id: item.id,
        approved: progress,
        // is_active: progress,
        deny_reason: denyDescription,
      })
      .then((resp) => {
        setNewState(!newState);
        setOpenModal(false);
        setFormDataErrors(false);
        setDenyDescription("");
        // statusLoader[index] = !statusLoader[index];
        // setStatusLoader({ ...statusLoader });a
        setOpenModalReason(false);
      });
  };

  function disableQuestionsButton(id: any) {}

  const goToQuestions = (id: any) => {
    navigate(`/prf-questions/${id}`);
  };

  const goToTheDetail = (id: any) => {
    localStorage.setItem('info', JSON.stringify(pagination));
    navigate(`/performance-review-request-detail/${id}`);
  };

  const goToTheProfile = (id: any) => {
    navigate(`/employee/${id}`);
  };

  const handleStatusView = (item: any) => {
    if (item.approved == null) {
      return "New";
    } else if (item.approved === true && item.is_active === false) {
      return "Completed";
    } else if (item.approved === false) {
      return "Declined";
    } else if (item.is_active && !item.in_progress) {
      return "Pending";
    } else if (item.approved === true) {
      return "In progress";
    } else if (item.deleted_by != null) {
      return "Deleted"
    }
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalData.filter((dataItem) => {
      return `${dataItem?.employee?.user.first_name} ${dataItem?.employee?.user.last_name}`
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const openModalDialog = (item: any) => {
    setModalData(item);
    setOpenModal(true);
  };

  const openDenyModal = (item: any) => {
    setModalData(item);
    setOpenModalReason(true);
    setFormDataErrors(false);
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    loadItems(newPage + 1, rowsPerPage, pagination.search);
  };

  const openDenyReasonModal = (item: any) => {
    setModalData(item);
    setDenyReasonModal(true);
  };

  const closeDenyReasonModal = () => {
    setDenyReasonModal(false);
  };
  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    loadItems(1, parseInt(event.target.value, 10), "");
  };

  let loadAllUsersExceptClient = () => {
    apiService.get(`${APP_URL}/users/users-except-client/`).then((res) => {
      setAllUsers(res);
    });
  };

  const handleDelete = () => {
    if (deleteReason === undefined || deleteReason === '') {
      setFormError(true);
    } else {
      apiService.deleteItem(
        `${APP_URL}/performance-review/pr_request/${prId}/delete`,
        { delete_reason: deleteReason }
      );
      setReset(!reset);
      setShow(false);
    }
  };

  return loader ? (
    <CircularProgress style={{ marginTop: "200px" }} />
  ) : (
    <Box
      sx={{
        pt: "24px",
        marginleft: "-10%",
        mt: 10,
        ml: 5,
        mr: 5,
        justifyContent: "center",
        right: "100vw",
      }}
    >
      <Autocomplete
        onChange={(event, value) => {
          loadItems(1, 10, value);
        }}
        autoHighlight={true}
        disableClearable
        clearOnEscape={true}
        id="search"
        options={[
          ...new Set(
            allUsers
              .map(
                (option) => `${option.user.first_name} ${option.user.last_name}`
              )
              .sort()
          ),
        ]}
        renderInput={(params) => <TextField {...params} label="Search" />}
      />
      <Button
        onClick={() => {
          loadItems(1, 10, "");
        }}
      >
        Clear Search
      </Button>
      <TableContainer component={Paper}>
        <Grid></Grid>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Requested by</TableCell>
              <TableCell align="center">Requested for</TableCell>
              <TableCell align="center">Reason</TableCell>
              <TableCell align="center">Date of new request</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">End date</TableCell>
              <TableCell align="center">
                {user?.is_hr || user?.is_super_hr ? "Form" : "Questions"}
              </TableCell>
              {user?.is_hr || user?.is_super_hr ? (
                <TableCell align="center">Properties</TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any, index) => (
              <>
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <Box style={classes.field}>
                      <AvatarIcon
                        src={
                          "data:image/jpeg;base64," + item?.requester?.avatar_64
                        }
                        firstName={item?.requester.user.first_name}
                        lastName={item?.requester.user.last_name}
                      />
                      <Typography marginLeft={0.5}>
                        {cutFullName(item?.requester.user, 25)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center" style={classes.cell}>
                    <Box style={classes.field}>
                      <AvatarIcon
                        src={
                          "data:image/jpeg;base64," + item?.employee?.avatar_64
                        }
                        firstName={item?.employee?.user?.first_name}
                        lastName={item?.employee?.user?.last_name}
                      />
                      <Button
                        onClick={
                          user?.is_hr || user?.is_super_hr
                            ? () => goToTheProfile(item?.employee?.id)
                            : () => goToTheProfile(item?.employee?.id)
                        }
                        style={{ color: "black" }}
                      >
                        {cutFullName(item?.employee?.user, 25)}
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{item?.reason?.reason}</TableCell>
                  <TableCell align="center">
                    {moment(item?.created_at).format("MMM Do YY")}
                  </TableCell>
                  <TableCell align="center">
                    <Box style={handleClassName(item)}>
                      {handleStatusView(item)}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {user?.is_hr || user?.is_super_hr ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          defaultValue={dayjs(item?.end_date)}
                          sx={{ width: 150 }}
                          onChange={(e: any) => {
                            const newData: any[] = [];
                            data.forEach((val: any) =>
                              newData.push(Object.assign({}, val))
                            );
                            newData[index].end_date = moment(String(e)).format(
                              "YYYY-MM-DD"
                            );
                            setData(newData);
                          }}
                          onAccept={() => {
                            apiService.patch(
                              `${APP_URL}/performance-review/pr_request/end_date/${data[index].id}`,
                              {
                                hr_defined_end_date: data[index].end_date,
                              }
                            );
                          }}
                        />
                      </LocalizationProvider>
                    ) : (
                      moment(item?.created_at).format("MMM Do YY")
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div style={{ display: "flex" }}>
                      {!(item.is_active && !item.in_progress) ||
                      item.form_id === undefined ? (
                        !item.in_progress ? (
                          <>
                            {((currentUser.is_super_hr || currentUser.is_hr) &&
                              item?.approved !== null) ||
                            (!currentUser.is_super_hr &&
                              !currentUser.is_hr) ? null : statusLoader[
                                index
                              ] ? (
                              <CircularProgress
                                style={{
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : (
                              <>
                                <Button
                                  disabled={item?.approved !== null}
                                  onClick={() =>
                                    handlePRApprove(item, true, index)
                                  }
                                >
                                  <CheckIcon />
                                </Button>
                                <Button
                                  disabled={item?.approved !== null}
                                  onClick={() => openDenyModal(item)}
                                >
                                  <CloseIcon />
                                </Button>
                                <Button
                                  onClick={() => openModalDialog(item)}
                                  title={"Open modal"}
                                >
                                  <FormatAlignJustifyIcon />
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <Button
                            onClick={() => goToQuestions(item?.form_id)}
                            title={"Go to the questions"}
                            // disabled={!item?.form.in_progress}
                          >
                            <FormatAlignJustifyIcon />
                          </Button>
                        )
                      ) : (
                        <Button onClick={() => openViewModal(item?.form_id)}>
                          <VisibilityIcon style={{ color: "black" }} />
                        </Button>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {(() => {
                      if (user?.is_super_hr || user?.is_hr) {
                        return (
                          <div>
                            {(() => {
                              if (item.approved === false) {
                                return (
                                  <div>
                                    <Button
                                      onClick={() => openDenyReasonModal(item)}
                                      title={"Open properties"}
                                      disabled={
                                        item.approved === true &&
                                        item.is_active === false
                                      }
                                    >
                                      <FormatListBulletedIcon />
                                    </Button>
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <Button
                                      onClick={() => goToTheDetail(item.id)}
                                      title={"Open properties"}
                                      disabled={
                                        (item.approved === true &&
                                          item.is_active === false) ||
                                        item.approved === null
                                      }
                                    >
                                      <FormatListBulletedIcon />
                                    </Button>
                                  </div>
                                );
                              }
                            })()}
                          </div>
                        );
                      }
                    })()}
                  </TableCell>
                  {user?.is_hr || user?.is_super_hr ? 
                  (
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {handleShowDelete(item.id)}}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </TableCell>
                  ) : ('')}
                    
                </TableRow>
                <Dialog
                  open={openDeleteModal}
                  onClose={() => {
                    setOpenDeleteModal(false);
                  }}
                  style={{ alignItems: "center" }}
                >
                  <DialogTitle>
                    Performance review will be deleted, are you sure?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      style={{ background: "#F7685B", color: "black" }}
                      onClick={() => {
                        setOpenDeleteModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ background: "#2ED47A", color: "black" }}
                      onClick={() => {
                        deleteTravel(item);
                        setOpenDeleteModal(false);
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={show}
                  onClose={handleCloseDelete}
                  sx={{
                    '& .MuiBackdrop-root': { backgroundColor: 'transparent' },
                  }}
                >
                  <DialogTitle>Delete Reason</DialogTitle>
                  <DialogContent>
                    <Grid style={{ paddingTop: '5px' }}>
                      <Typography>
                        Reason for deleting performance review:
                      </Typography>
                      <TextField
                        sx={{ mt: 1, width: "100%" }}
                        size="small"
                        style={{ paddingBottom: '20px' }}
                        onChange={(e: any) => setDeleteReason(e.target.value)}
                        rows={5}
                        multiline
                        error={formError}
                        helperText={formError && 'Delete reason is required'}
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{ background: '#F7685B', color: 'black' }}
                      onClick={handleCloseDelete}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ background: '#2ED47A', color: 'black' }}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={pagination.count}
          page={pagination.page - 1}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{ alignItems: "center" }}
      >
        <DialogTitle>Performance review request details</DialogTitle>
        <DialogContent>
          <Grid style={classes.modal}>
            <Grid>
              <AvatarIcon
                src={
                  "data:image/jpeg;base64," + modalData?.requester?.avatar_64
                }
                firstName={modalData?.requester?.user?.first_name}
                lastName={modalData?.requester?.user?.last_name}
                style={{ width: 75, height: 75 }}
              />
              <Box style={{ display: "flex" }}>
                <Typography>First name:</Typography>
                <Typography>
                  {modalData?.requester?.user?.first_name}
                </Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography>Last name:</Typography>
                <Typography>{modalData?.requester?.user?.last_name}</Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography>Client: </Typography>
                <Typography>{modalData?.requester?.client?.name}</Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography>Position:</Typography>
                <Typography> {modalData?.requester?.position?.name}</Typography>
              </Box>
            </Grid>
            <Grid>
              <Typography style={{ paddingTop: "70px", fontWeight: "600" }}>
                Requesting for
              </Typography>
            </Grid>
            <Grid>
              <AvatarIcon
                src={"data:image/jpeg;base64," + modalData?.employee?.avatar_64}
                firstName={modalData?.employee?.user?.first_name}
                lastName={modalData?.employee?.user?.last_name}
                style={{ width: 75, height: 75 }}
              />
              <Box style={{ display: "flex" }}>
                <Typography>First name:</Typography>
                <Typography>{modalData?.employee?.user?.first_name}</Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography>Last name:</Typography>
                <Typography>{modalData?.employee?.user?.last_name}</Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography>Client: </Typography>
                <Typography>{modalData?.employee?.client?.name}</Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography>Position:</Typography>
                <Typography>{modalData?.employee?.position?.name}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid style={{ paddingTop: "50px" }}>
            <h2>Reason for requesting a performance review:</h2>
            <Typography>{modalData?.reason?.reason}</Typography>
            <h2>Description:</h2>
            <Typography>{modalData?.description}</Typography>
            <h2>Reason of deny a performance review:</h2>
            <TextField
              sx={{ mt: 1 }}
              size="small"
              onChange={(e: any) => setDenyDescription(e.target.value)}
              style={{ paddingBottom: "20px" }}
              error={formDataErrors}
              helperText={formDataErrors && "Deny Reason is required"}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {modalData?.approved == null &&
            (user?.is_hr || user?.is_super_hr) && (
              <>
                <Button
                  disabled={!denyDescription}
                  style={{ background: "#F7685B", color: "black" }}
                  onClick={() => handlePR(modalData, false)}
                >
                  Deny
                </Button>
                <Button
                  style={{ background: "#2ED47A", color: "black" }}
                  onClick={() => handlePRApprove(modalData, true, null)}
                >
                  Accept
                </Button>
              </>
            )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalReason}
        onClose={() => {
          setOpenModalReason(false);
          setFormDataErrors(false);
        }}
        style={{ alignItems: "center" }}
      >
        <DialogTitle>Performance review deny details</DialogTitle>
        <DialogContent>
          <Grid style={{ paddingTop: "50px" }}>
            <Typography>Reason of deny a performance review:</Typography>
            <TextField
              sx={{ mt: 1 }}
              size="small"
              onChange={(e: any) => {
                setDenyDescription(e.target.value);
              }}
              style={{ paddingBottom: "20px" }}
              error={formDataErrors}
              helperText={formDataErrors && "Deny Reason is required"}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#F7685B", color: "black" }}
            onClick={() => {
              setOpenModalReason(false);
              setFormDataErrors(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ background: "#2ED47A", color: "black" }}
            onClick={() => {
              handlePR(modalData, false);
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={denyReasonModal}
        onClose={() => {
          closeDenyReasonModal();
        }}
        style={{ alignItems: "center" }}
      >
        <DialogTitle>Decline Reason</DialogTitle>
        <DialogContent>
          <Typography variant="h6">{modalData?.deny_reason}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#F7685B", color: "black" }}
            onClick={() => {
              closeDenyReasonModal();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {openedDocument && (
        <Modal
          open={state}
          onClose={() => setState(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            marginTop: 5,
            height: 680,
            width: 680,
            left: "30%",
          }}
        >
          <ViewerPdf file={`${openedDocument}`} />
        </Modal>
      )}
    </Box>
  );
};

export default PerformanceReviewList;
