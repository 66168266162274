import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from '@mui/material';
import moment from 'moment';
import apiService from '../services/apiService';
import { APP_URL } from '../utils/constants';
import { AvatarIcon } from './Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import { cutFullName } from '../utils/functions';
import { FormatListBulleted } from '@mui/icons-material';
import PerformanceReviewEmployeeViewProperties from './PerformanceReviewEmployeeViewProperties';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "../assets/img/DownloadIcon.svg";
import ViewerPdf from './PDFViewer/ViewerPdf';
import { saveAs } from "file-saver";

const classes = {
  modal: {
    width: "550px",
    display: "flex",
    justifyContent: "space-between",
  },
  completed: {
    backgroundColor: "#2ED47A99",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  new: {
    backgroundColor: "#109CF1",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  declined: {
    backgroundColor: "#F7685B99",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  reviewing: {
    backgroundColor: "#B89CFB",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  inProgress: {
    backgroundColor: "#FFD590",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  },
  cell: {
    color: "white",
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  deleted: {
    backgroundColor: "red",
    padding: "0.2rem",
    borderRadius: "0.3rem",
  }
};

const PerformanceReviewHistoryListEmployeeView = (props: any) => {
  const [data, setData] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [openedDocument, setOpenedDocument] = useState<any>();
  const [state, setState] = useState<any>(null);
  const [detailData, setDetailData] = useState<any>({})

  const url = `${APP_URL}/performance-review/pr_request/employee/${props.employee}/`;

  useEffect(() => {
    apiService.get(url).then((resp) => {
      setData(resp);
      setLoader(false);
    });
  }, []);

  const handleClassName = (item: any) => {
    if (item.deleted_by != null) {
      return classes.deleted;
    } else if (item.approved === true && item.is_active === false) {
      return classes.completed;
    } else if (item.approved === false) {
      return classes.declined;
    } else if (
      item.is_active === true &&
      (item.client_review_form?.in_progress === false ||
        item.employee_review_form?.in_progress === false ||
        item.team_lead_review_form?.in_progress === false)
    ) {
      return classes.reviewing;
    } else if (item.approved === true) {
      return classes.inProgress;
    } else if (item.approved == null){
      return classes.new
    }
  };

  const handleStatusView = (item: any) => {
    if (item.deleted_by != null) {
      return "Deleted";
    } else if (item.approved === true && item.is_active === false) {
      return "Completed";
    } else if (item.approved === false) {
      return "Declined";
    } else if (item.approved == null) {
      return "New"
    } else if (item.is_active && !item.in_progress) {
      return "Pending";
    } else if (item.approved === true) {
      return "In progress";
    } else if (item.approved == null) {
      return "New"
    }
  };

  const handleClick = (data: any) => {
    props.setShow(!props.show)

    setDetailData(data)
  };

  const openModal = (id: string) => {
    apiService
      .getFile(`${APP_URL}/performance-review/pdf2/${id}/`)
      .then((resp) => {
        let reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onloadend = function () {
          let base64data = reader.result;
          setOpenedDocument(base64data);
        };
      });

    setState(id);
  };

  const getNameOfDocument = (path: string) => {
    let splitted = path.split("/");
    let return_string: string = splitted[splitted.length - 1];
    return return_string;
  };

  const downloadFile = (id: any) => {
    const docName = getNameOfDocument(`${id}`);
    apiService
      .getFile(`${APP_URL}/performance-review/pdf2/${id}/`)
      .then((resp) => {
        saveAs(resp, `Form ${docName}`);
      });
  };

  return loader ? (
    <CircularProgress style={{ marginTop: '200px' }} />
  ) : (
    <>
      {props.show ? (
        <PerformanceReviewEmployeeViewProperties
          data={detailData}
          userId={props.employee}
        />
      ) : (
        <>
        <Box sx={{ minWidth: 950 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Requested by</TableCell>
                  <TableCell align="center">Reason</TableCell>
                  <TableCell align="center">Date of new request</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">End date</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Properties</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item: any) => (
                  <>
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{background: (item?.deleted_by != null) && '#F5C4C5'}}
                    >
                      <TableCell align="center">
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <AvatarIcon
                            src={
                              'data:image/jpeg;base64,' +
                              item?.requester?.avatar_64
                            }
                            firstName={item?.requester.user.first_name}
                            lastName={item?.requester.user.last_name}
                          />
                          <Box ml={2}>
                            {cutFullName(item?.requester.user, 25)}
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        {item?.reason?.reason}
                      </TableCell>

                      <TableCell align="center">
                        {item?.created_at
                          ? moment(item?.created_at).format('MMM Do YY')
                          : 'Blank date'}
                      </TableCell>

                      <TableCell align="center">
                        <Box style={handleClassName(item)}>
                          {handleStatusView(item)}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {item?.end_date
                            ? moment(item?.end_date).format('MMM Do YY')
                            : 'Blank date'}
                      </TableCell>
                      <TableCell align="center">
                      <Button onClick={() => openModal(item?.id)}>
                          <VisibilityIcon style={{ color: "black" }} />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                      <Button onClick={() => downloadFile(item?.id)}>
                          <img src={DownloadIcon} />
                    </Button>
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            handleClick(item);
                          }}
                        >
                          <FormatListBulleted />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        
        {/* Dialogs */}

        {openedDocument && (
            <Modal
              open={state}
              onClose={() => setState(null)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                marginTop: 5,
                height: 680,
                width: 680,
                left: '30%',
              }}
            >
              <ViewerPdf file={`${openedDocument}`} />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default PerformanceReviewHistoryListEmployeeView;
