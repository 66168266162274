import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { IPerformanceReviewForm } from "./PerformanceReviewQuestions/utils/prf.model";
import { useSelector } from "react-redux";

const PRFTemplate = () => {
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const [prfTemplates, setPrfTemplates] = useState<IPerformanceReviewForm[]>([]);
  const [prfId, setPrfId] = useState<number>(0);
  const [prfName, setPrfName] = useState<string>("");

  const handlePrfTemplate = (id?: number) => {
    if (id) {
     navigate(`/prf-template/${id}`);
    } else {
      const createPrf = async () => {
        const prf: IPerformanceReviewForm = {
          name: prfName,
          sections: [],
          is_template: true,
        };
        await apiService
          .post(`${APP_URL}/performance-review/pr_form/`, prf)
          .then((res: IPerformanceReviewForm) =>navigate(`/prf-template/${res.id}`));
      };
      createPrf();
    }
  };

  useEffect(() => {
    const fetchPrfTemplates = async () => {
      await apiService.get(`${APP_URL}/performance-review/templates/`).then((res) => {
        setPrfTemplates(res);
      });
    };
    fetchPrfTemplates();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginTop: 15,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{ display: 'flex', width: '35%', justifyContent: 'space-evenly' }}
        >
          <TextField
            select
            label='Select'
            sx={{ width: 150 }}
            size='small'
            onChange={(e) => setPrfId(Number(e.target.value))}
          >
            {prfTemplates.map((prfTemplate, index) => (
              <MenuItem key={index} value={prfTemplate.id}>
                {prfTemplate.name}
              </MenuItem>
            ))}
          </TextField>
          <Button
            onClick={(e) => handlePrfTemplate(prfId)}
            variant='contained'
            disabled={!prfId}
          >
            EDIT TEMPLATE
          </Button>
          <Button onClick={() => setOpened(true)} variant='contained'>
            CREATE TEMPLATE
          </Button>
        </Box>
      </Box>

      <Dialog open={opened} onClose={() => setOpened(false)}>
        <DialogTitle>Create PR Form Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a PR Form Template, please enter it's name first. You will
            be redirected to a PR Form Template creation page.
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='prf-name'
            label='PRF Name'
            type='text'
            fullWidth
            variant='standard'
            onBlur={(e) => setPrfName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpened(false)}>Cancel</Button>
          <Button onClick={() => handlePrfTemplate()}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PRFTemplate;
