import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Cookies from 'js-cookie';
import React, {useState } from 'react'
import { decodeToken } from 'react-jwt';
import apiService from '../../services/apiService';
import { APP_URL } from '../../utils/constants';

var jwt = require("react-jwt");

const EngineerAddModal = (props:any) => {
    const [engineers, setEngineers] = useState([])
    const position = props.position
    const profiles = props.profiles

    let cookie = Cookies.get("myCookie");
    // let userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  
    

    if (!props.show) {
        return null
    }
    function addWatcher (e: any) {
        const engineerIds = engineers.map((engineer) => engineer.id)
        const currWatchersIds = position?.watchers?.map((profile: any) => profile.user.id)
        const postPos = position
        postPos.watchers = [...currWatchersIds, ...engineerIds]
        apiService.patch(`${APP_URL}/job/alljobs/`, postPos).then((response) => position.watchers=response.watchers).then(() => {setEngineers([]); props.onClose()})


    }
    function handleChange (e: any) { 
          setEngineers(e.target.value);
    }

    function close (){
        setEngineers([])
        props.onClose()
    }

    return(
        <div style={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"}}>
            <div style={{
                width: "500px",
                backgroundColor: "white"
            }}>
            <div style={{
                padding: "10px"
            }}>
                <h4> Select a watcher to add </h4>
            </div>
            <div style={{
                padding: "10px",
                borderTop: "1px solid #eee",
                borderBottom: "1px solid #eee",
            }}>
            <FormControl fullWidth>
            <InputLabel id="engineer-label">Engineer</InputLabel>
            <Select 
              multiple
              value={engineers}
              labelId="engineer-label"
              id="engineer-select"
              label="Engineer"
              onChange={handleChange}
              renderValue={(selected) => selected.map((profile, index, selected) => {
                if (selected.length-1 === index){
                    return(
                        profile.user.first_name + " " + profile.user.last_name
                    )
                }
                return(
                      profile.user.first_name + " " + profile.user.last_name + ", "
                  )
              })}
            >
            {profiles?.filter((profile: any) => profile.id !== userId && profile.is_regular_user && !position?.watchers?.some((watcher: any) => watcher.user.id===profile.user.id)).map((profile: any) => (
                    <MenuItem
                    key={profile?.id} 
                    value={profile}>
                    {profile.user.first_name} {profile.user.last_name}
                    </MenuItem>
              ))}
            </Select>
          </FormControl>
          </div>
          <div style={{
                padding: "10px"
            }}>
              <Button onClick={close} style={{float:"right"}}>Close</Button>
              <Button onClick={addWatcher} style={{float:"left"}}>Add</Button>
          </div>
           
          </div>
        </div>

    )
}

export default EngineerAddModal