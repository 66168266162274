import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { AvatarIcon } from "./Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { cutLastName } from "../utils/functions";
import Swal from "sweetalert2";
import { decodeToken } from "react-jwt";

const Toast = Swal.mixin({
  toast: true,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const classes = {
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderLeft: "0.2rem solid #109CF1",
    borderBottom: "0.1rem solid #EBEFF2",
    height: "7vh",
  },
  perfRewReq: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "3rem",
  },
  search: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  main: {
    // height: "100%",
    // backgroundColor: "teal",
  },
  mainTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  whoRequest: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "15rem",
  },
  fields: { width: "100%" },
  fieldInfo: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "teal",
  },
  typographyDesc: {
    flex: 5,
    display: "flex",
    alignItems: "flex-start",
  },
  typographyValue: {
    flex: 6,
    display: "flex",
    alignItems: "flex-start",
  },
  mainBottom: {},
  paperReasons: {
    padding: 15,
  },
  radios: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "10rem",
    margin: "1.5rem 2rem",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "8vh",
    borderTop: "2px solid #EBEFF2",
  },
  button: {
    backgroundColor: "teal",
    "&:hover": {
      backgroundColor: "teal",
    },
  },
};

const PerformanceReviewRequest = () => {
  const [value, setValue] = useState<any>(1);
  const [reasons, setReasons] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [user, setUser] = useState<any>();
  const [usersPerClient, setUsersPerClient] = useState<any>();
  const [icon, setIcon] = useState<any>();
  const [iconForWho, setIconForWho] = useState<any>();
  const [loader, setLoader] = useState<any>(true);

  //autocomplete
  let [employee, setEmployee] = useState<any>(null);
  const [inputValue, setInputValue] = useState("");

  let jwt = require("react-jwt");
  let cookie = Cookies.get("myCookie");
  let userId: number;
  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }
  let currentUser = useSelector((state: any) => state.reducer.user);

  useEffect(() => {
    setUser(currentUser);
    //setIcon("data:image/jpeg;base64," + currentUser.avatar_64);
    if (currentUser?.is_hr || currentUser?.is_super_hr) {
      apiService.get(`${APP_URL}/users/profile/`).then((resp3) => {
        setUsersPerClient(resp3);
        setLoader(false);
      });
    } else if (currentUser?.is_client) {
      apiService.get(`${APP_URL}/users/per-client/`).then((resp3) => {
        setUsersPerClient(resp3.users);
        setLoader(false);
      });
    } else {
      setUsersPerClient([currentUser]);
      setLoader(false);
    }
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (currentUser?.avatar_64) {
      if (base64regex.test(currentUser.avatar_64)) {
        setIcon("data:image/jpeg;base64," + currentUser?.avatar_64);
      } else {
        setIcon(currentUser?.avatar_64);
      }
    }

    apiService.get(`${APP_URL}/performance-review/reason/`).then((resp2) => {
      setReasons(resp2);
    });
  }, []);

  useEffect(() => {
    if (employee?.avatar_64) {
      setIconForWho("data:image/jpeg;base64," + employee.avatar_64);
    } else {
      setIconForWho(null);
    }
  }, [employee]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
  };

  const handleSendRequest = () => {
    if (employee === null) {

      employee = currentUser;
    }

    let data = {
      employee:
        currentUser.is_regular_user || currentUser.is_team_lead
          ? currentUser.id
          : employee?.id,
      reason: value,
      description: description,
    };
    setLoader(true);
    apiService
      .post(`${APP_URL}/performance-review/pr_request/`, data)
      .then(() => {
        setValue(1);
        setDescription("");
        setEmployee(null);
        Toast.fire({
          icon: "success",
          title: "Performance review request sent successfully!",
          background: "#a5dc86",
        });
        setLoader(false);
      })
      .catch((resp) => {
        // setErrorMessage(resp);
        Toast.fire({
          icon: "error",
          title: resp,
          background: "#f27474",
        });
        setLoader(false);
      });
  };

  return loader ? (
    <CircularProgress />
  ) : (
    <Paper>
      {/* 1.DEO - Performance review request - search */}
      {!(currentUser.is_regular_user || currentUser.is_team_lead) && (
        <Box style={classes.header}>
          <Box style={classes.perfRewReq}>
            {currentUser.is_regular_user || currentUser.is_team_lead
              ? "Performance review - self assessment"
              : "Performance review request"}
          </Box>
          <Box
            style={classes.search}
            sx={{
              display:
                currentUser.is_regular_user || currentUser.is_team_lead
                  ? "none"
                  : "block",
            }}
          >
            <Autocomplete
              getOptionLabel={(option) =>
                `${option?.user?.first_name} ${option?.user?.last_name}`
              }
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={usersPerClient && usersPerClient}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Employees" />
              )}
              value={employee}
              onChange={(event: any, newValue: any) => {
                setEmployee(newValue);
              }}
            />
          </Box>
        </Box>
      )}

      {/* 2.DEO */}
      <Box p={3} style={classes.main}>
        <Box sx={classes.mainTop}>
          <Paper
            elevation={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "15rem",
              flex:
                currentUser.is_regular_user || currentUser.is_team_lead
                  ? 0.35
                  : 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "80%",
              }}
            >
              <Box mt={1} mb={1}>
                <AvatarIcon
                  src={icon}
                  firstName={user?.user?.first_name}
                  lastName={user?.user?.last_name}
                  style={{
                    fontSize: "50px !important",
                    width: 120,
                    height: 120,
                  }}
                />
              </Box>
              <Box style={classes.fields}>
                <Box sx={classes.fieldInfo}>
                  <Typography variant="body2" style={classes.typographyDesc}>
                    First name:
                  </Typography>
                  <Typography variant="body2" style={classes.typographyValue}>
                    {user?.user?.first_name}
                  </Typography>
                </Box>
                <Box sx={classes.fieldInfo}>
                  <Typography variant="body2" style={classes.typographyDesc}>
                    Last name:
                  </Typography>
                  <Typography variant="body2" style={classes.typographyValue}>
                    {cutLastName(user?.user, 20)}
                  </Typography>
                </Box>
                <Box sx={classes.fieldInfo}>
                  <Typography variant="body2" style={classes.typographyDesc}>
                    Client:
                  </Typography>
                  <Typography variant="body2" style={classes.typographyValue}>
                    {user?.client?.name}
                  </Typography>
                </Box>
                <Box sx={classes.fieldInfo}>
                  <Typography variant="body2" style={classes.typographyDesc}>
                    Position:
                  </Typography>
                  <Typography variant="body2" style={classes.typographyValue}>
                    {user?.position?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          {/* Requesting for */}
          <Typography
            sx={{
              margin: 3,
              display:
                currentUser.is_regular_user || currentUser.is_team_lead
                  ? "none"
                  : "block",
            }}
          >
            Requesting for
          </Typography>
          {employee === null ? (
            <Box
              sx={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "15rem",
                display:
                  currentUser.is_regular_user || currentUser.is_team_lead
                    ? "none"
                    : "block",
              }}
            />
          ) : (
            <Paper sx={classes.whoRequest} elevation={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "80%",
                }}
              >
                <Box mt={1} mb={1}>
                  <AvatarIcon
                    src={iconForWho}
                    firstName={employee?.user?.first_name}
                    lastName={employee?.user?.last_name}
                    style={{
                      fontSize: "50px !important",
                      width: 120,
                      height: 120,
                    }}
                  />
                </Box>
                <Box style={classes.fields}>
                  <Box sx={classes.fieldInfo}>
                    <Typography variant="body2" style={classes.typographyDesc}>
                      First name:
                    </Typography>
                    <Typography variant="body2" style={classes.typographyValue}>
                      {employee?.user?.first_name}
                    </Typography>
                  </Box>
                  <Box sx={classes.fieldInfo}>
                    <Typography variant="body2" style={classes.typographyDesc}>
                      Last name:
                    </Typography>
                    <Typography variant="body2" style={classes.typographyValue}>
                      {cutLastName(employee?.user, 20)}
                    </Typography>
                  </Box>
                  <Box sx={classes.fieldInfo}>
                    <Typography variant="body2" style={classes.typographyDesc}>
                      Client:
                    </Typography>
                    <Typography variant="body2" style={classes.typographyValue}>
                      {employee?.client?.name}
                    </Typography>
                  </Box>
                  <Box sx={classes.fieldInfo}>
                    <Typography variant="body2" style={classes.typographyDesc}>
                      Position:
                    </Typography>
                    <Typography variant="body2" style={classes.typographyValue}>
                      {employee?.position?.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
        {/* MAIN BOTTOM - Reason for requesting a performance review */}
        <Box mt={2} p={4} style={classes.mainBottom}>
          <Paper style={classes.paperReasons}>
            <Typography variant="subtitle1">
              Reason for requesting a performance review
            </Typography>
            {!user.is_regular_user && (<Box style={classes.radios}>
              <FormControl>
                {reasons &&
                  reasons.map((item: any) => (
                    <RadioGroup value={value} onChange={handleChange}>
                      <FormControlLabel
                        defaultValue={5}
                        value={item?.id}
                        control={<Radio size="small" />}
                        label={item?.reason}
                      />
                    </RadioGroup>
                  ))}
              </FormControl>
            </Box>)}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 4rem",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      {/* 3.DEO - send request */}
      <Box style={classes.footer}>
        <Button
          onClick={() => handleSendRequest()}
          style={classes.button}
          variant="contained"
          disabled={loader}
        >
          Send request
          {loader && (
            <CircularProgress
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "10px",
                color: "white",
              }}
            />
          )}
        </Button>
      </Box>
    </Paper>
  );
};

export default PerformanceReviewRequest;
