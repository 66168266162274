import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import { Position } from "./job_position";
export class CandidatClass {
   id: number;
   first_name: string;
   last_name: string;
   email: string;
   job_application_progress: number;
   CV: File;
   job_position: Position;
   constructor(
      id: number = 0,
      first_name: string = "",
      last_name: string = "",
      email: string = "",
      job_application_progress: number,
      CV: File,
      job_position: Position
   ) {
      this.id = id;
      this.first_name = first_name;
      this.last_name = last_name;
      this.email = email;
      this.job_application_progress = job_application_progress;
      this.CV = CV;
      this.job_position = job_position;
   }
}
   export const getCandidateList = async (): Promise<any[]> => {
      let listCan: any[] = undefined;
      await apiService.get(`${APP_URL}/job/candidates/`).then(response => listCan=response);
      return listCan;
};

