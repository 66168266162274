import { Box, Button, Container, Link, Typography } from "@mui/material";
import PTS from "../assets/img/PTS.png"

export const PathToSuccess = () => {
    return(
    <Container>
        <Box sx={{mt:9, ml: 12, width:"50vw", justifyContent: 'center', display:"flex", flexDirection:"column"}}>
            <Typography sx={{fontSize:40, mb:2}}>Technical and Managerial Paths</Typography>
            <Typography>The Technical and Managerial path gives a structured overview of the engineering career path. It strives to provide team members with detailed information and description of each level, and what is expected of them to keep developing and growing. The goal is also to provide each team member with the opportunity to plan their progress with other relevant members. </Typography>
        </Box>
        <Box component="img"
        sx={{
          mt:2,
          height: 700,
          width: 400,
        }}
        alt="Path to Success."
        src={PTS}></Box>
    </Container>
    );
}