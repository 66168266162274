import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import _pick from "lodash/pick";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import _cloneDeep from "lodash/cloneDeep";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import AddPosition from "./CreateEmployeePosition";
import AddSeniority from "./CreateSeniority";
import AddClient from "./CreateClient";
import AddProject from "./CreateProject";
import AddTeam from "./CreateTeam";
import Chip from "@mui/material/Chip";
import _isEmpty from "lodash/isEmpty";
import { LoadingButton } from "@mui/lab";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FormDataType from "./HRDashboardInterface";
import { Box, styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import Autocomplete from "@mui/material/Autocomplete";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import {
  Button,
  Container,
  Paper,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Stack,
} from "@mui/material";
import Dashboard from "../../pages/Dashboard";
import { saveAs } from "file-saver";
import { b64toBlob } from "../../services/b64Converter";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector } from "react-redux";
import ViewerPdf from "../PDFViewer/ViewerPdf";
import Swal from "sweetalert2";
import { Checkbox, FormControlLabel } from "@mui/material";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Toast = Swal.mixin({
  toast: true,
  width: 450,
  color: "white",
  iconColor: "white",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

let jwt = require("react-jwt");

const nameOfDocuments = [
  "ID card",
  "Employment Contract",
  "Certificate of employment",
  "Degree of vocational education",
  "Scan of the highest certificate/diploma obtained",
  "Certificate of impunity",
  "Annex to the contract",
  "Notice of Annex to the contract",
  "Decision on annual leave",
  "NDA",
  "Equipment check-out form",
  "Bank documents",
  "Notice on processing employees’ personal data",
  "Protection of whistleblowers document",
  "Notice on prevention of harassment at work",
  "Benefits",
  "Decision of business trip",
  "Business trip order",
];

//tabs
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const Input = styled("input")({
  display: "none",
});

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
//tabs

const classes = {
  user: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem 10rem",
  },
  items: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5rem",
  },
  items2: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.5rem",
  },
  picture: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "0.5rem",
  },
  item: {
    flex: 1,
    margin: "0rem 0.1rem",
  },
  enabled: {
    color: "red",
  },
  avatar: { position: "relative" },
  stack: { position: "absolute", left: 0, right: 0 },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listitem: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: "0.5rem 2rem",
  },
};

const typeChecker = (path: string) => {
  var splitted = path.split(".");
  return splitted[splitted.length - 1];
};

const pdf = "pdf";

const initFormData: FormDataType = {
  firstName: "",
  lastName: "",
  emailQcerris: "",
  emailPrivate: "",
  address: "",
  phoneNumber: "",
  bankAccount: "",
  emergencyContactName: "",
  emergencyNumber: "",
  dateOfBirth: null,
  internshipStart: null,
  internship_end: null,
  contractStart: null,
  dateOfAnexStart: null,
};

const initFormDataErrors = {
  firstName: false,
  lastName: false,
  emailQcerris: false,
};

const initUserType = {
  isRegularUser: false,
  isHR: false,
  isClient: false,
  isTeamLead: false,
  isSuperHR: false,
  isSupervisor: false,
};
const roles = [
  "Regular User",
  "HR",
  "Client",
  "Team Lead",
  "Supervisor",
  "Super HR",
];

const CreateUser = () => {
  const [formData, setFormData] = useState<any>(initFormData);
  const [formDataErrors, setFormDataErrors] = useState(initFormDataErrors);
  const [userType, setUserType] = useState<any>(initUserType);

  const [positions, setPositions] = useState<any>([]);
  const [seniorities, setSeniorities] = useState<any>([]);
  const [clients, setClients] = useState<any>([]);

  const [teams, setTeams] = useState<any>([]);
  const [teamLeads, setTeamLeads] = useState<any>([]);
  const [supervisors, setSupervisors] = useState<any>();
  const [projects, setProjects] = useState<any>();

  const [gender, setGender] = useState<any>("MA");
  const [role, setRole] = useState<any>("Regular User");

  const [positionUser, setPositionUser] = useState<any>({});
  const [clientUser, setClientUser] = useState<any>({});
  const [teamUser, setTeamUser] = useState<any>([]);
  const [seniorityUser, setSeniorityUser] = useState<any>({});
  const [teamLeadUser, setTeamLeadUser] = useState<any>({});
  const [supervisorUser, setSupervisorUser] = useState<any>({});
  const [projectUser, setProjectUser] = useState<any>([]);
  const [teamsIDs, setTeamsIDs] = useState<any>([]);
  const [projectIDs, setProjectIDs] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [coach, setCoach] = useState<boolean>(false);

  //Documents
  const [file, setFile] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [listOfDocuments, setListOfDocuments] = useState<any>([]);

  const [files, setFiles] = useState<any>();
  const [state, setState] = useState<any>(null);
  const [openedDoc, setOpenedDoc] = useState<any>();

  let teamKeysHelper = formData.team?.map((team: any) => team.id);

  let cookie = Cookies.get("myCookie");
  let userId: any;
  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }

  useEffect(() => {
    setTeamUser([]);
  }, [clientUser]);

  let currentUser = useSelector((state: any) => state.reducer.user);
  useEffect(() => {
    setUser(currentUser);
  }, []);

  const SetAllStatesToStartingPosition = () => {
    setFormData(initFormData);
    setUserType(initUserType);
    setGender("MA");
    setRole("Regular User");
    setPositionUser({});
    setSeniorityUser({});
    setTeamLeadUser({});
    setSupervisorUser({});
    setClientUser({});
    setProjectUser([]);
    setTeamUser([]);
  };

  const postTeamLeads = async () => {
    const listEmployees = await apiService.get(`${APP_URL}/users/profile/`);
    let listTeamLeads = listEmployees.filter(
      (e: any) => e.is_team_lead === true
    );
    setTeamLeads(listTeamLeads);
    let listSupervisors = listEmployees.filter(
      (e: any) => e.is_supervisor === true
    );
    setSupervisors(listSupervisors);
  };

  const postNewSeniority = () => {
    apiService.get(`${APP_URL}/users/seniority/`).then((resp) => {
      setSeniorities(resp);
    });
  };
  const postNewPosition = () => {
    apiService.get(`${APP_URL}/users/position/`).then((resp) => {
      setPositions(resp);
    });
  };

  const postNewClient = async (name: string = "") => {
    const result = await apiService.get(`${APP_URL}/users/client/`);
    setClients(result);
    let patchUser: any;
    if (name === "")
      patchUser = result.filter((c: any) => c.id === clientUser.id);
    else patchUser = result.filter((c: any) => c.name === name);
    setClientUser(patchUser[0]);
  };

  const postNewTeam = async () => {
    // await postNewClient();
    const result = await apiService.get(`${APP_URL}/users/teams/`);
    const listClientTeamsId: string[] = clientUser.teams.map(
      (ct: any) => ct.id
    );
    let helper = result.filter((t: any) => listClientTeamsId.includes(t.id));
    setTeams(helper);
  };

  const postNewProject = async () => {
    const listProjects = await apiService.get(`${APP_URL}/users/project/`);
    let userID = clientUser.id;
    let helper = listProjects.filter(
      (p: any) => p.client && p.client.id === userID
    );
    setProjects(helper);
  };

  useEffect(() => {
    switch (role) {
      case "Regular User":
        setUserType({ isRegularUser: true });
        break;
      case "HR":
        setUserType({ isHr: true });
        break;
      case "Client":
        setUserType({ isClient: true });
        break;
      case "Team Lead":
        setUserType({ isTeamLead: true });
        break;
      case "Supervisor":
        setUserType({ isSupervisor: true });
        break;
      case "Super HR":
        setUserType({ isHr: true, isSuperHR: true });
        break;
    }
  }, [role]);

  useEffect(() => {
    const fetchData = async () => {
      const listEmployees = await apiService.get(`${APP_URL}/users/profile/`);
      let listTeamLeads = listEmployees.filter(
        (e: any) => e.is_team_lead === true
      );
      setTeamLeads(listTeamLeads);
      let listSupervisors = listEmployees.filter(
        (e: any) => e.is_supervisor === true
      );
      setSupervisors(listSupervisors);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const listClients = await apiService.get(`${APP_URL}/users/client/`);
      setClients(listClients);
      const listPositions = await apiService.get(`${APP_URL}/users/position/`);
      setPositions(listPositions);
      const listSeniority = await apiService.get(`${APP_URL}/users/seniority/`);
      setSeniorities(listSeniority);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (clientUser?.teams) {
        const listTeams = await apiService.get(`${APP_URL}/users/teams/`);
        const listClients = await apiService.get(`${APP_URL}/users/client/`);
        let QCerrisClient = listClients.filter(
          (c: any) => c.name === "QCerris"
        );
        let QCTeamsIDs = QCerrisClient[0].teams.map((t: any) => t.id);
        let listClientsId: string[] = clientUser.teams.map((ct: any) => ct.id);
        for (let i = 0; i < QCTeamsIDs.length; i++) {
          listClientsId.push(QCTeamsIDs[i]);
        }
        let helper = listTeams.filter((t: any) => listClientsId.includes(t.id));
        setTeams(helper);
      }
    };
    fetchData();
  }, [clientUser]);

  useEffect(() => {
    const fetchData = async () => {
      if (clientUser?.id) {
        const listProjects = await apiService.get(`${APP_URL}/users/project/`);
        let helper = listProjects.filter(
          (p: any) => p.client && p.client.id === clientUser.id
        );
        setProjects(helper);
      }
    };
    fetchData();
  }, [clientUser]);

  useEffect(() => {
    if (teamUser) {
      let helper = teamUser.map((t: any) => t.id);
      setTeamsIDs(helper);
    }
  }, [teamUser]);

  useEffect(() => {
    if (projectUser) {
      let helper = projectUser.map((p: any) => p.id);
      setProjectIDs(helper);
    }
  }, [projectUser]);

  const addToList = () => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("title", title);
    formData.append("user", "-1");

    setListOfDocuments([...listOfDocuments, formData]);
    Toast.fire({
      icon: "success",
      title: "File has been added!",
      width: 480,
      background: "#a5dc86",
    });
    setTitle(null);
    setFile(null);
  };
  const handleCreateUser = () => {
    const errors: any = _cloneDeep(formDataErrors);
    if (_isEmpty(formData.firstName)) {
      errors["firstName"] = true;
    }
    if (_isEmpty(formData.lastName)) {
      errors["lastName"] = true;
    }
    if (_isEmpty(formData.emailQcerris)) {
      errors["emailQcerris"] = true;
    }
    setFormDataErrors(errors);

    if (Object.values(errors).filter((x) => x === true).length > 0) {
      return;
    }

    let data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      date_of_birth: formData.dateOfBirth,
      gender: gender,
      is_hr: userType?.isHr,
      is_client: userType?.isClient,
      is_regular_user: userType?.isRegularUser,
      is_team_lead: userType?.isTeamLead,
      is_supervisor: userType?.isSupervisor,
      is_super_hr: userType?.isSuperHR,
      email: formData.emailQcerris,
      private_email: formData.emailPrivate,
      address: formData.address,
      phone_number: formData.phoneNumber,
      bank_account: formData.bankAccount,
      internship_start: formData.internshipStart,
      internship_end: formData.internship_end,
      contract_start: formData.contractStart,
      anex_date_start: formData.dateOfAnexStart,
      emergency_name: formData.emergencyContactName,
      emergency_number: formData.emergencyNumber,
      position: positionUser.id,
      seniority: seniorityUser.id,
      client: clientUser.id,
      project: projectIDs,
      team: teamsIDs,
      permision: 0,
      is_coach: coach,
      team_lead: teamLeadUser.id,
    };
    apiService
      .post(`${APP_URL}/users/`, data)
      .then((resp) => {
        {
          listOfDocuments &&
            listOfDocuments.length > 0 &&
            listOfDocuments.map((item: any) => (item.id = resp.user_id));
        }

        listOfDocuments.forEach((element: any) => {
          const fd = new FormData();
          for (let pair of element.entries()) {
            let name = pair[0];
            let value = pair[1];
            if (name === "user") {
              fd.append("user", resp.user_id);
            }
            if (name === "document") {
              fd.append("document", value);
            }
            if (name === "title") {
              fd.append("title", value);
            }
          }
          apiService.post(
            `${APP_URL}/users/documents/`,
            fd,
            "multipart/form-data"
          );
        });

        Toast.fire({
          icon: "success",
          title: "Employee successfully added!",
          width: 480,
          background: "#a5dc86",
        });
        SetAllStatesToStartingPosition();
        postTeamLeads();
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: "Error Adding Employee!",
          background: "#f27474",
        });
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setFormDataErrors({
      ...formDataErrors,
      [e.target.name]: _isEmpty(e.target.value),
    });
  };

  const OpenModal = (id: string) => {
    apiService.get(`${APP_URL}/users/documents/${id}/`).then((resp) => {
      setOpenedDoc("data:image/png;base64," + resp.file);
      setState(resp.title);
    });
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const addFile = (e: any) => {
    const files = e.target.files;
    if (files[0].size < 67108864) {
      setFile(files[0]);
    } else {
      Toast.fire({
        icon: "error",
        title: "File is too large! Max file size is 63MB!",
        background: "#f27474",
      });
    }
  };

  const Download = (props: any) => {
    const downloadFile = () => {
      apiService.get(`${APP_URL}/users/documents/${props.id}/`).then((resp) => {
        let docName = resp.title;
        let file = b64toBlob(resp.file);
        saveAs(file, docName);
      });
    };
    return (
      <Box>
        <Button
          startIcon={<FileDownloadIcon />}
          onClick={downloadFile}
        ></Button>
      </Box>
    );
  };

  return (
    <Container>
      <Dashboard />
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" mt={14}>
          <PersonAddAltIcon sx={{ color: "teal" }} />
        </Box>
        <Typography align="center" variant="h4" mb={7}>
          Create new user
        </Typography>
        <TabsUnstyled defaultValue={0}>
          <TabsList>
            <Tab>General info</Tab>
            <Tab>Documents</Tab>
          </TabsList>
          <TabPanel value={0}>
            {user && user.is_super_hr && (
              <Container maxWidth="md">
                <Paper sx={classes.paper}>
                  <Container maxWidth="sm">
                    <Box style={classes.items}>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          First name:
                        </Typography>
                        <TextField
                          fullWidth
                          id="demo-helper-text-aligned"
                          name="firstName"
                          value={formData?.firstName}
                          onChange={handleChange}
                          error={formDataErrors.firstName}
                          helperText={
                            formDataErrors.firstName && "First name required"
                          }
                        />
                      </Box>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Last name:
                        </Typography>
                        <TextField
                          fullWidth
                          id="demo-helper-text-aligned"
                          name="lastName"
                          value={formData?.lastName}
                          onChange={handleChange}
                          error={formDataErrors.lastName}
                          helperText={
                            formDataErrors.lastName && "Last name required"
                          }
                        />
                      </Box>
                    </Box>
                    <Box style={classes.items}>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Date of birth:
                        </Typography>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              defaultValue={dayjs(formData?.dateOfBirth)}
                              onChange={(e: any) => {
                                setFormData({
                                  ...formData,
                                  dateOfBirth: moment(String(e)).format(
                                    "yyyy-MM-DD"
                                  ),
                                });
                              }}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Gender:
                        </Typography>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="gender"
                          value={gender}
                          defaultValue={"MA"}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <MenuItem value={"MA"}>Male</MenuItem>
                          <MenuItem value={"FE"}>Female</MenuItem>
                          <MenuItem value={"OT"}>Other</MenuItem>
                        </Select>
                      </Box>
                    </Box>

                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        User Role:
                      </Typography>
                      <Autocomplete
                        getOptionLabel={(option: any) => option}
                        id="controllable-states-demo"
                        options={roles}
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        value={role ?? ""}
                        onChange={(event: any, newValue: any) => {
                          setRole(newValue);
                        }}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Email Company:
                      </Typography>
                      <TextField
                        fullWidth
                        type="email"
                        id="demo-helper-text-aligned"
                        name="emailQcerris"
                        value={formData?.emailQcerris}
                        onChange={handleChange}
                        error={formDataErrors.emailQcerris}
                        helperText={
                          formDataErrors.emailQcerris && "Email required"
                        }
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Email Private:
                      </Typography>
                      <TextField
                        fullWidth
                        id="demo-helper-text-aligned"
                        name="emailPrivate"
                        value={formData?.emailPrivate}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Address:
                      </Typography>
                      <TextField
                        fullWidth
                        id="demo-helper-text-aligned"
                        variant="outlined"
                        name="address"
                        value={formData?.address}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Phone number:
                      </Typography>
                      <TextField
                        fullWidth
                        id="demo-helper-text-aligned"
                        name="phoneNumber"
                        value={formData?.phoneNumber}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Bank account:
                      </Typography>
                      <TextField
                        fullWidth
                        id="demo-helper-text-aligned"
                        name="bankAccount"
                        value={formData?.bankAccount}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box style={classes.items}>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Internship start date:
                        </Typography>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              defaultValue={dayjs(formData?.internshipStart)}
                              onChange={(e: any) =>
                                setFormData({
                                  ...formData,
                                  internshipStart: moment(String(e)).format(
                                    "yyyy-MM-DD"
                                  ),
                                })
                              }
                              maxDate={dayjs(formData?.internship_end ?? "")}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Internship end date:
                        </Typography>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              defaultValue={dayjs(formData?.internship_end)}
                              onChange={(e: any) => {
                                setFormData({
                                  ...formData,
                                  internship_end: moment(String(e)).format(
                                    "yyyy-MM-DD"
                                  ),
                                });
                              }}
                              minDate={dayjs(formData?.internshipStart ?? "")}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={classes.items}>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Contract start date:
                        </Typography>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{ width: 435 }}
                              defaultValue={dayjs(formData?.contractStart)}
                              onChange={(e: any) =>
                                setFormData({
                                  ...formData,
                                  contractStart: moment(String(e)).format(
                                    "yyyy-MM-DD"
                                  ),
                                })
                              }
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={classes.items}>
                      <Box style={classes.item}>
                        <Typography align="left" variant="body2">
                          Anex date start:
                        </Typography>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{ width: 435 }}
                              defaultValue={formData?.dateOfAnexStart}
                              onChange={(e: any) =>
                                setFormData({
                                  ...formData,
                                  dateOfAnexStart: moment(String(e)).format(
                                    "yyyy-MM-DD"
                                  ),
                                })
                              }
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Emergency contact name and lastname:
                      </Typography>
                      <TextField
                        fullWidth
                        id="demo-helper-text-aligned"
                        name="emergencyContactName"
                        value={formData?.emergencyContactName}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Emergency number:
                      </Typography>
                      <TextField
                        fullWidth
                        id="demo-helper-text-aligned"
                        name="emergencyNumber"
                        value={formData?.emergencyNumber}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Supervisor:
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="Supervisors"
                        value={supervisorUser ?? {}}
                        onChange={(e) => setSupervisorUser(e.target.value)}
                        fullWidth
                      >
                        {supervisors &&
                          supervisors.map((item: any) => (
                            <MenuItem value={item || ""}>
                              {item.user.first_name + " " + item.user.last_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Team Lead:
                      </Typography>
                      <Autocomplete
                        getOptionLabel={(option: any) =>
                          option.user?.first_name && option.user?.last_name
                            ? option.user.first_name +
                              " " +
                              option.user.last_name
                            : ""
                        }
                        id="controllable-states-demo"
                        options={teamLeads}
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        value={teamLeadUser ?? ""}
                        onChange={(event: any, newValue: any) => {
                          setTeamLeadUser(newValue);
                        }}
                      />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Position:
                      </Typography>
                      <Autocomplete
                        getOptionLabel={(option: any) =>
                          option?.name ? option.name : ""
                        }
                        id="controllable-states-demo"
                        options={positions}
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        value={positionUser ?? ""}
                        onChange={(event: any, newValue: any) => {
                          setPositionUser(newValue);
                        }}
                      />
                      <AddPosition newPosition={postNewPosition} />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Seniority:
                      </Typography>
                      <Autocomplete
                        getOptionLabel={(option: any) =>
                          option?.name ? option.name : ""
                        }
                        id="controllable-states-demo"
                        options={seniorities}
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        value={seniorityUser ?? ""}
                        onChange={(event: any, newValue: any) => {
                          setSeniorityUser(newValue);
                        }}
                      />

                      <AddSeniority newSeniority={postNewSeniority} />
                    </Box>
                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Main Client:
                      </Typography>
                      <>
                        <Autocomplete
                          getOptionLabel={(option: any) =>
                            option?.name ? option.name : ""
                          }
                          id="controllable-states-demo"
                          options={clients}
                          fullWidth
                          sx={{ backgroundColor: "white" }}
                          renderInput={(params) => <TextField {...params} />}
                          value={clientUser ?? ""}
                          onChange={(event: any, newValue: any) => {
                            console.log(newValue);
                            setClientUser(newValue);
                          }}
                        />
                        <AddClient
                          newClient={postNewClient}
                          setClientUser={setClientUser}
                        />
                      </>
                    </Box>
                    {/*<Box style={classes.items2}>
                      <Typography align="left" variant="body2">
                        Project:
                      </Typography>
                      <>
                        <Select
                          labelId="demo-simple-select-project"
                          id="demo-simple-select-project"
                          name="project.name"
                          onOpen={() => setLoader(false)}
                          value={projectUser ?? []}
                          multiple
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value: any) => (
                                <Chip key={value.id} label={value.name} />
                              ))}
                            </Box>
                          )}
                          onChange={(e) => setProjectUser(e.target.value)}
                          fullWidth
                        >
                          {loader ? (
                            <CircularProgress />
                          ) : (
                            projects &&
                            projects.map((item: any) => (
                              <MenuItem value={item} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                        <AddProject
                          Client={clientUser}
                          newProject={postNewProject}
                        />
                      </>
                            </Box>*/}

                    {/* <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Other Clients:
                      </Typography>
                      <>
                        <Select
                          labelId="demo-simple-select-team"
                          id="demo-simple-select-team"
                          name="team.name"
                          value={teamUser ?? []}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value: any) => (
                                <Chip key={value.id} label={value.name} />
                              ))}
                            </Box>
                          )}
                          multiple
                          onChange={(e) => setTeamUser(e.target.value)}
                          fullWidth
                        >
                          {teams &&
                            teams.map((item: any) => (
                              <MenuItem value={item} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <AddTeam Client={clientUser} newTeam={postNewTeam} patchClient={postNewClient} />
                      </>
                    </Box> */}

                    <Box sx={classes.items2}>
                      <Typography align="left" variant="body2">
                        Team:
                      </Typography>
                      <>
                        <Select
                          labelId="demo-simple-select-team"
                          id="demo-simple-select-team"
                          name="team.name"
                          value={teamUser ?? []}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value: any) => (
                                <Chip key={value.id} label={value.name} />
                              ))}
                            </Box>
                          )}
                          multiple
                          onChange={(e) => setTeamUser(e.target.value)}
                          fullWidth
                        >
                          {teams &&
                            teams.map((item: any) => (
                              <MenuItem value={item} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <AddTeam
                          Client={clientUser}
                          newTeam={postNewTeam}
                          patchClient={postNewClient}
                          setTeamUser={setTeamUser}
                        />
                      </>
                    </Box>
                    {!currentUser.is_client && (
                      <Box sx={classes.items2}>
                        <FormControlLabel
                          label="Coach"
                          control={
                            <Checkbox
                              color="default"
                              onChange={() => {
                                setCoach(!coach);
                              }}
                              checked={coach}
                            />
                          }
                        />
                      </Box>
                    )}
                    <Box style={classes.items}>
                      <Box style={classes.item}>
                        <LoadingButton
                          sx={{ marginTop: "1.5rem" }}
                          fullWidth
                          size="large"
                          variant="contained"
                          onClick={handleCreateUser}
                        >
                          Create User
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Container>
                </Paper>
              </Container>
            )}
          </TabPanel>
          <TabPanel value={1}>
            {/* ////////////////////////////////////////////////// */}
            <Container>
              <Box>
                <Box mt={5} mb={5}>
                  <form onSubmit={submitForm} encType="multipart/form-data">
                    <Box style={classes.list}>
                      <Box style={classes.listitem}>
                        <Stack>
                          <label htmlFor="document-button-file">
                            <Input
                              id="document-button-file"
                              type="file"
                              name="document-button-file"
                              onChange={(e) => addFile(e)}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              component="span"
                              startIcon={<AddCircleOutlineIcon />}
                              fullWidth
                            >
                              Add document
                            </Button>
                          </label>
                        </Stack>
                        <Typography>{file?.name ? file?.name : ""}</Typography>
                      </Box>
                      <Select
                        style={{ width: "250px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="position.name"
                        value={title}
                        onChange={(e: any) => {
                          setTitle(e.target.value);
                        }}
                      >
                        {nameOfDocuments.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>

                      <Box style={classes.listitem}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={addToList}
                          fullWidth
                          //   startIcon={<UploadFileIcon />}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Box>
                <Typography align="center" variant="h5">
                  List of Documents
                </Typography>
                <Box ml={15} mr={15}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ whiteSpace: "nowrap" }}>
                      <TableRow>
                        <TableCell>File </TableCell>
                        <TableCell>Download</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ whiteSpace: "nowrap" }}>
                      {files &&
                        files.map((item: any) => (
                          <TableRow>
                            <TableCell>
                              <Button onClick={() => OpenModal(`${item.id}`)}>
                                <b>{item.title}</b>
                              </Button>
                              {typeChecker(`${item.title}`) === pdf ? (
                                <Modal
                                  open={state === `${item.title}`}
                                  onClose={() => setState(null)}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                  sx={{
                                    marginTop: 5,
                                    height: 680,
                                    width: 680,
                                    left: "30%",
                                  }}
                                >
                                  <ViewerPdf file={`${openedDoc}`} />
                                </Modal>
                              ) : (
                                <Modal
                                  open={state === `${item.title}`}
                                  onClose={() => setState(null)}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                  sx={{
                                    height: 800,
                                    width: 800,
                                    top: "15%",
                                    left: "30%",
                                  }}
                                >
                                  <img
                                    src={openedDoc}
                                    loading="lazy"
                                    width="700"
                                    height="700"
                                  />
                                </Modal>
                              )}
                            </TableCell>
                            <TableCell>
                              <Download id={item.id} />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Container>
            {/* ////////////////////////////////////////////////// */}
          </TabPanel>
        </TabsUnstyled>
      </Container>
    </Container>
  );
};

export default CreateUser;
