import React, { useEffect, useState } from "react";
import apiService from "../../services/apiService";
import { APP_URL } from "../../utils/constants";
import { Box, Checkbox, Divider, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
// import SearchBar from "material-ui-search-bar";
import SearchBar from '@mkyy/mui-search-bar';
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterSelectionBox from "./components/FilterSelectionBox";
import UserProfileCard from "./components/UserProfileCard";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";

export interface ISelectionBox {
  type: string;
  selectionChoicesStatePair: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  selectionBoxTextStatePair: [string, React.Dispatch<React.SetStateAction<string>>];
}

export interface IMultiFilter {
  client: string;
  team: string;
  position: string;
  seniority: string;
  role: string;
}

export const OurTeam = () => {
  let selectionBoxes: ISelectionBox[] = [
    {
      type: "client",
      selectionChoicesStatePair: useState<string[]>([]),
      selectionBoxTextStatePair: useState<string>(""),
    },
    {
      type: "team",
      selectionChoicesStatePair: useState<string[]>([]),
      selectionBoxTextStatePair: useState<string>(""),
    },
    {
      type: "position",
      selectionChoicesStatePair: useState<string[]>([]),
      selectionBoxTextStatePair: useState<string>(""),
    },
    {
      type: "seniority",
      selectionChoicesStatePair: useState<string[]>([]),
      selectionBoxTextStatePair: useState<string>(""),

    },
    {
      type: "role",
      selectionChoicesStatePair: useState<string[]>([]),
      selectionBoxTextStatePair: useState<string>(""),
    },
  ];

  let currentUser = useSelector((state: any) => state.reducer.user);
  const shouldSeeOurTeamPage = currentUser.is_super_hr || currentUser.is_hr

  const [allTeams, setAllTeams] = useState<string[]>([]);

  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [teamsPerClient, setTeamsPerClient] = useState<{
    [clientName: string]: string[];
  }>();

  const [searchVal, setSearchVal] = useState<string>("");
  const [multiFilter, setMultiFilter] = useState<IMultiFilter>({
    client: "",
    position: "",
    role: "",
    seniority: "",
    team: "",
  });

  const [teamSelected, setTeamSelected] = useState<string>("");

  const [inactive, setInactive] = useState<boolean>(false);
  const [coach, setCoach] = useState<boolean>(false);


  useEffect(() => {
    const fetchAllUsers = async () => {
      await apiService.get(`${APP_URL}/users/allusers/`).then((res) => {
        if (currentUser.is_client) res = res.users;
        setAllUsers(res.sort((up1: any, up2: any) => up1.user.first_name.localeCompare(up2.user.first_name)));
        setFilteredUsers(
          res
            .filter((userProfile: any) => userProfile.user.is_active ?? true)
            .sort((up1: any, up2: any) => up1.user.first_name.localeCompare(up2.user.first_name))
        );
      });
    };
    fetchAllUsers();

    const fetchSelectionFilterInfo = async () => {
      await apiService.get(`${APP_URL}/users/our-team/selection-filters-info/`).then((res: string[][]) => {
        for (let i = 0; i < res.length; i++) {
          if (i === 1) setAllTeams(res[i]);
          selectionBoxes[i].selectionChoicesStatePair[1](res[i]);
        }
      });
    };
    fetchSelectionFilterInfo();

    const fetchTeamsPerClient = async () => {
      await apiService.get(`${APP_URL}/users/teams-per-client/`).then((res) => setTeamsPerClient(res));
    };
    fetchTeamsPerClient();
  }, []);

  const handleMultiFilter = () => {
    if (multiFilter.client) selectionBoxes[1].selectionChoicesStatePair[1](teamsPerClient[multiFilter.client]);
    else if (multiFilter.client === "") selectionBoxes[1].selectionChoicesStatePair[1](allTeams);

    setFilteredUsers(
      allUsers
        .filter((userProfile) => {
          let satisfiesFilter = true;
          if (inactive) satisfiesFilter = satisfiesFilter && (!userProfile?.user?.is_active ?? true);
          else satisfiesFilter = satisfiesFilter && (userProfile?.user?.is_active ?? true);

          if (coach) { 
            satisfiesFilter = satisfiesFilter && (userProfile?.is_coach ?? true)
          }

          if (multiFilter.position)
            satisfiesFilter =
              satisfiesFilter && userProfile?.position?.name.toLowerCase().includes(multiFilter.position.toLowerCase());
          if (multiFilter.seniority)
            satisfiesFilter =
              satisfiesFilter &&
              userProfile?.seniority?.name.toLowerCase().includes(multiFilter.seniority.toLowerCase());
          if (multiFilter.role)
            satisfiesFilter =
              satisfiesFilter && userProfile?.role.toLowerCase().includes(multiFilter.role.toLowerCase());
          if (multiFilter.client)
            satisfiesFilter =
              satisfiesFilter && userProfile?.client?.name.toLowerCase().includes(multiFilter.client.toLowerCase());
          if (multiFilter.team)
            satisfiesFilter =
              satisfiesFilter &&
              userProfile?.team?.some((team: any) => team.name.toLowerCase().includes(multiFilter.team.toLowerCase()));

          return satisfiesFilter;
        })
        .filter((userProfile) => {
          return (
            (userProfile.user.first_name + " " + userProfile.user.last_name)
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            userProfile.client?.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            userProfile?.team?.some((team: any) => team.name.toLowerCase().includes(searchVal.toLowerCase())) ||
            userProfile.position?.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            userProfile.seniority?.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            userProfile.role.toLowerCase().includes(searchVal.toLowerCase())
          );
        })
    );
  };

  const handleRemoveMultiFilter = () => {
    setMultiFilter({
      client: "",
      position: "",
      role: "",
      seniority: "",
      team: "",
    });
    handleMultiFilter();
    selectionBoxes.forEach((selectionBox) => {
      selectionBox.selectionBoxTextStatePair[1]("");
    });
  };

  useEffect(() => {
    handleMultiFilter();
  }, [searchVal, inactive, coach]);

  useEffect(() => {
    if (multiFilter.team) setTeamSelected(multiFilter.team);
    else setTeamSelected(searchVal);
  }, [multiFilter.team]);

  if (!shouldSeeOurTeamPage) {
    return <Navigate to="/main/overview" />;
  }
  return (
    <Box mt={10} p={3} ml={8}>
      <Typography variant="h1" gutterBottom align="left" fontWeight={500} fontSize={48}>
        Our Team
      </Typography>
      <SearchBar
        value={searchVal}
        onChange={(val:any) => {
          setSearchVal(val);
        }}
        onCancelResearch={() => {
          setSearchVal("");
        }}
        placeholder="Search by name"
        style={{
          borderRadius: 20,
          background: 'lightgray',
        }}
      />
      <Divider />

      <Grid container>
        {selectionBoxes.map((selectionBox, index) => {
          if ((currentUser.is_regular_user || currentUser.is_team_lead || currentUser.is_client) && index === 4)
            return <div key={index}></div>;
          if (currentUser.is_client && (index === 0 || index === 3)) return <div key={index}></div>;
          return (
            <FilterSelectionBox
              selectionBox={selectionBox}
              multiFilter={multiFilter}
              setMultiFilter={setMultiFilter}
              handleMultiFilter={handleMultiFilter}
              key={index}
            />
          );
        })}
        {(currentUser.is_hr || currentUser.is_super_hr) && (
          <FormControlLabel
            label="Show inactive"
            control={<Checkbox onChange={(e) => setInactive(!inactive)} />}
            sx={{ marginTop: -1.5, marginLeft: 1 }}
          />
        )}
         {!currentUser.is_client &&
        <FormControlLabel
            label="Show coach"
            control={<Checkbox onChange={(e) => setCoach(!coach)} />}
            sx={{ marginTop: -1.5, marginLeft: 1 }}
          />
         }
        <IconButton
          color="error"
          size="large"
          aria-label="remove-filter"
          sx={{ marginTop: 4, marginLeft: 1, height: 43, width: 43 }}
          onClick={handleRemoveMultiFilter}
        >
          <FilterListOffIcon />
        </IconButton>
      </Grid>

      {filteredUsers.length > 0 && (
        <Grid container spacing={{ xs: 2, sm: 4, md: 8 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {filteredUsers.map((userProfile, index) => (
            <Grid item key={index}>
              <Link to={`/employee/${userProfile.user.id}`} style={{ textDecoration: "none" }}>
                <UserProfileCard userProfile={userProfile} teamSelected={teamSelected} />
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
