import axios from "axios";
import { APP_URL } from "../utils/constants";
import { ImageType } from "react-images-uploading";
import apiService from "../services/apiService";

export const colorPicker = async (app_color: any) => {
   return await fetch(`${APP_URL}/template/template_change/`, {
      method: "PATCH",
      headers: {
         "Content-type": "application/json",
         Accept: "application/json",
      },
      body: JSON.stringify({ app_color }),
   }).then((resp) => resp.json());
};

export const logoPicker = async (logo: any) => {
   return await fetch(`${APP_URL}/template/template_change/`, {
      method: "PATCH",
      headers: {
         "Content-type": "application/json",
         Accept: "application/json",
      },
      body: JSON.stringify({ logo }),
   }).then((resp) => resp.json());
};

export const candidateApplyForJob = async (
   first_name: any,
   last_name: any,
   email: any,
   job_position: any,
   cv: File,
   job_appplication_progress: any
) => {
   return await apiService
      .post(`${APP_URL}/job/apply/`, {
         first_name,
         last_name,
         email,
         job_position,
         cv,
         job_appplication_progress,
      })
      .then((resp) => resp.json());
};

export const createUser = async (email: string, password: string) => {
   return apiService.post(`${APP_URL}/auth/login/`, { email, password }, "application/json", false);
};

const googleLogin = async (accesstoken: any) => {
   let res = await axios.post(`${APP_URL}/api/`, {
      access_token: accesstoken,
   });
   console.log("B:", res);
   return res.status;
};
export default googleLogin;
