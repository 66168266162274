import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import { APP_URL } from "../utils/constants";
import Cookies from "js-cookie";
import _pick from "lodash/pick";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import Swal from "sweetalert2";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initFormDataErrors = {
  firstName: false,
  lastName: false,
  emailQcerris: false,
};

const classes = {
  user: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  items: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5rem",
  },
  items2: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.5rem",
  },
  picture: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "0.5rem",
  },
  item: {
    flex: 1,
    margin: "0rem 0.1rem",
  },
  button: {
    "&:hover": {
      backgroundColor: "#003A75",
    },
    backgroundColor: "none",
  },
  enabled: {
    color: "teal",
  },
  disabled: { color: "green" },
};

var jwt = require("react-jwt");

const GeneralInfo: React.FC<any> = ({
  formData,
  setFormData,
  id,
  positions,
  senioritis,
  clients,
  teams,
  isLoading,
}) => {
  const [disable, setDisable] = useState(true);
  const [user, setUser] = useState<any>();
  const [oldState, setOldState] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [formDataErrors, setFormDataErrors] = useState(initFormDataErrors);
  const [teamLeads, setTeamLeads] = useState([]);
  const [teamsName, setTeamsName] = useState([]);
  let teamKeysHelper = formData.team?.map((team: any) => team.id);
  let clientKeysHelper = formData.clients?.map((client: any) => client.id);

  const initUserType = {
    isRegularUser: formData.is_regular_user,
    isHR: formData.is_hr,
    isClient: formData.is_client,
    isTeamLead: formData.is_team_lead,
    isSuperHR: formData.is_super_hr,
    isSupervisor: formData.is_supervisor,
  };
  let initRole = "";
  if (formData.is_regular_user) {
    initRole = "Regular User";
  } else if (formData.is_hr) {
    initRole = "HR";
  } else if (formData.is_client) {
    initRole = "Client";
  } else if (formData.is_team_lead) {
    initRole = "Team Lead";
  } else if (formData.is_super_hr) {
    initRole = "Super HR";
  } else if (formData.is_supervisor) {
    initRole = "Supervisor";
  }

  const roles = [
    "Regular User",
    "HR",
    "Client",
    "Team Lead",
    "Supervisor",
    "Super HR",
  ];
  const [role, setRole] = useState<any>(initRole);
  const [userType, setUserType] = useState<any>(initUserType);

  let cookie = Cookies.get("myCookie");
  let userId: any;
  if (cookie) {
    // userId = jwt.decode(cookie).user_id;
    let userId = (decodeToken(cookie) as any).user_id;
  }
  let currentUser = useSelector((state: any) => state.reducer.user);

  useEffect(() => {
    getFormData();
    setUser(currentUser);
    fatchTeamLeads();
    let teams = [];
    for (let i = 0; i < formData.team.length; i++) {
      teams.push(formData.team[i].name);
    }
    setTeamsName(teams);
  }, []);

  const getFormData = () => {
    apiService.get(`${APP_URL}/users/profile/${id}/`).then((resp) => {
      const initData = _pick(resp, Object.keys(resp));
      let oldRole;
      if (formData.is_regular_user) {
        oldRole = "Regular User";
      } else if (formData.is_hr) {
        oldRole = "HR";
      } else if (formData.is_client) {
        oldRole = "Client";
      } else if (formData.is_team_lead) {
        oldRole = "Team Lead";
      } else if (formData.is_supervisor) {
        oldRole = "Supervisor";
      } else if (formData.is_super_hr) {
        oldRole = "Super HR";
      }
      setOldState(initData);
    });
  };

  const changeRole = (role: any) => {
    setRole(role);
    switch (role) {
      case "Regular User":
        setUserType({
          isHR: false,
          isClient: false,
          isTeamLead: false,
          isSuperHR: false,
          isSupervisor: false,
          isRegularUser: true,
        });
        break;
      case "HR":
        setUserType({
          isHR: true,
          isClient: false,
          isTeamLead: false,
          isSuperHR: false,
          isSupervisor: false,
          isRegularUser: false,
        });
        break;
      case "Client":
        setUserType({
          isHR: false,
          isClient: true,
          isTeamLead: false,
          isSuperHR: false,
          isSupervisor: false,
          isRegularUser: false,
        });
        break;
      case "Team Lead":
        setUserType({
          isHR: false,
          isClient: false,
          isTeamLead: true,
          isSuperHR: false,
          isSupervisor: false,
          isRegularUser: false,
        });
        break;
      case "Supervisor":
        setUserType({
          isHR: false,
          isClient: false,
          isTeamLead: false,
          isSuperHR: false,
          isSupervisor: true,
          isRegularUser: false,
        });
        break;
      case "Super HR":
        setUserType({
          isHR: false,
          isClient: false,
          isTeamLead: false,
          isSuperHR: true,
          isSupervisor: false,
          isRegularUser: false,
        });
        break;
    }
  };

  const fatchTeamLeads = async () => {
    const listEmployees = await apiService.get(`${APP_URL}/users/profile/`);
    if (listEmployees.users !== undefined) {
      let listTeamLeads = listEmployees.users.filter(
        (e: any) => e.is_team_lead === true
      );
      setTeamLeads(listTeamLeads);
    } else {
      let listTeamLeads = listEmployees.filter(
        (e: any) => e.is_team_lead === true
      );
      setTeamLeads(listTeamLeads);
    }
  };

  let textfield = disable ? classes.disabled : classes.enabled;

  async function handleButtonEnable() {
    setFormData(oldState);
    disable === true ? setDisable(false) : setDisable(true);
  }

  function evaluateData(data: any) {
    if (data === null || data === "") {
      return true;
    }
    return data;
  }

  const Toast = Swal.mixin({
    toast: true,
    color: "white",
    iconColor: "white",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  });

  const handleUpdateInfo = () => {
    let projectsId: any = [];
    let teamsId: any = [];
    formData?.team?.map((item: any) => {
      teamsId = [...teamsId, item.id];
    });
    formData?.project?.map((item: any) => {
      projectsId = [...projectsId, item.id];
    });
    let data = {
      id: formData.user?.id,
      first_name: formData.user?.first_name,
      last_name: formData.user?.last_name,
      email: formData.user?.email,
      is_hr: userType?.isHR,
      is_client: userType?.isClient,
      is_regular_user: userType?.isRegularUser,
      is_team_lead: userType?.isTeamLead,
      is_supervisor: userType?.isSupervisor,
      is_super_hr: userType?.isSuperHR,
      internship_start: formData.internship_start,
      internship_end: formData.internship_end,
      contract_start: formData.contract_start,
      gender: formData.gender,
      date_of_birth: formData.date_of_birth,
      private_email: formData.private_email,
      address: formData.address,
      phone_number: formData.phone_number,
      bank_account: formData.bank_account,
      end_date: formData.end_date,
      anex_date_start: formData.anex_date_start,
      emergency_name: formData.emergency_name,
      emergency_number: formData.emergency_number,
      position: formData?.position?.id,
      seniority: formData?.seniority?.id,
      team: teamsId,
      project: projectsId,
      client: formData.client?.id,
      permision: 0,
      team_lead: formData.team_lead?.user?.id,
      is_active: formData.user?.is_active,
      is_coach: formData?.is_coach,
    };
    const errors: any = _cloneDeep(formDataErrors);
    if (_isEmpty(data.first_name)) {
      errors["firstName"] = true;
    }
    if (_isEmpty(data.last_name)) {
      errors["lastName"] = true;
    }
    if (_isEmpty(data.email)) {
      errors["emailQcerris"] = true;
    }
    setFormDataErrors(errors);

    if (Object.values(errors).filter((x) => x === true).length > 0) {
      return;
    }
    setLoader(true);
    apiService
      .patch(`${APP_URL}/users/`, data)
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Successfully changed!",
          background: "#a5dc86",
        });
        getFormData();
        setLoader(false);
        setDisable(true);
        teamKeysHelper = formData.team?.map((team: any) => team.id);
        clientKeysHelper = formData.clients?.map((client: any) => client.id);
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: "Error!",
          background: "#f27474",
        });
        setLoader(false);
      });
  };

  const handleChangeFirstName = (e: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.user.first_name = e.target.value;
    setFormData(clonedFormData);
    formDataErrors["firstName"] = false;
  };

  const handleChangeLastName = (e: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.user.last_name = e.target.value;
    setFormData(clonedFormData);
    formDataErrors["lastName"] = false;
  };

  const handleChangeEmail = (e: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.user.email = e.target.value;
    setFormData(clonedFormData);
    formDataErrors["emailQcerris"] = false;
  };

  const handleSexChange = (e: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.gender = e.target.value;
    setFormData(clonedFormData);
  };

  const handleIsCoachChange = () => {
    setFormData({ ...formData, is_coach: !formData.is_coach });
  };

  const handleChangeStatus = (e: any) => {
    const clonedFormData = { ...formData };

    clonedFormData.user.is_active = e.target.value === "Active" ? true : false;
    setFormData(clonedFormData);
  };
  const handleRoleChange = (e: any, value: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.role = value;
    setFormData(clonedFormData);
    changeRole(value);
  };

  const handleClientChange = (e: any, value: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.client = value;
    setFormData(clonedFormData);
  };
  const handleSeniorityChange = (e: any, value: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.seniority = value;
    setFormData(clonedFormData);
  };
  const handlePositionChange = (e: any, value: any) => {
    console.log(value);
    const clonedFormData = { ...formData };
    clonedFormData.position = value;
    setFormData(clonedFormData);
  };
  const handleTeamLeadChange = (e: any, value: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.team_lead = value;
    setFormData(clonedFormData);
  };

  const handleChangeTeam = (e: any, value: any) => {
    const clonedFormData = { ...formData };
    clonedFormData.team = value;
    setFormData(clonedFormData);
    let teams = [];
    for (let i = 0; i < clonedFormData.team.length; i++) {
      teams.push(clonedFormData.team[i].name);
    }
    setTeamsName(teams);
  };

  const handleChangeTextData = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress style={{ marginTop: "15rem" }} />
      ) : (
        <Container maxWidth="lg">
          <Box>
            <Paper sx={classes.paper}>
              <Container maxWidth="xs">
                <Box style={classes.items}>
                  {evaluateData(formData?.user?.first_name) && (
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        First name:
                      </Typography>
                      <TextField
                        style={textfield}
                        size="small"
                        id="demo-helper-text-aligned"
                        name="user.first_name"
                        onChange={handleChangeFirstName}
                        value={formData?.user?.first_name}
                        disabled={disable}
                        fullWidth
                        error={formDataErrors.firstName}
                        helperText={
                          formDataErrors.firstName && "First name required"
                        }
                      />
                    </Box>
                  )}

                  {evaluateData(formData?.user?.last_name) && (
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Last name:
                      </Typography>
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="user.last_name"
                        onChange={handleChangeLastName}
                        value={formData?.user?.last_name}
                        disabled={disable}
                        fullWidth
                        error={formDataErrors.lastName}
                        helperText={
                          formDataErrors.lastName && "Last name required"
                        }
                      />
                    </Box>
                  )}
                </Box>
                <Box style={classes.items}>
                  {evaluateData(formData?.date_of_birth) && (
                    <Box style={classes.item}>
                      <Box>
                        {disable && currentUser.user.id != formData.user.id ? (
                          <>
                            <Typography align="left" variant="body2">
                              {formData?.date_of_birth?.length >= 6
                                ? "Date of birth:"
                                : "Birthday:"}
                            </Typography>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="dob"
                              displayEmpty
                              disabled={disable}
                              fullWidth
                              defaultValue={formData?.date_of_birth}
                            >
                              {formData?.date_of_birth && (
                                <MenuItem value={formData?.date_of_birth}>
                                  {formData?.date_of_birth}
                                </MenuItem>
                              )}
                            </Select>
                          </>
                        ) : (
                          <>
                            <Typography align="left" variant="body2">
                              Date of birth:
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                defaultValue={dayjs(formData?.date_of_birth)}
                                onChange={(e: any) => {
                                  setFormData({
                                    ...formData,
                                    dateOfBirth: moment(String(e)).format(
                                      "yyyy-MM-DD"
                                    ),
                                  });
                                }}
                                disabled={disable}
                              />
                            </LocalizationProvider>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                  {evaluateData(formData?.gender) && (
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Gender:
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="gender"
                        onChange={handleSexChange}
                        defaultValue={"MA"}
                        value={formData?.gender}
                        disabled={disable}
                        fullWidth
                      >
                        <MenuItem value={"MA"}>Male</MenuItem>
                        <MenuItem value={"FE"}>Female</MenuItem>
                        <MenuItem value={"OT"}>Other</MenuItem>
                      </Select>
                    </Box>
                  )}
                </Box>
                {evaluateData(formData?.user?.email) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Email Qcerris:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      onChange={handleChangeEmail}
                      value={formData?.user?.email}
                      disabled={disable}
                      fullWidth
                      error={formDataErrors.emailQcerris}
                      helperText={
                        formDataErrors.emailQcerris && "Email required"
                      }
                    />
                  </Box>
                )}
                {evaluateData(formData?.private_email) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Email Private:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="private_email"
                      onChange={handleChangeTextData}
                      value={formData?.private_email}
                      disabled={disable}
                      fullWidth
                    />
                  </Box>
                )}
                {evaluateData(formData?.address) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Address:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      variant="outlined"
                      name="address"
                      onChange={handleChangeTextData}
                      value={formData?.address}
                      disabled={disable}
                      fullWidth
                    />
                  </Box>
                )}

                {evaluateData(formData?.phone_number) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Phone number:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="phone_number"
                      onChange={handleChangeTextData}
                      value={formData?.phone_number}
                      disabled={disable}
                      fullWidth
                    />
                  </Box>
                )}
                {evaluateData(formData?.bank_account) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Bank account:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="bank_account"
                      onChange={handleChangeTextData}
                      value={formData?.bank_account}
                      disabled={disable}
                      fullWidth
                    />
                  </Box>
                )}

                <Box style={classes.items}>
                  {evaluateData(formData?.internship_start) && (
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Internship start date:
                      </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            defaultValue={dayjs(formData?.internship_start)}
                            onChange={(e: any) => {
                              setFormData({
                                ...formData,
                                internship_start: moment(String(e)).format(
                                  "yyyy-MM-DD"
                                ),
                              });
                            }}
                            maxDate={dayjs(formData?.internship_end ?? "")}
                            disabled={disable}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  )}
                  {evaluateData(formData?.internship_end) && (
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Internship end date:
                      </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            defaultValue={dayjs(formData?.internship_end)}
                            onChange={(e: any) => {
                              setFormData({
                                ...formData,
                                internship_end: moment(String(e)).format(
                                  "yyyy-MM-DD"
                                ),
                              });
                            }}
                            minDate={dayjs(formData?.internship_start ?? "")}
                            disabled={disable}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  )}
                </Box>

                {user && user.is_super_hr && (
                  <Box style={classes.items}>
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Contract start date:
                      </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            // label={formData && formData.contract_start}
                            defaultValue={dayjs(formData?.contract_start)}
                            onChange={(e: any) => {
                              setFormData({
                                ...formData,
                                contract_start: moment(String(e)).format(
                                  "yyyy-MM-DD"
                                ),
                              });
                            }}
                            maxDate={dayjs(formData?.end_date ?? "")}
                            disabled={disable}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>

                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Contract end date:
                      </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            defaultValue={dayjs(formData?.end_date)}
                            onChange={(e: any) => {
                              setFormData({
                                ...formData,
                                end_date: moment(String(e)).format(
                                  "yyyy-MM-DD"
                                ),
                              });
                            }}
                            minDate={dayjs(formData?.contract_start ?? "")}
                            disabled={disable}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box style={classes.items}>
                  {evaluateData(formData?.anex_date_start) && (
                    <Box style={classes.item}>
                      <Typography align="left" variant="body2">
                        Anex date start:
                      </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: 395 }}
                            defaultValue={dayjs(formData?.anex_date_start)}
                            onChange={(e: any) => {
                              setFormData({
                                ...formData,
                                anex_date_start: moment(String(e)).format(
                                  "yyyy-MM-DD"
                                ),
                              });
                            }}
                            disabled={disable}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  )}
                </Box>
                {evaluateData(formData?.emergency_name) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Emergency contact name and lastname:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="emergency_name"
                      onChange={handleChangeTextData}
                      value={formData?.emergency_name}
                      disabled={disable}
                      fullWidth
                    />
                  </Box>
                )}
                {evaluateData(formData?.emergency_number) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Emergency number:
                    </Typography>
                    <TextField
                      size="small"
                      id="demo-helper-text-aligned"
                      name="emergency_number"
                      onChange={handleChangeTextData}
                      value={formData?.emergency_number}
                      disabled={disable}
                      fullWidth
                    />
                  </Box>
                )}
                {
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      User Role:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="role"
                        value={formData.role}
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        getOptionLabel={(option: any) => option}
                        id="controllable-states-demo"
                        options={roles}
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        value={formData.role ?? ""}
                        onChange={handleRoleChange}
                      />
                    )}
                  </Box>
                }
                {evaluateData(formData?.team_lead) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Team Lead:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="emergency_number"
                        value={
                          formData?.team_lead?.user?.first_name
                            ? formData?.team_lead?.user?.first_name +
                              " " +
                              formData?.team_lead?.user?.last_name
                            : ""
                        }
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        id="controllable-states-demo"
                        options={teamLeads}
                        getOptionLabel={(option: any) =>
                          option?.user?.first_name && option?.user?.last_name
                            ? option.user.first_name +
                              " " +
                              option?.user?.last_name
                            : ""
                        }
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={formData.team_lead}
                        value={formData?.team_lead ?? ""}
                        onChange={handleTeamLeadChange}
                      />
                    )}
                  </Box>
                )}

                {evaluateData(formData?.position) && (
                  <Box
                    sx={
                      !currentUser.is_super_hr ? classes.item : classes.items2
                    }
                  >
                    <Typography align="left" variant="body2">
                      Position:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="emergency_number"
                        value={formData?.position?.name}
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        id="controllable-states-demo"
                        options={positions}
                        getOptionLabel={(option: any) =>
                          option?.name ? option.name : ""
                        }
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={formData.position}
                        value={formData?.position ?? ""}
                        onChange={handlePositionChange}
                      />
                    )}
                  </Box>
                )}
                {evaluateData(formData?.seniority) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Seniority:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="emergency_number"
                        value={formData?.seniority?.name}
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        id="controllable-states-demo"
                        options={senioritis}
                        getOptionLabel={(option: any) =>
                          option?.name ? option.name : ""
                        }
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={formData.seniority}
                        value={formData?.seniority ?? ""}
                        onChange={handleSeniorityChange}
                      />
                    )}
                  </Box>
                )}
                {evaluateData(formData?.client) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Client:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="emergency_number"
                        value={formData?.client?.name}
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        id="controllable-states-demo"
                        options={clients}
                        getOptionLabel={(option: any) =>
                          option?.name ? option.name : ""
                        }
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={formData.client}
                        value={formData?.client ?? ""}
                        onChange={handleClientChange}
                      />
                    )}
                  </Box>
                )}
                {evaluateData(formData?.team) && (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Team:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="emergency_number"
                        multiline
                        value={teamsName}
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <>
                        <Autocomplete
                          multiple
                          disablePortal
                          options={teams}
                          disableCloseOnSelect
                          defaultValue={teams.filter((team: any) =>
                            teamKeysHelper.includes(team.id)
                          )}
                          onChange={handleChangeTeam}
                          getOptionLabel={(option: any) => option.name}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </>
                    )}
                  </Box>
                )}
                {!currentUser.is_client && (
                  <Box sx={classes.items2}>
                    <FormControlLabel
                      label="Coach"
                      control={
                        <Checkbox
                          checked={formData.is_coach}
                          disabled={disable}
                          onChange={handleIsCoachChange}
                        />
                      }
                    />
                  </Box>
                )}
                {user && user.is_super_hr ? (
                  <Box sx={classes.items2}>
                    <Typography align="left" variant="body2">
                      Status:
                    </Typography>
                    {disable ? (
                      <TextField
                        size="small"
                        id="demo-helper-text-aligned"
                        name="emergency_number"
                        value={formData?.user.is_active ? "Active" : "Inactive"}
                        sx={{
                          backgroundColor: formData?.user.is_active
                            ? "#2ED47A"
                            : "#F7685B",
                        }}
                        disabled={disable}
                        fullWidth
                      />
                    ) : (
                      <>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="team.name"
                          onChange={handleChangeStatus}
                          disabled={disable}
                          fullWidth
                          value={
                            formData?.user.is_active ? "Active" : "Inacive"
                          }
                          sx={{
                            backgroundColor: formData?.user.is_active
                              ? "#2ED47A"
                              : "#F7685B",
                          }}
                          renderValue={(value) =>
                            formData?.user.is_active ? "Active" : "Inacive"
                          }
                        >
                          <MenuItem
                            value="Active"
                            sx={{ backgroundColor: "#2ED47A" }}
                          >
                            Active
                          </MenuItem>
                          <MenuItem
                            value="Inactive"
                            sx={{ backgroundColor: "#F7685B" }}
                          >
                            Inactive
                          </MenuItem>
                        </Select>
                      </>
                    )}
                  </Box>
                ) : (
                  ""
                )}
                {user && user.is_super_hr && (
                  <Box style={classes.items}>
                    <Box style={classes.item}>
                      {disable === true ? (
                        <Button
                          style={classes.button}
                          sx={{ marginTop: "1.5rem" }}
                          variant="contained"
                          onClick={() => setDisable(!disable)}
                          fullWidth
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          style={classes.button}
                          sx={{ marginTop: "1.5rem" }}
                          variant="contained"
                          onClick={handleButtonEnable}
                          fullWidth
                        >
                          Cancel
                        </Button>
                      )}
                    </Box>
                    <Box style={classes.item}>
                      <Button
                        style={classes.button}
                        sx={{ marginTop: "1.5rem" }}
                        variant="contained"
                        onClick={handleUpdateInfo}
                        fullWidth
                        disabled={loader || disable}
                      >
                        Update info
                        {loader && (
                          <CircularProgress
                            style={{
                              width: "25px",
                              height: "25px",
                              marginLeft: "10px",
                            }}
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}
                {user && !user.is_super_hr && (
                  <>
                    <br />
                  </>
                )}
              </Container>
            </Paper>
          </Box>
        </Container>
      )}
    </>
  );
};

export default GeneralInfo;
