import { Box, FormControl, Rating, TextField, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { IPerformanceReviewForm, IQuestionForm } from "../utils/prf.model";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { classes } from "../utils/styles";
import apiService from "../../../services/apiService";
import { APP_URL } from "../../../utils/constants";
import { checkIfAnyAnswerIsUnfilled } from "../PerformanceReviewQuestions";
import { useSelector, useDispatch } from "react-redux";

interface IProps {
  prf: IPerformanceReviewForm;
  setPrf: React.Dispatch<SetStateAction<IPerformanceReviewForm>>;
  question_form: IQuestionForm;
  index: number;
  submitted: boolean;
  employee: any;
}

const QuestionForm = ({
  prf,
  setPrf,
  question_form,
  index,
  submitted,
  employee,
}: IProps) => {
  const [answer, setAnswer] = useState(question_form.answer);
  let currentUser = useSelector((state: any) => state.reducer.user);

  const handleAnswer = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const id = Number(e.target.name);
    const answer = e.target.value;
    setPrf((prevPrf) => {
      const newState: IPerformanceReviewForm = { ...prevPrf };
      newState.sections.map((section) =>
        section.subsections.map((subsection) =>
          subsection.question_forms.map((question_form) => {
            if (question_form.id === id) {
              question_form.answer = answer;
            }
            return question_form;
          })
        )
      );
      return newState;
    });

    apiService.patch(`${APP_URL}/performance-review/question_formsv2/`, {
      id: e.target.name,
      answer: e.target.value,
    });
  };
  console.log(employee);
  console.log(currentUser);

  return (
    <Box sx={{ ml: 5, marginRight: "40px" }}>
      <Typography
        sx={{
          mt: 5,
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {question_form.question}
      </Typography>
      {question_form.is_text ? (
        <TextField
          name={question_form.id.toString()}
          id={question_form.id.toString()}
          fullWidth
          variant="standard"
          multiline
          defaultValue={question_form.answer}
          disabled={employee.id !== currentUser.id && currentUser.is_hr}
          sx={{
            ml: 3,
            mt: 2,
            width: "50vw",
            display: "flex",
            justifyContent: "flex-start",
          }}
          onBlur={(e) => handleAnswer(e)}
          onChange={(e) => setAnswer(e.target.value)}
          error={
            submitted &&
            checkIfAnyAnswerIsUnfilled(prf) &&
            answer.toString().trim() === ""
          }
          helperText={
            submitted &&
            checkIfAnyAnswerIsUnfilled(prf) &&
            answer.toString().trim() === ""
              ? "This field is required"
              : ""
          }
        />
      ) : (
        <Box>
          <Rating
            name={question_form.id.toString()}
            id={question_form.id.toString()}
            defaultValue={Number(question_form.answer)}
            disabled={employee.id !== currentUser.id && currentUser.is_hr}
            onBlur={(
              e: React.FocusEvent<
                HTMLInputElement | HTMLTextAreaElement,
                Element
              >
            ) => handleAnswer(e)}
            precision={1}
            size="large"
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
            sx={classes.rating}
          />
        </Box>
      )}
    </Box>
  );
};

export default QuestionForm;
