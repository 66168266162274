import React, { useState } from "react";
import { APP_URL } from "../utils/constants";
import apiService from "../services/apiService";

export default function CreatePosition() {
  const [name, setName] = useState("");
  //   const sendDataToApi = () => {
  //     apiService.post(`${APP_URL}/job/alljobs/`, {
  //       name,
  //     });
  //   };
  console.log(name);
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    event.preventDefault();
  };

  return (
    <form onSubmit={submitForm}>
      <div className="field">
        <label htmlFor="postion name">Position name</label>
        <input
          type="text"
          id="positionName"
          name="position_name"
          placeholder="Position name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <button
        type="submit"
        onClick={() => {
          console.log(name);
          let formData = new FormData();
          formData.append("name", name);
          apiService.post(`${APP_URL}/job/alljobs/`, formData);
        }}
      >
        ADD
      </button>
    </form>
  );
}
