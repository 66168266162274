export const classes = {
  loading: {
    height: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    marginTop: 80,
  },
  header: {
    display: "flex",
    borderLeft: "0.2rem solid #109CF1",
    borderBottom: "0.1rem solid #EBEFF2",
    height: "9vh",
  },
  perfRewQuest: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "3rem",
  },
  section: {
    ml: 5,
    marginRight: "40px",
    mt: 5,
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    pb: 5,
    pl: 2,
    pr: 2,
  },
  subsection: {
    display: "flex",
    ml: 1,
    mr: 1,
    borderRadius: "10px",
    boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
    flexDirection: "column",
    pb: 5,
    mb: 3,
  },
  rating: {
    ml: 3,
    mt: 2,
    width: "50vw",
    display: "flex",
    justifyContent: "flex-start",
    "& .MuiRating-iconFilled": {
      color: "#119cf1",
    },
    "& .MuiRating-iconHover": {
      color: "#119cf1",
    },
  },
};
